import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSlash } from '@fortawesome/free-solid-svg-icons';
import { Progress } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    LinkCellRenderer,
    TextFilterDefaults,
    booleanCellRenderer,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import CommonContext, { ApiRoutes } from '../Common';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import { JobAssignmentCancellationTypeForm } from './JobAssignmentCancellationTypeForm';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';

class JobAssignmentCancellationTypeIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.jacTypeForm = React.createRef();

        this.state = {
            loading: true,
            showJobAssignmentCancellationTypeForm: false,
        };

        this.onAddJobAssignmentCancellationType = this.onAddJobAssignmentCancellationType.bind(this);
        this.onEditJobAssignmentCancellationType = this.onEditJobAssignmentCancellationType.bind(this);
        this.onJobAssignmentCancellationTypeFormClosed = this.onJobAssignmentCancellationTypeFormClosed.bind(this);

        this.yesNoCellRenderer = this.yesNoCellRenderer.bind(this);
    }

    componentDidMount() {
        return this.populateState(); 
    }

    onAddJobAssignmentCancellationType = () => {
        this.jacTypeForm.current.open();
    };

    onEditJobAssignmentCancellationType = (id) => {
        this.jacTypeForm.current.open(id);
    };

    onJobAssignmentCancellationTypeFormClosed() {
        this.setState({ showJobAssignmentCancellationTypeForm: false });
    }

    onJobAssignmentCancellationTypeSaveCallback = (response) => {
        this.setState({ showJobAssignmentCancellationTypeForm: false });
        this.context.setFormOpened(false);
        // Refresh grid
        this.state.gridApi.setDatasource(this.state.dataSource);
    };

    async populateState() {
    // Get grid defaults and handlers
        const gridOptions = createGridOptions(this);

        // Specify any custom components, filtering
        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
        };

        const isDeletedFilterParams = {
            suppressFilterButton: true,
            options: [
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
            ],
            optionsLabel: 'label',
            optionsValue: 'value',
            initialFilterValue: 'false',
        };

        // Provide column definitions
        gridOptions.columnDefs = [
            {
                flex: 0,
                width: 75,
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'Description',
                sortable: true,
                headerName: 'Description',
                field: 'description',
                sort: { direction: 'asc', priority: 0 },
                filter: 'agTextColumnFilter',
                floatingFilter: true,
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    clicked: this.onEditJobAssignmentCancellationType,
                    nameField: 'description',
                    idField: 'id',
                    title: 'View this Job Assignment Cancellation Type',
                },
            },
            {
                colId: 'preventsReassignment',
                sortable: false,
                headerName: 'Prevents Reassignment',
                field: 'preventsReassignment',
                cellRenderer: this.yesNoCellRenderer,
            },
            {
                colId: 'includeOnRosterView',
                sortable: false,
                headerName: 'Include On Roster View',
                field: 'includeOnRosterView',
                cellRenderer: this.yesNoCellRenderer,
            },
            {
                colId: 'treatAsUnassigned',
                sortable: false,
                headerName: 'Treat as Unassigned',
                field: 'treatAsUnassigned',
                cellRenderer: this.yesNoCellRenderer,
            },
            {
                colId: 'canBeDeleted',
                sortable: false,
                headerName: 'Can Be Deleted',
                field: 'canBeDeleted',
                cellRenderer: this.yesNoCellRenderer,
            },
            {
                colId: 'canBeModified',
                sortable: false,
                headerName: 'Can Be Modified',
                field: 'canBeModified',
                cellRenderer: this.yesNoCellRenderer,
            },
            {
                colId: 'isDeleted',
                sortable: false,
                headerName: 'Is Deleted',
                field: 'isDeleted',
                cellRenderer: this.yesNoCellRenderer,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: isDeletedFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: isDeletedFilterParams,
            },
        ];

        // Create datasource.  Is reused and rebound on refresh from the state.
        const dataSource = createDataSource(
            ApiRoutes.jobAssignmentCancellationType.search(),
            gridOptions,
        );

        this.setState({
            loading: false,
            gridOptions,
            dataSource,
        });
    }

    yesNoCellRenderer = booleanCellRenderer;

    render() {
        if (this.state.loading) {
            return <Progress />;
        }

        const {
            rowData,
            gridOptions,
            showJobAssignmentCancellationTypeForm,
            gridStatus,
            gridApi,
            dataSource,
        } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon
                        icon={faPhoneSlash}
                        className="mr-2 text-muted"
                    />
                    <span>Job Assignment Cancellation Types</span>
                </PageHeading>
                <DataGridToolbar
                    entity="jobassignmentcancellationtype"
                    gridApi={gridApi}
                    dataSource={dataSource}
                    onAdd={this.onAddJobAssignmentCancellationType}
                    addLabel="Add Job Assignment Cancellation Type"
                />
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={gridStatus}
                />
                <JobAssignmentCancellationTypeForm
                    ref={this.jacTypeForm}
                    onClose={this.onJobAssignmentCancellationTypeFormClosed}
                    show={showJobAssignmentCancellationTypeForm}
                    onSaveCallback={
                        this.onJobAssignmentCancellationTypeSaveCallback
                    }
                />
            </PageWrap>
        );
    }
}
export default withRouter(JobAssignmentCancellationTypeIndex);

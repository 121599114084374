import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBusinessTime,
    faChartLine,
    faFileInvoice,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';
import {
    Row,
    Card,
    CardBody,
    CardSubtitle,
    CardTitle,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Spinner,
    Progress
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import CommonContext, { ApiRoutes } from '../Common';
import { PageHeading, PageWrap, toasty } from '../common/forms/FormElements';
import { util } from '../Util';
import StaffingReportForm from './StaffingReportForm';
import NewCustomerBillableHoursReportForm from './NewCustomerBillableHoursReportForm';

import ExpiringContractsReportForm from './ExpiringContractsReportForm';
import HoursReport from '../chargeView/HoursReport';
import { Can } from '../Can';

class CollectedReportingIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            showStaffingReportForm: false,
            showNewCustomerBillableHoursReportForm: false,
            showExpiringContractsReportForm: false,
            tenantSettings: null,
            showHoursReport: false,
            tenants: [],
            isGeneratingHoursReport: false,
        };

        this.generateHoursReportRef = React.createRef();
    }

    componentDidMount() {
        return this.populateState(); 
    }

    onExpiringContractsReportFormClose = () => this.setState({ showExpiringContractsReportForm: false });

    onNewCustomerBillableHoursReportFormClose = () => this.setState({ showNewCustomerBillableHoursReportForm: false });

    onShowHoursReport = () => this.setState({ showHoursReport: true });

    onStaffingReportFormClose = () => {
        this.setState({ showStaffingReportForm: false });
    };

    openContractExpiryReport = () => this.setState({ showExpiringContractsReportForm: true });

    openNewCustomerBillableHoursReport = () => this.setState({ showNewCustomerBillableHoursReportForm: true });

    openStaffingReport = () => {
        this.setState({ showStaffingReportForm: true });
    };

    async populateState() {
        const [tenant, tenants] = await Promise.all([
            util.fetch.js(ApiRoutes.tenant.settings()),
            util.fetch.js(ApiRoutes.tenant.all()),
        ]);

        const { tenantSettings } = tenant;

        this.setState({ loading: false, tenantSettings, tenants });
    }

    render() {
        const {
            loading,
            showStaffingReportForm,
            showNewCustomerBillableHoursReportForm,
            tenantSettings,
            tenants,
            showExpiringContractsReportForm,
            showHoursReport,
            isGeneratingHoursReport,
        } = this.state;

        if (loading) {
            return <Progress />;
        }

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon
                        icon={faChartLine}
                        className="mr-2 text-muted"
                    />
                    <span>Reporting</span>
                </PageHeading>

                <Row className="dashboard-row">
                    {tenantSettings.enableStaffingReport && (
                        <Button
                            className="dashboard-link no-text-decorate"
                            onClick={this.openStaffingReport}
                            color=""
                        >
                            <Card className="dashboard-link">
                                <CardBody>
                                    <CardTitle className="d-flex flex-column align-items-center">
                                        <FontAwesomeIcon
                                            className="text-muted"
                                            size="4x"
                                            icon={faUsers}
                                        />
                                    </CardTitle>
                                    <CardSubtitle className="d-flex flex-column align-items-center">
                                        <h4 className="mt-1 pt-1 pb-1 text-center border-red-top-bottom">
                    Staffing
                                        </h4>
                                    </CardSubtitle>
                                </CardBody>
                            </Card>
                        </Button>
                    )}

                    {tenantSettings.enableNewCustomerBillableHoursReport && (
                        <Button
                            className="dashboard-link no-text-decorate ml-5"
                            onClick={this.openNewCustomerBillableHoursReport}
                            color=""
                        >
                            <Card className="dashboard-link">
                                <CardBody>
                                    <CardTitle className="d-flex flex-column align-items-center">
                                        <FontAwesomeIcon
                                            className="text-muted"
                                            size="4x"
                                            icon={faBusinessTime}
                                        />
                                    </CardTitle>
                                    <CardSubtitle className="d-flex flex-column align-items-center">
                                        <h4 className="mt-1 pt-1 pb-1 text-center border-red-top-bottom">
                    New Customer Billable Hours
                                        </h4>
                                    </CardSubtitle>
                                </CardBody>
                            </Card>
                        </Button>
                    )}

                    {tenantSettings.enableContractDateRange && (
                        <Button
                            className="dashboard-link no-text-decorate ml-5"
                            onClick={this.openContractExpiryReport}
                            color=""
                        >
                            <Card className="dashboard-link">
                                <CardBody>
                                    <CardTitle className="d-flex flex-column align-items-center">
                                        <FontAwesomeIcon
                                            className="text-muted"
                                            size="4x"
                                            icon={faAddressCard}
                                        />
                                    </CardTitle>
                                    <CardSubtitle className="d-flex flex-column align-items-center">
                                        <h4 className="mt-1 pt-1 pb-1 text-center border-red-top-bottom">
                    Expiring Contracts
                                        </h4>
                                    </CardSubtitle>
                                </CardBody>
                            </Card>
                        </Button>
                    )}

                    {tenantSettings.hasHoursReport && (
                        <Can I="view" a="hours_report">
                            <Button
                                className="dashboard-link no-text-decorate ml-5"
                                onClick={this.onShowHoursReport}
                                color=""
                            >
                                <Card className="dashboard-link">
                                    <CardBody>
                                        <CardTitle className="d-flex flex-column align-items-center">
                                            <FontAwesomeIcon
                                                className="text-muted"
                                                size="4x"
                                                icon={faFileInvoice}
                                            />
                                        </CardTitle>
                                        <CardSubtitle className="d-flex flex-column align-items-center">
                                            <h4 className="mt-1 pt-1 pb-1 text-center border-red-top-bottom">
                      Timecharges - Hours Report
                                            </h4>
                                        </CardSubtitle>
                                    </CardBody>
                                </Card>
                            </Button>

                            {showHoursReport && (
                                <Modal
                                    size="lg"
                                    backdrop="static"
                                    keyboard={false}
                                    isOpen={showHoursReport}
                                >
                                    <ModalHeader>Hours Report</ModalHeader>
                                    <ModalBody>
                                        <HoursReport
                                            generateRef={
                                                this.generateHoursReportRef
                                            }
                                            onGenerateStatusChange={(isGen) => this.setState({
                                                isGeneratingHoursReport:
                                                        isGen,
                                            })}
                                            onGenerateError={(err) => toasty.error(
                                                `Error generating report: ${
                                                    err}`,
                                            )}
                                        />
                                    </ModalBody>
                                    <ModalFooter>
                                        <Spinner
                                            tag="span"
                                            type="border"
                                            size="sm"
                                            aria-hidden="true"
                                            hidden={!isGeneratingHoursReport}
                                        />
                                        <Button
                                            className="d-flex flex-row flex-nowrap align-items-center"
                                            color="primary"
                                            innerRef={
                                                this.generateHoursReportRef
                                            }
                                        >
                  Generate Report
                                        </Button>
                                        <Button
                                            className="d-flex flex-row flex-nowrap align-items-center"
                                            color="secondary"
                                            onClick={() => this.setState({
                                                showHoursReport: false,
                                            })}
                                        >
                  Cancel
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            )}
                        </Can>
                    )}
                </Row>

                {tenantSettings.enableStaffingReport && (
                    <StaffingReportForm
                        show={showStaffingReportForm}
                        onClose={this.onStaffingReportFormClose}
                        tenants={tenants}
                    />
                )}

                {tenantSettings.enableNewCustomerBillableHoursReport && (
                    <NewCustomerBillableHoursReportForm
                        show={showNewCustomerBillableHoursReportForm}
                        onClose={this.onNewCustomerBillableHoursReportFormClose}
                        tenants={tenants}
                    />
                )}

                {tenantSettings.enableContractDateRange && (
                    <ExpiringContractsReportForm
                        show={showExpiringContractsReportForm}
                        onClose={this.onExpiringContractsReportFormClose}
                    />
                )}
            </PageWrap>
        );
    }
}

export default withRouter(CollectedReportingIndex);

import React from 'react';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Input } from 'reactstrap';
import { BaseFormViewModel } from '../common/ViewModel';
import {
    FormCheckbox,
    FormLabel,
    ValidationErrorMessage,
} from '../common/forms/FormElements';
import { ComplianceType } from './ComplianceType';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { handleFormSaveError } from '../common/forms/ValidationError';

export default class ComplianceTypeForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const stateBase = {
            ComplianceType: props.ComplianceType ?? new ComplianceType(),
            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidMount() {
        return this.populateState(); 
    }

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    };

    onDelete = async (e) => {
        const { selectedComplianceType } = this.props;

        if (
            selectedComplianceType.contractComplianceIds
            && selectedComplianceType.contractComplianceIds.length > 0
        ) {
            alert(
                'This compliance type is being used by one or more contracts and may not be deleted.',
            );
            return;
        }

        const response = await util.fetch
            .delete(ApiRoutes.complianceTypes.delete(selectedComplianceType.id))
            .catch(this.handleSaveError);

        if (response) {
            this.props.onDeleteCallback(response);
        }
    };

    onSubmit = async (e) => {
    // Clear any fluent api errors
        this.clearErrors();
        this.props.onSaveCallback();
        this.resetForm();
    };

    clearErrors = () => this.setState((state) => ({ errors: {} }));

    handleSaveError = (err) => handleFormSaveError(this, err);

    populateState = async () => {
        const [equipmentTypes, employeeGroups] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.equipmentTypes()),
            util.fetch.js(ApiRoutes.typeAheads.employeeGroups()),
        ]);

        this.setState((state) => ({
            loading: false,
            equipmentTypes: (equipmentTypes ?? []).map((et) => ({ label: et.description, value: et.id })),
            employeeGroups,
        }));
    };

    resetForm = () => this.setState({ formValidated: false });

    render() {
        const {
            equipmentTypes,
            employeeGroups,
            formValidated,
            validationMessage,
            loading,
        } = this.state;
        const { selectedComplianceType, show } = this.props;

        if (!selectedComplianceType) {
            return '';
        }

        return (
            <SlideForm
                loading={loading}
                show={show}
                id="ComplianceTypeForm"
                formIcon={faWarehouse}
                formTitle={
                    !!parseInt(selectedComplianceType.id ?? 0) > 0
                        ? 'Edit Compliance Type'
                        : 'Add Compliance Type'
                }
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value });
                }}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                onSave={this.onSave}
                onDelete={this.onDelete}
                errors={this.state.errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}
                entityId={selectedComplianceType.id}
            >
                <FormGroup>
                    <FormLabel
                        htmlFor="ComplianceName"
                        text="Name"
                        required
                    />
                    <Input
                        id="ComplianceName"
                        name="selectedComplianceType.name"
                        value={selectedComplianceType.name ?? ''}
                        onChange={this.props.onChange}
                        placeholder="Enter Name (maximum 100 characters)"
                        maxLength="100"
                        pattern="[^()/><\][\\\x22,;|]+"
                        type="text"
                        required
                    />
                    <ValidationErrorMessage>
            Name is required and can contain hyphens and periods.
                    </ValidationErrorMessage>
                </FormGroup>

                <FormGroup>
                    <FormLabel htmlFor="Active" text="Active" />
                    <FormCheckbox
                        id="Active"
                        name="selectedComplianceType.active"
                        checked={selectedComplianceType.active ?? ''}
                        onChange={this.props.onChange}
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="AppliesToAllDispatches"
                        text="Applies To All Dispatches"
                    />
                    <FormCheckbox
                        id="AppliesToAllDispatches"
                        name="selectedComplianceType.appliesToAllDispatches"
                        checked={
                            selectedComplianceType.appliesToAllDispatches ?? ''
                        }
                        onChange={this.props.onChange}
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="WarnWhenMissing"
                        text="Warn When Missing"
                    />
                    <FormCheckbox
                        id="WarnWhenMissing"
                        name="selectedComplianceType.warnWhenMissing"
                        checked={selectedComplianceType.warnWhenMissing ?? ''}
                        onChange={this.props.onChange}
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="ComplianceEquipmentTypes"
                        text="Equipment Type(s)"
                        required
                    />
                    <ValidatedSelect
                        id="ComplianceEquipmentTypes"
                        name="selectedComplianceType.complianceEquipmentTypeIds"
                        isMulti
                        required={
                            (
                                selectedComplianceType.complianceApplicationUserGroupIds
                                || []
                            ).length === 0
                        }
                        options={equipmentTypes}
                        isDisabled={
                            (
                                selectedComplianceType.complianceApplicationUserGroupIds
                                || []
                            ).length > 0
                        }
                        value={
                            (equipmentTypes ?? []).filter((x) => (
                                selectedComplianceType.complianceEquipmentTypeIds
                                    ?? []
                            ).includes(x.value)) ?? ''
                        }
                        onChange={this.props.onSelectChange}
                        validationMessage="At least one Equipment Type selection is required."
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="ComplianceEmployeeGroups"
                        text="Group(s)"
                        required
                    />
                    <ValidatedSelect
                        id="ComplianceEmployeeGroups"
                        name="selectedComplianceType.complianceApplicationUserGroupIds"
                        isMulti
                        required={
                            (
                                selectedComplianceType.complianceEquipmentTypeIds
                                || []
                            ).length === 0
                        }
                        options={employeeGroups}
                        isDisabled={
                            (
                                selectedComplianceType.complianceEquipmentTypeIds
                                || []
                            ).length > 0
                        }
                        value={
                            (employeeGroups ?? []).filter((x) => (
                                selectedComplianceType.complianceApplicationUserGroupIds
                                    ?? []
                            ).includes(x.value)) ?? ''
                        }
                        onChange={this.props.onSelectChange}
                        validationMessage="At least one Employee Group selection is required."
                    />
                </FormGroup>
            </SlideForm>
        );
    }
}

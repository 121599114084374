import React, { useState , useEffect } from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import 'rc-time-picker/assets/index.css';

const TimeInput = ({ disabled, readOnly, name, value, increment, onChange, fullWidth, size }) => {
    const [tempTime, setTempTime] = useState(() => {
        if (value && moment(value, 'HH:mm', true).isValid()) {
            return moment(value, 'HH:mm');
        }
        return null;
    });

    // Sync tempTime with value when value changes
    useEffect(() => {
        if (value && moment(value, 'HH:mm', true).isValid()) {
            setTempTime(moment(value, 'HH:mm'));
        } else {
            setTempTime(null);
        }
    }, [value]);

    const stepInMinutes = Math.round(increment * 60 || 1);

    // Round the current time to the nearest step increment
    const getRoundedTime = () => {
        const now = moment();
        const roundedMinutes = Math.round(now.minute() / stepInMinutes) * stepInMinutes;
        return now.clone().minute(roundedMinutes).second(0);
    };
    
    const handleOk = (panel) => {
        let currentTime;

        if (!tempTime) {
            // If no time is selected, use the current time rounded to the nearest step increment
            currentTime = getRoundedTime();
        } else {
        // Use the selected time
            currentTime = tempTime;
        }

        // Update the state and notify parent
        setTempTime(currentTime);


        if (onChange) {
            const formattedTime = currentTime.format('HH:mm');
            onChange({
                target: { name, value: formattedTime },
            });
        }

        if (panel && panel.close) {
            panel.close();
        }
    };

    const handleReset = (panel) => {
        setTempTime(null);
        if (onChange) {
            onChange({
                target: { name, value: '' },
            });
        }
        if (panel && panel.close) {
            panel.close();
        }
    };

    const handleTimeChange = (time) => {
        setTempTime(time);
        if (onChange && time) {
            onChange({ target: { name, value: time.format('HH:mm') } });
        }
    };

    const renderAddon = (panel) => (
        <div>
            <div className="time-picker-header">
                <Button
                    color="link"
                    onClick={() => panel?.close()}
                >
                    <FontAwesomeIcon
                        icon={faClose}
                        className="close-icon"
                    />
                </Button>
            </div>
            <div className="time-picker-footer">
                <Button className="mr-2" color="link" onClick={() => handleReset(panel)} >
                    Reset
                </Button>
                <Button color="primary" onClick={() => handleOk(panel)}>
                    OK
                </Button>
            </div>
        </div>
    );

    const inputClasses = classNames('rc-time-picker', {
        'rc-time-picker-sm': size === 'sm',
        'rc-time-picker-lg': size === 'lg',
        'w-100': fullWidth,
    });

    return (
        <div
            className={classNames('time-input-container', {
                'w-100': fullWidth,
                'time-input-read-only': readOnly,
            })}
        >
            <TimePicker
                showSecond={false}
                value={tempTime}
                defaultValue={getRoundedTime()}
                onChange={handleTimeChange}
                minuteStep={stepInMinutes}
                use12Hours={true}
                allowEmpty={false}
                inputReadOnly={readOnly}
                disabled={disabled || readOnly}
                addon={!readOnly && renderAddon} 
                className={inputClasses} 
            />
        </div>
    );
};

TimeInput.propTypes = {
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    increment: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    fullWidth: PropTypes.bool,
    size: PropTypes.string,
};

TimeInput.defaultProps = {
    required: false,
    disabled: false,
    readOnly: false,
    value: '',
    increment: '',
    fullWidth: false,
    size: 'sm',
};

export default TimeInput;

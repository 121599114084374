import { faTasks, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withRouter } from 'react-router-dom';

import {
    Button,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormText,
    Progress
} from 'reactstrap';
import CommonContext, { ApiRoutes, LocalizationKeys as l } from '../Common';
import {
    getFilterModel,
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    LinkCellRenderer,
    IconCellRenderer,
    VariableLinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import {
    PageHeading,
    PageWrap,
    toasty,
    FormLabel,
    onFieldChange,
} from '../common/forms/FormElements';
import TimesheetDetails from '../timesheet/TimesheetDetails';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { util } from '../Util';
import CustomCircularProgress from '../common/CustomCircularProgress';
import { NotAuthorizedPage } from '../status/StatusCodes';

class BillingIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.timesheetDetailsRef = React.createRef();
        const yesNo = [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
        ];

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            timesheetDetailsOpen: false,
            selectedDetails: [],
            showRejectTimesheetModal: false,
            isRejectingTimesheet: false,
            adminRejecting: false,
            selectedRowRejectionNotes: '',
            loadingReport: false,
            showExportModal: false,
            isExporting: false,
            yesNo,
            isApproving: false,
        };

        this.onRowClicked = this.onRowClicked.bind(this);
        this.onCellClicked = this.onCellClicked.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
    }

    componentDidMount() {
        return this.populateState(); 
    }

    componentWillUnmount() {
        return this.setState = (state, callback) => {

        };
    }

    onAcceptButtonClick = async () => {
        this.setState({ isApproving: true });

        const { rowsSelected } = this.state;

        // call accept
        if (rowsSelected) {
            const model = rowsSelected.map((x) => x.id);
            const response = await util.fetch
                .post(ApiRoutes.billing.approve(), model)
                .catch(this.handleSaveError);

            if (response) {
                toasty.error(`Error approving billing records: ${response}`);
            } else {
                toasty.success('Billing records approved');
            }
        }

        this.state.gridOptions.refresh();

        this.setState({ rowsSelected: [], isApproving: false });
    };

    onCellClicked = (data) => {
    // test
        alert(data);
    };

    onChange = onFieldChange;

    async onExportClick() {
    // this.setState({ loadingReport: true });
        this.setState({ isExporting: true });
        const { gridOptions } = this.state;
        let model = {};
        if (gridOptions.api.isAnyFilterPresent()) model = getFilterModel(gridOptions.api.getFilterModel());
        try {
            const response = await util.fetch.post(
                ApiRoutes.billing.export(),
                model,
            );
            if (response) {
                toasty.error(response);
            } else {
                toasty.success('Billing Report complete');
            }
        } catch {
            toasty.error('Error exporting billing');
        }
        // this.setState({ loadingReport: false });
        this.state.gridOptions.refresh();
        this.setState({ isExporting: false, showExportModal: false });
    }

    onRejectTimesheet = async () => {
        toasty.error('Not Implemented');
    };

    onRowClicked = (event) => {
        const selection = event.api.getSelectedRows();
        const row = selection.length ? selection[0] : null;

        if (row) {
            this.setState({ selectedRow: row });
            // this.openTimesheetDetails();
        }
    };

    // https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    // Possibly use lodash dequeue?
    onRowSelected(e) {
        const rs = e.api.getSelectedRows();

        this.setState({
            rowsSelected: rs,
        });
    }

    onTimesheetDetailsClosed = async () => {
        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });
        this.state.gridOptions.refresh();
    };

    // Used to load from cache for the list types.
    getInitialListFiltersFromCache(initialFilters) {
        const json = sessionStorage.getItem('billing_gridFilterSettings');

        if (json) {
            const obj = JSON.parse(json);

            initialFilters.dispatchId = obj.DispatchLocationId
                ? obj.DispatchLocationId.value
                : '';
            initialFilters.hasOverride = obj.HasOverride
                ? obj.HasOverride.value
                : '';
            initialFilters.companyId = obj.CustomerCompanyId
                ? parseInt(obj.CustomerCompanyId.value)
                : 0;
            initialFilters.operationsCenterId = obj.OperationsCenterId
                ? parseInt(obj.OperationsCenterId.value)
                : 0;
            initialFilters.billingStatusId = obj.BillingStatusId
                ? parseInt(obj.BillingStatusId.value)
                : 0;
            initialFilters.payrollStatusId = obj[
                'Timesheet.PayrollStatus.Description'
            ]
                ? parseInt(obj['Timesheet.PayrollStatus.Description'].value)
                : 0;
        }

        return initialFilters;
    }

    downloadExport = async (id) => {
        if (id) {
            this.setState({ loadingReport: true });
            /* var model = { id: id }; */
            await util.fetch
                .downloadFile(
                    ApiRoutes.billing.download(id),
                    null,
                    'Billing.pdf',
                )
                .catch(this.handleSaveError);
            this.setState({ loadingReport: false });
        }
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    openTimesheetDetails = async (id) => {
        if (id) {
            await this.context.setFormOpened(true);
            this.timesheetDetailsRef.current.open(id);
        }
    };

    populateState = async () => {
        const [dispatchLocations, billingStatuses, payrollStatuses, strings] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.dispatchLocations()),
            util.fetch.js(ApiRoutes.typeAheads.billingStatuses()),
            util.fetch.js(ApiRoutes.typeAheads.payrollStatuses()),
            util.l10n.getStrings([l.billing]),
        ]);

        let initialListFilters = {
            initialDispatchId: '',
            initialCustomerCompanyId: 0,
            initialOperationsCenterId: 0,
            initialBillingStatusId: 0,
            initialPayrollStatusId: 0,
            initialHasOverride: null,
        };

        initialListFilters = this.getInitialListFiltersFromCache(initialListFilters);

        const { tenantSettings } = this.context.tenant;
        const payrollEnabled = !!tenantSettings.payrollEnabled;

        const gridOptions = createGridOptions(this);
        const context = this;

        // https://www.ag-grid.com/documentation/javascript/row-selections#checkbox-selection
        // One column must have "checkboxSelection" set to true.
        // Note: headerCheckboxSelection not available with infinite scroll.
        gridOptions.rowSelection = 'multiple';

        gridOptions.postProcessData = this.transformRowData;
        gridOptions.onRowSelected = this.onRowSelected;

        gridOptions.rowClassRules = {
            'ag-row-warning': (params) => (params.data ?? {}).hasOverride === 'Yes',
            'ag-row-danger': (params) => (params.data ?? {}).isEmergency === true,
        };

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
            iconRenderer: IconCellRenderer,
            variableLinkRenderer: VariableLinkCellRenderer,
        };
        gridOptions.onRowClicked = this.onRowClicked;

        const dispatchFilterParams = {
            suppressFilterButton: true,
            options: dispatchLocations,
            optionsLabel: 'label',
            optionsValue: 'value',
            initialFilterValue: initialListFilters.dispatchId,
        };
        // timesheetStatusFilterParams = {
        //    suppressFilterButton: true,
        //    options: timesheetStatuses,
        //    optionsLabel: 'label',
        //    optionsValue: 'value'
        // },
        const billingStatusFilterParams = {
            suppressFilterButton: true,
            options: billingStatuses,
            optionsLabel: 'label',
            optionsValue: 'value',
            initialFilterValue: initialListFilters.billingStatusId,
        };
        const payrollStatusFilterParams = {
            suppressFilterButton: true,
            options: payrollStatuses,
            optionsLabel: 'label',
            optionsValue: 'value',
            initialFilterValue: initialListFilters.payrollStatusId,
        };
        const yesNoFilterParams = {
            suppressFilterButton: true,
            options: this.state.yesNo,
            optionsLabel: 'label',
            optionsValue: 'value',
            initialFilterValue: initialListFilters.hasOverride,
        };

        gridOptions.columnDefs = [
            {
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'JobDate',
                headerName: 'Date',
                field: 'date',
                sortable: true,
                flex: 1.5,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
                // floatingFilterComponentParams: {
                //    suppressFilterButton: true,
                // }
            },
            {
                colId: 'customerName',
                headerName: 'Customer Name',
                field: 'customerName',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'operationsCenterName',
                headerName: 'Operations Center',
                field: 'operationsCenterName',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'ContractNumber', // leave this, it doesnt map to an ef prop but is triggered for the sort on the dynamic field.
                headerName: 'Contract #',
                field: 'contractNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'DispatchLocationId',
                headerName: 'Dispatching',
                field: 'dispatchOfficeName',
                sortable: true,
                flex: 2,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: dispatchFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: dispatchFilterParams,
            },
            {
                colId: 'Timesheet.TimesheetNumber',
                headerName: 'Timesheet #',
                field: 'timesheetNumber',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'Timesheet.WorkOrderNumber',
                headerName: 'Work Order',
                field: 'workOrderNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'JobLocationAddress',
                headerName: 'Job Address',
                field: 'jobAddress',
                sortable: false,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                headerName: 'Flags',
                valueGetter: 'node.id',
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'BillingStatusId',
                headerName: 'Billing Status',
                field: 'billingStatus',
                sortable: true,
                flex: 1,
                cellRenderer: 'variableLinkRenderer',
                cellRendererParams: (r) => {
                    if ((r.data ?? {}).billingExportId) {
                        return {
                            clicked: this.downloadExport,
                            isLink: true,
                            nameField: 'billingStatus',
                            idField: 'billingExportId',
                            title: 'Download Export',
                        };
                    }
                    return {
                        clicked: this.downloadExport,
                        isLink: false,
                        nameField: 'billingStatus',
                        idField: 'billingExportId',
                        title: 'Download Export',
                    };
                },
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: billingStatusFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: billingStatusFilterParams,
            },
            {
                colId: 'Timesheet.PayrollStatus.Description',
                headerName: 'Payroll Status',
                field: 'payrollStatus',
                sortable: true,
                flex: 1.5,
                tooltipField: 'status',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: payrollStatusFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: payrollStatusFilterParams,
                hide: !payrollEnabled,
            },
            {
                colId: 'HasOverride',
                headerName: 'Billing Override',
                field: 'hasOverride',
                sortable: true,
                flex: 1.5,
                tooltipField: 'status',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: yesNoFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: yesNoFilterParams,
            },
            {
                colId: 'Id',
                flex: 0,
                maxWidth: 75,
                headerName: 'View',
                sortable: false,
                cellRenderer: 'iconRenderer',
                cellRendererParams: {
                    clicked: this.openTimesheetDetails,
                    idField: 'timesheetId',
                    iconClass: 'fa-eye',
                },
            },
            {
                colId: 'Id',
                flex: 0,
                maxWidth: 75,
                headerName: 'Print',
                sortable: false,
                cellRenderer: 'iconRenderer',
                cellRendererParams: {
                    clicked: this.printTimesheet,
                    idField: 'timesheetId',
                    iconClass: 'fa-print',
                },
            },
            {
                colId: 'SelectionPlaceholder',
                headerName: '',
                field: 'selectionPlaceholder',
                sortable: false,
                maxWidth: 75,
                checkboxSelection: true,
            },
        ];

        /// ///////////////////////////////////////////////////////
        // RJP - Cache and reload filter and sort changes
        gridOptions.onFilterChanged = (params) => {
            const filterModel = gridOptions.api.getFilterModel();
            sessionStorage.setItem(
                'billing_gridFilterSettings',
                JSON.stringify(filterModel),
            );
        };

        gridOptions.onSortChanged = (params) => {
            const sortModel = gridOptions.api.getSortModel();
            sessionStorage.setItem(
                'billing_gridSortSettings',
                JSON.stringify(sortModel),
            );
        };

        gridOptions.onFirstDataRendered = (params) => {
            const sortModel = sessionStorage.getItem('billing_gridSortSettings');
            if (sortModel) {
                const obj = JSON.parse(sortModel);
                gridOptions.api.setSortModel(obj);
            }
        };

        gridOptions.onGridReady = (params) => {
            const filterModel = sessionStorage.getItem(
                'billing_gridFilterSettings',
            );
            if (filterModel) {
                const obj = JSON.parse(filterModel);

                // Have to set individually here instead of using the whole model
                // due to list types requiring a different initialization routine
                if (obj.ContractNumber) {
                    const filterInstance = gridOptions.api.getFilterInstance('ContractNumber');
                    filterInstance.setModel(obj.ContractNumber);
                    gridOptions.api.onFilterChanged();
                }
                if (obj['Timesheet.TimesheetNumber']) {
                    const filterInstance = gridOptions.api.getFilterInstance(
                        'Timesheet.TimesheetNumber',
                    );
                    filterInstance.setModel(obj['Timesheet.TimesheetNumber']);
                    gridOptions.api.onFilterChanged();
                }
                if (obj.JobLocationAddress) {
                    const filterInstance = gridOptions.api.getFilterInstance('JobLocationAddress');
                    filterInstance.setModel(obj.JobLocationAddress);
                    gridOptions.api.onFilterChanged();
                }
                if (obj.JobDate) {
                    const filterInstance = gridOptions.api.getFilterInstance('JobDate');
                    filterInstance.setModel(obj.JobLocationAddress);
                    gridOptions.api.onFilterChanged();
                }
            }

            params.api.setDatasource(context.state.dataSource);
            context.setState({ gridApi: params.api });
        };

        // END - Cache and reload filter and sort changes
        /// //////////////////////////////////////////////////////

        const dataSource = createDataSource(
            ApiRoutes.billing.search(),
            gridOptions,
        );

        this.setState({
            loading: false,
            gridOptions,
            dataSource,
            isRejectingTimesheet: false,
            strings,
        });
    };

    printTimesheet = async (id) => {
        if (id) {
            window.open(ApiRoutes.report.timesheetBilling(id), '_self');
        }
    };

    rejectTimesheet = async () => {
        const { rowsSelected, selectedRowRejectionNotes, adminRejecting } = this.state;

        if (
            selectedRowRejectionNotes.length === 0
            || !selectedRowRejectionNotes.trim()
        ) return;
        this.setState({ isRejectingTimesheet: true });

        // call reject
        if (rowsSelected) {
            const model = {
                billingId: rowsSelected[0].id,
                note: selectedRowRejectionNotes,
            };

            const endPoint = adminRejecting == true
                ? ApiRoutes.billing.adminReject()
                : ApiRoutes.billing.reject();
            const response = await util.fetch
                .post(endPoint, model)
                .catch(this.handleSaveError);

            if (response) {
                toasty.success('Billing: Timesheet Rejected');
            }
        }

        this.state.gridOptions.refresh();

        this.setState({
            selectedRow: null,
            showRejectTimesheetModal: false,
            selectedRowRejectionNotes: '',
            isRejectingTimesheet: false,
            adminRejecting: false,
        });
    };

    async toggleExportModal() {
        const { showExportModal } = this.state;
        this.setState({ showExportModal: !showExportModal });
    }

    toggleRejectTimesheetModal = async () => {
        const { showRejectTimesheetModal, rowsSelected } = this.state;

        const adminRequired = await util.fetch.get(
            ApiRoutes.billing.needsAdminReject(rowsSelected[0].id),
        );

        // if admin not needed just show regular
        if (adminRequired == false) {
            this.setState({
                showRejectTimesheetModal: !showRejectTimesheetModal,
            });
            return;
        }

        // if admin needed and user is not admin
        if (adminRequired == true && !this.context?.user?.isAdmin) {
            toasty.info(
                'An admin needs to reject this timesheet due to it being approved already',
            );
            return;
        }

        // if admin needed and is an admin
        if (adminRequired == true && this.context?.user?.isAdmin) {
            this.setState({
                showRejectTimesheetModal: !showRejectTimesheetModal,
                adminRejecting: true,
            });
        }
    };

    render() {
        const {
            rowData,
            gridOptions,
            showRejectTimesheetModal,
            isRejectingTimesheet,
            isApproving,
            selectedRowRejectionNotes,
            rowsSelected,
            isExporting,
            showExportModal,
            adminRejecting,
            strings,
        } = this.state;

        const billing = strings ? strings[l.billing] : '';

        // TODO: Eliminate inline styles.

        return (
            <CommonContext.Consumer>
                {(value) => {
                    const { tenantSettings } = (value ?? {}).tenant ?? {};

                    if (this.state.loading || !tenantSettings) {
                        return (
                            <Progress />
                        );
                    }

                    if (!tenantSettings.billingEnabled) return <NotAuthorizedPage />;

                    return (
                        <PageWrap>
                            <PageHeading>
                                <FontAwesomeIcon
                                    icon={faTasks}
                                    className="mr-2 text-muted"
                                />
                                <span>{billing}</span>
                                <span
                                    style={{
                                        float: 'right',
                                        position: 'relative',
                                        top: '-5px',
                                    }}
                                >
                                    <Button
                                        size="sm"
                                        style={{ marginRight: '10px' }}
                                        color="danger"
                                        onClick={() => this.toggleExportModal()}
                                    >
                    Export
                                    </Button>
                                    <Button
                                        size="sm"
                                        color="success"
                                        style={{ marginRight: '10px' }}
                                        onClick={() => this.onAcceptButtonClick()}
                                        disabled={
                                            rowsSelected.length === 0
                                            || isApproving
                                        }
                                    >
                    Approve Selected
                                    </Button>
                                    <Button
                                        size="sm"
                                        color="danger"
                                        onClick={() => this.toggleRejectTimesheetModal()}
                                        disabled={
                                            rowsSelected.length !== 1
                                            || isRejectingTimesheet
                                        }
                                    >
                    Reject Selected
                                    </Button>
                                </span>
                            </PageHeading>

                            <DataGridToolbar
                                entity="Billing"
                                gridApi={this.state.gridApi}
                                dataSource={this.state.dataSource}
                                hideAdd
                                hideExcelButton
                                gridOptions={this.state.gridOptions}
                                serverExport={{
                                    apiPath: ApiRoutes.billing.excelExport(),
                                    filePrefix: 'BillingSearch',
                                }}
                                serverExportDisabled={
                                    !!this.state.saving
                                    || !!this.state.loading
                                    || !!this.state.loadingData
                                }
                            />
                            {!!this.state.loadingReport && (
                                <CustomCircularProgress />
                            )}

                            {!this.state.loadingReport && (
                                <DataGrid
                                    domLayout="normal"
                                    rowData={rowData}
                                    gridOptions={gridOptions}
                                    gridStatus={this.state.gridStatus}
                                />
                            )}
                            <TimesheetDetails
                                ref={this.timesheetDetailsRef}
                                show={this.state.timesheetDetailsOpen}
                                toggleShow={(open) => this.setState({
                                    timesheetDetailsOpen: open,
                                })}
                                onClose={this.onTimesheetDetailsClosed}
                                onAccept={this.onAcceptTimesheet}
                                onReject={this.onRejectTimesheet}
                                onlyBillable
                                isBilling
                            />
                            <Modal
                                isOpen={showRejectTimesheetModal}
                                toggle={this.toggleRejectTimesheetModal}
                            >
                                <ModalHeader
                                    toggle={this.toggleRejectTimesheetModal}
                                >
                  Billing: Reject Timesheet
                                </ModalHeader>
                                <ModalBody>
                                    {adminRejecting ? (
                                        <small
                                            style={{ marginBottom: '20px' }}
                                            className="form-text text-danger"
                                        >
                      This administrative action will
                      delete any approved payroll or
                      billing records, and reset the
                      timesheet. Only administrators will
                      be allowed to adjust and re-approve
                      this timesheet. Please take note of
                      Payroll and Billing implications.
                                        </small>
                                    ) : (
                                        <FormText
                                            style={{ marginBottom: '20px' }}
                                        >
                      This action will reject the
                      timesheet from billing review and
                      remove all charges from payroll
                      review.
                                        </FormText>
                                    )}
                                    <FormGroup>
                                        <FormLabel
                                            required
                                            text="Notes"
                                        />
                                        <textarea
                                            id="selectedRowRejectionNotes"
                                            name="selectedRowRejectionNotes"
                                            className="form-control"
                                            defaultValue={
                                                selectedRowRejectionNotes ?? ''
                                            }
                                            onChange={this.onChange}
                                            required
                                            placeholder="Enter notes regarding the rejection."
                                            type="text"
                                            maxLength="500"
                                            rows="5"
                                        />
                                        <small className="text-danger">
                      Notes are required.
                                        </small>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isRejectingTimesheet && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isRejectingTimesheet}
                                        onClick={this.rejectTimesheet}
                                    >
                    Ok
                                    </Button>
                                    {' '}
                                </ModalFooter>
                            </Modal>
                            <Modal
                                isOpen={showExportModal}
                                toggle={() => this.toggleExportModal()}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleExportModal()}
                                >
                  Export Billing
                                </ModalHeader>
                                <ModalBody>
                                    <p>
                    Export will be generated based upon
                    search criteria entered on the billing
                    screen. If the result set is too large
                    the export may timeout.
                                    </p>
                                </ModalBody>
                                <ModalFooter>
                                    {isExporting && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isExporting}
                                        onClick={() => this.onExportClick()}
                                    >
                    Ok
                                    </Button>
                                    {' '}
                                    <Button
                                        color="secondary"
                                        onClick={() => this.setState({
                                            showExportModal: false,
                                        })}
                                    >
                    Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>
                        </PageWrap>
                    );
                }}
            </CommonContext.Consumer>
        );
    }
}

export default withRouter(BillingIndex);

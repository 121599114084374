import React, { Fragment } from 'react';
import { faSave, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import {
    Button, Input, FormGroup, Row, Col,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prompt, withRouter } from 'react-router-dom';
import { isEqual } from 'lodash-es';
import {
    AppPageForm, FlexCenterRow, /* FlexEndRow, */ FormCheckbox, FormDivider, onFieldChange, onReactSelectChanged, SubHeading, toasty,
    FormLabel,
} from '../common/forms/FormElements';
import { BaseFormViewModel } from '../common/ViewModel';
import CommonContext, { ApiRoutes, AppNavPaths, SiteRoles } from '../Common';
import { Group } from './Group';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { handleFormSaveError } from '../common/forms/ValidationError';

class GroupForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const stateBase = {
            group: new Group(),
            roles: [],
            dispatchCompanies: [],
            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        return this.populateState(); 
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps && (this.props.match.params.id !== (prevProps.match.params ?? {}).id)) {
            this.populateState();
        }
    }

    onChange = onFieldChange;

    onClearErrors = () => this.setState((state) => ({ errors: {} }));

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    };

    onDelete = async (e) => {
        const { group } = this.state;
        const response = await util.fetch.delete(ApiRoutes.company.delete(group.id)).catch(this.onCatchFetchError);
        if (response) this.onClose(response);
    };

    onSelectChanged = onReactSelectChanged;

    onSubmit = async (e) => {
    // Clear any fluent api errors
        this.onClearErrors();
        this.setSaving(true);

        const { group } = this.state;

        const cloned = util.object.clone(group);

        this.props.location.pathname == AppNavPaths.GroupNew ? this.createGroup(cloned) : this.updateGroup(cloned);
    };

    setSaving = (b) => this.setState({ saving: b });

    addAllDispatching = () => {
        const { group, dispatchCompanies } = { ...this.state };
        group.expandedDispatchCompanies = [...dispatchCompanies.map((x) => x.value)];
        this.setState({ group });
    };

    createGroup = async (group) => {
        const result = await util.fetch.andGetResponse(
            util.fetch.types.post,
            ApiRoutes.group.create(),
            { ...group },
            'Error Creating Group',
        );

        if (result) {
            toasty.success('Group created.');
            this.props.history.push(`${AppNavPaths.Group}/${result.data}`);
        } else {
            this.setState({ saving: false });
        }
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    async populateState() {
        const groupId = this.props.match.params.id;

        const [roles, group, dispatchLocs] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.roles()),
            groupId ? util.fetch.js(ApiRoutes.group.byId(groupId)) : new Group(),
            util.fetch.js(ApiRoutes.company.dispatchCompanies()),
        ]);

        const dispatchLocsMapped = (dispatchLocs ?? []).map((x) => ({ label: x.companyName, value: x.id }));

        const originalData = util.object.clone(group);

        this.setState((state) => ({
            originalData,
            group,
            loading: false,
            roles,
            saving: false,
            dispatchCompanies: dispatchLocsMapped,
        }));
    }

    removeAllDispatching = () => {
        const { group } = { ...this.state };
        group.expandedDispatchCompanies = [];
        this.setState({ group });
    };

    resetForm = () => this.setState({ formValidated: false });

    updateGroup = async (group) => {
        const result = await util.fetch.andGetResponse(
            util.fetch.types.put,
            ApiRoutes.group.byId(group.id),
            { ...group },
            'Error Saving Group',
        );

        if (result) {
            toasty.success('Group saved.');
            this.props.history.go(0);
        } else {
            this.setState({ saving: false });
        }
    };

    render() {
        const {
            group,
            originalData,
            errors,
            formValidated,
            roles,
            saving,
            dispatchCompanies,
        } = this.state;

        const existing = !!group.id;

        const selectedDispatchCompanies = (dispatchCompanies ?? []).filter((x) => (group.expandedDispatchCompanies ?? []).includes(x.value));
        const groupRoles = (group?.userRoles ?? []);

        if (selectedDispatchCompanies.length) selectedDispatchCompanies.sort((a, b) => (a.label > b.label ? 1 : -1));

        // If admin or regional support is selected as a role for this group, there is no need to show
        // the dispatching dropdown, as these roles get all dispatching companies by default.
        const adminOrRegSpptSelected = groupRoles.includes(SiteRoles.Administrator)
            || groupRoles.includes(SiteRoles.RegionalSupport);

        return (
            <>
                <Prompt
                    when={!saving && !isEqual(originalData, group)}
                    message="You have unsaved changes, are you sure you want to leave?"
                />
                <AppPageForm
                    formShown={this.context.formIsOpen}
                    formId="groupForm"
                    formHeadingIcon={faWarehouse}
                    formHeading={!group.id ? 'New Group' : 'Edit Group'}
                    formName="groupForm"
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => {
                        this.setState({ formValidated: value }); 
                    }}
                    isValidated={formValidated}
                    saving={saving}
                    errors={errors}
                    onClearErrors={this.onClearErrors}
                    loading={this.state.loading}
                >
                    <br />
                    <SubHeading>Details</SubHeading>
                    <Row className="pt-3">
                        <Col xl="6" lg="8" md="10" sm="12" className="ml-auto mr-auto">
                            <FormGroup>
                                <FormLabel
                                    htmlFor="groupName"
                                    text="Name"
                                    required
                                />
                                <Input
                                    id="groupName"
                                    name="group.groupName"
                                    value={group.groupName ?? ''}
                                    onChange={this.onChange}
                                    placeholder="Enter Name (max 150 characters)"
                                    maxLength="150"
                                    pattern="[^()/><\][\\\x22,;|]+"
                                    type="text"
                                    required
                                />
                                <small className="invalid-feedback text-danger">Name is required and can only contain the following special characters: hyphens and periods.</small>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="type" text="Roles" required />
                                <ValidatedSelect
                                    id="types"
                                    name="group.userRoles"
                                    required
                                    isMulti
                                    options={roles}
                                    value={(roles ?? []).filter((x) => (group.userRoles ?? []).includes(x.value)) ?? ''}
                                    onChange={this.onSelectChanged}
                                    validationMessage="A group role is required."
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel
                                    htmlFor="code"
                                    text="Code"
                                    required={false}
                                />
                                <Input
                                    id="code"
                                    name="group.code"
                                    value={group.code ?? ''}
                                    onChange={this.onChange}
                                    placeholder="Enter Code (max 15 characters)"
                                    maxLength="15"
                                    type="text"
                                />
                                <small className="invalid-feedback text-danger">Name is required and can only contain the following special characters: hyphens and periods.</small>
                            </FormGroup>
                            <FormGroup>
                                <FormCheckbox
                                    className="mt-2 mb-2"
                                    id="isFieldGroup"
                                    checked={group.isFieldGroup}
                                    onChange={(event) => {
                                        const value = event.target.checked;
                                        this.setState((state) => (state.group.isFieldGroup = value, state));
                                    }}
                                    labelText="This is a field personnel group"
                                />
                                <FormCheckbox
                                    className="mt-2 mb-2"
                                    id="isImpersonatedGroup"
                                    checked={group.isImpersonatedGroup}
                                    onChange={(event) => {
                                        const value = event.target.checked;
                                        this.setState((state) => (state.group.isImpersonatedGroup = value, state));
                                    }}
                                    labelText="Members of this group can be impersonated"
                                />
                                <FormCheckbox
                                    className="mt-2 mb-2"
                                    id="isExcludedFromBillingReport"
                                    checked={group.isExcludedFromBillingReport}
                                    onChange={(event) => {
                                        const value = event.target.checked;
                                        this.setState((state) => (state.group.isExcludedFromBillingReport = value, state));
                                    }}
                                    labelText="Exclude this group from the payroll billing report"
                                />
                                <FormCheckbox
                                    className="mt-2 mb-2"
                                    id="requireMFA"
                                    checked={group.requireMFA}
                                    onChange={(event) => {
                                        const value = event.target.checked;
                                        this.setState((state) => (state.group.requireMFA = value, state));
                                    }}
                                    labelText="Multi-factor authentication"
                                />
                            </FormGroup>
                            {
                                !adminOrRegSpptSelected
                                && (
                                    <FormGroup>
                                        <FormCheckbox
                                            className="mt-2 mb-2"
                                            id="expandDispatching"
                                            checked={group?.expandDispatching ?? false}
                                            onChange={(event) => {
                                                const value = event.target.checked;
                                                this.setState((state) => (state.group.expandDispatching = value, state));
                                            }}
                                            labelText="Allow Expanded Dispatching Assignments"
                                        />
                                    </FormGroup>
                                )
                            }
                            {/* { */}
                            {/*    !adminOrRegSpptSelected && !!group.expandDispatching && */}
                            {/*    <FormGroup> */}
                            {/*        <FormLabel */}
                            {/*            htmlFor="dispatchCompanies" */}
                            {/*            text="Expanded Dispatching" */}
                            {/*            required={false} /> */}
                            {/*        <ValidatedSelect */}
                            {/*            id="dispatchCompanies" */}
                            {/*            name="group.expandedDispatchCompanies" */}
                            {/*            options={dispatchCompanies} */}
                            {/*            isMulti */}
                            {/*            required={false} */}
                            {/*            value={selectedDispatchCompanies ?? ''} */}
                            {/*            onChange={this.onSelectChanged} */}
                            {/*            validationMessage="A Dispatching selection is required when expanding dispatching assignments." */}
                            {/*        /> */}
                            {/*        <FlexEndRow className="w-100 pt-2 pl-2"> */}
                            {/*            <small className="site-link mr-2" onClick={this.addAllDispatching}>Add All</small> */}
                            {/*            // */}
                            {/*            <small className="site-link ml-2" onClick={this.removeAllDispatching}>Remove All</small> */}
                            {/*        </FlexEndRow> */}
                            {/*    </FormGroup> */}
                            {/* } */}
                        </Col>
                    </Row>
                    <FormDivider />

                    <FlexCenterRow className="mb-3">
                        <Button
                            disabled={!!this.state.saving}
                            size="sm"
                            type="submit"
                            color="primary"
                            name="groupForm"
                        >
                            <FontAwesomeIcon
                                className="mr-2"
                                icon={faSave}
                            />
                            {this.state.saving
                                ? 'Saving...'
                                : (!existing ? 'Save New group' : 'Save')}
                        </Button>
                        <Button
                            disabled={!!this.state.saving}
                            size="sm"
                            type="button"
                            color="secondary"
                            className="ml-3"
                            onClick={() => this.props.history.push(AppNavPaths.Groups)}
                        >
                            <span className="mr-2 fa fa-long-arrow-alt-left" />
              Back to Groups
                        </Button>
                    </FlexCenterRow>

                </AppPageForm>
            </>
        );
    }
}
export default withRouter(GroupForm);

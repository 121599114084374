import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faTasks, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import './InvoiceGenerationIndex.scss';
import React from 'react';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import {
    Button,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Progress,
} from 'reactstrap';
import { CompactSelectStyles } from '../common/forms/ValidatedSelect';
import SlideForm from '../common/forms/SlideForm';
import BillingIssuesWidget from '../widgets/BillingIssuesWidget';
import CommonContext, { ApiRoutes, ApplicationPermissions } from '../Common';
import {
    getFilterModel,
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    LinkCellRenderer,
    IconCellRenderer,
    VariableLinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
} from '../common/dataGrid/DataGrid';
import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import {
    SimpleTable,
    FormCheckbox,
    SmallButton,
    PageHeading,
    PageWrap,
    toasty,
    FormLabel,
    onFieldChange,
    FlexCenterRow,
} from '../common/forms/FormElements';
import TimesheetDetails from '../timesheet/TimesheetDetails';
import InvoiceSlideout from '../invoice/InvoiceSlideout';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { util } from '../Util';
import CustomCircularProgress from '../common/CustomCircularProgress';
import { NotAuthorizedPage } from '../status/StatusCodes';
import { BillingStatus } from '../billing/Billing';
import { getTenantUserProfile } from '../common/TenantUserProfile';

class InvoiceGenerationIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.timesheetDetailsRef = React.createRef();
        this.invoiceSlideoutRef = React.createRef();
        this.billingIssuesSlideoutRef = React.createRef();

        const yesNo = [
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
        ];

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            timesheetDetailsOpen: false,
            invoiceSlideoutOpen: false,
            selectedDetails: [],
            showRejectTimesheetModal: false,
            showRejectBillingTimesheetModal: false,
            showMoveTimesheetModal: false,
            isMovingTimesheet: false,
            isRejectingTimesheet: false,
            isRejectingBillingTimesheet: false,
            adminRejecting: false,
            selectedRowRejectionNotes: '',
            loadingReport: false,
            showExportModal: false,
            isExporting: false,
            yesNo,
            isApproving: false,
            isInvoicing: false,
            createJob: false,
            showBillingIssuesSlideout: false,
            billingIssueMessages: [],
            filterModel: null,
            invoicedTimesheetsSelected: false,
        };

        this.onRowClicked = this.onRowClicked.bind(this);
        this.onCellClicked = this.onCellClicked.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
    }

    componentDidMount() {
        return this.populateState(); 
    }

    componentWillUnmount() {
        return this.setState = (state, callback) => {

        };
    }

    onAcceptBillingIssueReply = async (id, message) => {
        const model = {
            timesheetId: id,
            notes: message,
        };

        await util.fetch.post(ApiRoutes.billingIssues.accept(), model);

        toasty.success('Billing reply accepted');

        this.setState({
            showBillingIssuesSlideout: false,
        });

        this.state.gridOptions.refresh();
    };

    onBillingIssuesSlideout = async (id) => {
        const messages = await util.fetch.js(
            ApiRoutes.billingIssues.messages(id),
        );

        this.setState({
            billingIssueMessages: messages,
            showBillingIssuesSlideout: true,
        });

        this.billingIssuesSlideoutRef.current.open();
    };

    onCellClicked = (data) => {
    // test
        alert(data);
    };

    onChange = onFieldChange;

    async onExportClick() {
    // this.setState({ loadingReport: true });
        this.setState({ isExporting: true });
        const { gridOptions } = this.state;
        let model = {};
        if (gridOptions.api.isAnyFilterPresent()) model = getFilterModel(gridOptions.api.getFilterModel());
        try {
            const response = await util.fetch.post(
                ApiRoutes.invoiceGeneration.export(),
                model,
            );
            if (response) {
                toasty.error(response);
            } else {
                toasty.success('Invoice Report complete');
            }
        } catch {
            toasty.error('Error exporting invoice');
        }
        // this.setState({ loadingReport: false });
        this.state.gridOptions.refresh();
        this.setState({ isExporting: false, showExportModal: false });
    }

    onGenerateInvoice = async () => {
        this.setState({ isInvoicing: true });

        const { rowsSelected } = this.state;

        // call accept
        if (rowsSelected) {
            // check if all the selected TS are from same contract
            const contractIds = rowsSelected.map((x) => x.contractId);
            const distinctContractIds = [...new Set(contractIds)];
            const workOrderNumbers = rowsSelected.map((x) => x.workOrderNumber?.toUpperCase());
            const invoiced = rowsSelected.filter((x) => x.billingStatus == 'Invoiced');

            const distinctWorkOrderNumbers = [...new Set(workOrderNumbers)];

            if (invoiced.length > 0) {
                toasty.error(
                    'Selections contain timesheets that are already invoiced.',
                );
                this.setState({ isInvoicing: false });
            } else if (distinctContractIds.length !== 1) {
                // throw error
                toasty.error(
                    'Selected timesheets are not from the same contract.',
                );
                this.setState({ isInvoicing: false });
            } else {
                const { allowMultipleWONumbers } = rowsSelected[0];

                if (
                    distinctWorkOrderNumbers.length !== 1
                    && !allowMultipleWONumbers
                ) {
                    // throw error
                    toasty.error(
                        'Selected timesheets are not from the same work order.',
                    );
                    this.setState({ isInvoicing: false });
                } else {
                    await this.context.setFormOpened(true);
                    // get rows ids
                    const model = {
                        billingIds: rowsSelected.map((x) => x.id),
                        invoiceId: null,
                    };
                    this.invoiceSlideoutRef.current.open(model);
                }
            }
        }
    };

    onInvoiceChargeReport = async () => {
        const { rowsSelected } = this.state;

        if (rowsSelected) {
            const model = rowsSelected.map((x) => x.id);

            await util.fetch
                .downloadFile(
                    ApiRoutes.invoiceGeneration.generateChargeReport(),
                    model,
                    'InvoiceChargeReport.xlsx',
                )
                .catch(this.handleSaveError);
        }
    };

    onInvoiceSlideoutClosed = async () => {
        await this.context.setFormOpened(false);
        this.setState({ rowsSelected: [], isInvoicing: false });
        this.state.gridOptions.refresh();
    };

    onRejectTimesheet = async () => {
        toasty.error('Not Implemented');
    };

    onRejectToBillingIssues = async (id, message, updateStatus) => {
        if (message == null || message.length == 0) {
            toasty.error('Please supply rejection notes');
            return;
        }

        const model = {
            timesheetId: id,
            notes: message,
            updateStatus,
        };

        await util.fetch.post(ApiRoutes.billingIssues.reject(), model);

        toasty.success('Billing row rejected to Billing Issues');

        this.setState({
            showBillingIssuesSlideout: false,
        });

        this.state.gridOptions.refresh();
    };

    onRowClicked = (event) => {
        const selection = event.api.getSelectedRows();
        const row = selection.length ? selection[0] : null;

        if (row) {
            this.setState({ selectedRow: row });
            // this.openTimesheetDetails();
        }
    };

    // https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    // Possibly use lodash dequeue?
    onRowSelected(e) {
        let { invoicedTimesheetsSelected } = this.state;

        const rs = e.api.getSelectedRows();

        const invoicedItems = rs.filter((x) => x.billingStatus == 'Invoiced');
        invoicedTimesheetsSelected = invoicedItems.length > 0;

        this.setState({
            rowsSelected: rs,
            invoicedTimesheetsSelected,
        });
    }

    onTimesheetDetailsClosed = async () => {
        await this.context.setFormOpened(false);
        this.setState({ selectedRow: null });
        this.state.gridOptions.refresh();
    };

    // Used to load from cache for the list types.
    getInitialListFiltersFromCache(initialFilters) {
        const json = sessionStorage.getItem('billing_gridFilterSettings');

        if (json) {
            const obj = JSON.parse(json);

            initialFilters.dispatchId = obj.DispatchLocationId
                ? obj.DispatchLocationId.value
                : '';
            initialFilters.hasOverride = obj.HasOverride
                ? obj.HasOverride.value
                : '';
            initialFilters.companyId = obj.CustomerCompanyId
                ? parseInt(obj.CustomerCompanyId.value)
                : 0;
            initialFilters.operationsCenterId = obj.OperationsCenterId
                ? parseInt(obj.OperationsCenterId.value)
                : 0;
            initialFilters.billingStatusId = obj.BillingStatusId
                ? parseInt(obj.BillingStatusId.value)
                : 0;
            initialFilters.payrollStatusId = obj[
                'Timesheet.PayrollStatus.Description'
            ]
                ? parseInt(obj['Timesheet.PayrollStatus.Description'].value)
                : 0;
        }

        return initialFilters;
    }

    setOnHold = async (id) => {
        if (id) {
            const response = await util.fetch
                .post(ApiRoutes.invoiceGeneration.setOnHold(), id)
                .catch(this.handleSaveError);

            if (response) {
                toasty.error(`Error setting on hold: ${response}`);
            } else {
                toasty.success('Hold set');
            }

            this.state.gridOptions.refresh();
        }
    };

    downloadExport = async (id) => {
        if (id) {
            this.setState({ loadingReport: true });
            /* let model = { id: id }; */
            await util.fetch
                .post(ApiRoutes.invoiceGeneration.download(id))
                .catch(this.handleSaveError);
            this.setState({ loadingReport: false });
        }
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    moveTimesheets = async () => {
        const { selectedContract, allTimesheetsByJob } = this.state;

        this.setState({ isMovingTimesheet: true });

        const groupContainsInvoice = allTimesheetsByJob.filter((e) => e.invoiceStatusId > 0).length > 0;
        const groupMixSelected = allTimesheetsByJob.filter((e) => e.convert == false).length > 0;

        const model = {
            contractId: selectedContract,
            timesheetIds: allTimesheetsByJob
                .filter((x) => x.convert == true)
                .map((e) => e.id),
            createJob: groupContainsInvoice || groupMixSelected,
        };

        try {
            const response = await util.fetch.post(
                ApiRoutes.contract.moveTimesheets(),
                model,
            );

            if (response.result == 0) {
                toasty.success('Timesheets moved');
            } else {
                toasty.error('Error moving timesheets');
            }

            this.state.gridOptions.refresh();
        } catch {
            toasty.error('Error moving timesheets');
        }

        this.setState({
            isMovingTimesheet: false,
            showMoveTimesheetModal: false,
        });
    };

    openTimesheetDetails = async (id) => {
        if (id) {
            await this.context.setFormOpened(true);
            this.timesheetDetailsRef.current.open(id);
        }
    };

    populateState = async () => {
        let [tenantUserProfile, dispatchLocations, billingStatuses] = await Promise.all([
            getTenantUserProfile(),
            util.fetch.js(ApiRoutes.typeAheads.dispatchLocations()),
            util.fetch.js(ApiRoutes.typeAheads.billingStatuses()),
        ]);

        const { userPermissions } = { ...tenantUserProfile };
        const canMoveTimesheetContract = userPermissions.includes(ApplicationPermissions.billing_tscontract_update);

        let initialListFilters = {
            initialDispatchId: '',
            initialCustomerCompanyId: 0,
            initialOperationsCenterId: 0,
            initialBillingStatusId: 6,
            initialPayrollStatusId: 0,
            initialHasOverride: null,
        };

        billingStatuses = billingStatuses.filter(
            (x) => x.value == 6
                || x.value == 4
                || x.value == 8
                || x.value == 10
                || x.value == 9,
        );
        initialListFilters = this.getInitialListFiltersFromCache(initialListFilters);

        const gridOptions = createGridOptions(this);
        const context = this;

        // https://www.ag-grid.com/documentation/javascript/row-selections#checkbox-selection
        // One column must have "checkboxSelection" set to true.
        // Note: headerCheckboxSelection not available with infinite scroll.
        gridOptions.rowSelection = 'multiple';

        gridOptions.postProcessData = this.transformRowData;
        gridOptions.onRowSelected = this.onRowSelected;

        gridOptions.rowClassRules = {
            'ag-row-warning': (params) => (params.data ?? {}).hasOverride === 'Yes',
            'ag-row-danger': (params) => (params.data ?? {}).isEmergency === true,
            'ag-row-otjobstart': (params) => (params.data ?? {}).hasDT === true,
        };

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
            iconRenderer: IconCellRenderer,
            variableLinkRenderer: VariableLinkCellRenderer,
        };
        gridOptions.onRowClicked = this.onRowClicked;

        const dispatchFilterParams = {
            suppressFilterButton: true,
            options: dispatchLocations,
            optionsLabel: 'label',
            optionsValue: 'value',
            initialFilterValue: initialListFilters.dispatchId,
        };
        const billingStatusFilterParams = {
            suppressFilterButton: true,
            options: billingStatuses,
            optionsLabel: 'label',
            optionsValue: 'value',
            initialFilterValue: initialListFilters.initialBillingStatusId,
        };
        const yesNoFilterParams = {
            suppressFilterButton: true,
            options: this.state.yesNo,
            optionsLabel: 'label',
            optionsValue: 'value',
            initialFilterValue: initialListFilters.hasOverride,
        };

        gridOptions.columnDefs = [
            {
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'JobDate',
                headerName: 'Date',
                field: 'date',
                sortable: true,
                flex: 1.5,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
                // floatingFilterComponentParams: {
                //    suppressFilterButton: true,
                // }
            },
            {
                colId: 'customerName',
                headerName: 'Customer Name',
                field: 'customerName',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'ContractNumber', // leave this, it doesnt map to an ef prop but is triggered for the sort on the dynamic field.
                headerName: 'Contract #',
                field: 'contractNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'DispatchLocationId',
                headerName: 'Dispatching',
                field: 'dispatchOfficeName',
                sortable: true,
                flex: 2,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: dispatchFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: dispatchFilterParams,
            },
            {
                colId: 'Timesheet.TimesheetNumber',
                headerName: 'Timesheet #',
                field: 'timesheetNumber',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'Timesheet.WorkOrderNumber',
                headerName: 'Work Order',
                field: 'workOrderNumber',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'JobLocationAddress',
                headerName: 'Job Address',
                field: 'jobAddress',
                sortable: false,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                headerName: 'Flags',
                valueGetter: 'node.id',
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'Foreman',
                headerName: 'Foreman',
                field: 'foreman',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'startTime',
                headerName: 'Start Time',
                field: 'startTime',
                sortable: false,
                flex: 1,
            },
            {
                colId: 'Invoice.InvoiceNumber',
                headerName: 'Invoice #',
                field: 'invoiceNumber',
                sortable: true,
                flex: 2,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'BillingStatusId',
                headerName: 'Status',
                field: 'billingStatus',
                sortable: true,
                flex: 1,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: billingStatusFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: billingStatusFilterParams,
            },
            {
                colId: 'HasOverride',
                headerName: 'Billing Override',
                field: 'hasOverride',
                sortable: true,
                flex: 1.5,
                tooltipField: 'status',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: yesNoFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: yesNoFilterParams,
            },
            {
                colId: 'Id',
                flex: 0,
                maxWidth: 75,
                headerName: 'Billing Issue',
                sortable: false,
                cellRenderer: (params) => {
                    if (params.data) {
                        return (
                            <SmallButton
                                title="Reject"
                                type="button"
                                onClick={() => {
                                    this.onBillingIssuesSlideout(
                                        params.data.timesheetId,
                                    );
                                }}
                            >
                                <i className="fa fa-comments fa-md mr-2" />
                            </SmallButton>
                        );
                    }

                    return null;
                },
            },
            {
                colId: 'Id',
                flex: 0,
                maxWidth: 75,
                headerName: 'Hold',
                sortable: false,
                cellRenderer: (params) => {
                    if (
                        params.data
                        && params.data.billingStatusId
                        !== BillingStatus.IssueReject
                        && params.data.billingStatusId
                        !== BillingStatus.PendingReview
                    ) {
                        if (params.data.billingStatusId === 8) {
                            return (
                                <SmallButton
                                    title="Hold"
                                    type="button"
                                    onClick={() => {
                                        this.removeOnHold(params.data.id);
                                    }}
                                >
                                    <i className="fa fa-unlock fa-md mr-2" />
                                </SmallButton>
                            );
                        }
                        return (
                            <SmallButton
                                title="Hold"
                                type="button"
                                onClick={() => {
                                    this.setOnHold(params.data.id);
                                }}
                            >
                                <i className="fa fa-lock fa-md mr-2" />
                            </SmallButton>
                        );
                    }

                    return null;
                },
            },
            {
                colId: 'Id',
                flex: 0,
                maxWidth: 75,
                headerName: 'View',
                sortable: false,
                cellRenderer: 'iconRenderer',
                cellRendererParams: {
                    clicked: this.openTimesheetDetails,
                    idField: 'timesheetId',
                    iconClass: 'fa-eye',
                },
            },
            {
                colId: 'Id',
                flex: 0,
                maxWidth: 75,
                headerName: 'Print',
                sortable: false,
                cellRenderer: 'iconRenderer',
                cellRendererParams: {
                    clicked: this.printTimesheet,
                    idField: 'timesheetId',
                    iconClass: 'fa-print',
                },
            },
            {
                colId: 'SelectionPlaceholder',
                headerName: '',
                field: 'selectionPlaceholder',
                sortable: false,
                maxWidth: 75,
                checkboxSelection(params) {
                    if (params.data) {
                        return params.data.billingStatus === 'Ready To Invoice';
                    }

                    return null;
                },
            },
        ];

        if (this.context?.user?.isAdmin) {
            const overrideIndex = gridOptions.columnDefs.findIndex((c) => c.colId === 'HasOverride');
            const col = {
                colId: 'invoiceEstimateAmount',
                headerName: 'Invoice Est.',
                field: 'invoiceEstimateAmount',
                sortable: false,
                flex: 1,
            };
            if (overrideIndex) {
                gridOptions.columnDefs.splice(overrideIndex + 1, 0, col);
            } else {
                gridOptions.columnDefs.push(col);
            }
        }

        gridOptions.onFilterChanged = (params) => {
            const filterModel = gridOptions.api.getFilterModel();
            context.setState({ filterModel });
        };

        gridOptions.onSortChanged = (params) => { };

        gridOptions.onFirstDataRendered = (params) => { };

        gridOptions.postProcessData = (data) => {
            context.setState({
                rowData: data,
                scoreboardData: { ...data.counts } ?? null,
            });

            return { ...data.result };
        };

        gridOptions.onGridReady = (params) => {
            params.api.setDatasource(context.state.dataSource);
            context.setState({ gridApi: params.api });
        };

        const dataSource = createDataSource(
            ApiRoutes.invoiceGeneration.search(),
            gridOptions,
        );

        this.setState({
            loading: false,
            gridOptions,
            dataSource,
            isRejectingTimesheet: false,
            canMoveTimesheetContract,
        });
    };

    printTimesheet = async (id) => {
        if (id) {
            window.open(ApiRoutes.report.timesheetBilling(id), '_self');
        }
    };

    rejectBillingTimesheet = async () => {
        const { rowsSelected, selectedRowRejectionNotes } = this.state;

        if (
            selectedRowRejectionNotes.length === 0
            || !selectedRowRejectionNotes.trim()
        ) return;

        this.setState({ isRejectingBillingTimesheet: true });

        // call reject
        if (rowsSelected) {
            const model = {
                billingIds: rowsSelected.map((x) => x.id),
                note: selectedRowRejectionNotes,
            };

            const endPoint = ApiRoutes.invoiceGeneration.billingReject();
            const response = await util.fetch
                .post(endPoint, model)
                .catch(this.handleSaveError);

            if (response) {
                toasty.success(
                    'All charges for the selected timesheets will be rejected to Billing: Charges',
                );
            }
        }

        this.state.gridOptions.refresh();

        this.setState({
            selectedRow: null,
            showRejectBillingTimesheetModal: false,
            selectedRowRejectionNotes: '',
            isRejectingBillingTimesheet: false,
        });
    };

    rejectTimesheet = async () => {
        const { rowsSelected, selectedRowRejectionNotes } = this.state;

        if (
            selectedRowRejectionNotes.length === 0
            || !selectedRowRejectionNotes.trim()
        ) return;

        if (!this.context?.user?.isAdmin) {
            toasty.info('Only admins are allowed to reject timesheets');
            return;
        }

        this.setState({ isRejectingTimesheet: true });

        // call reject
        if (rowsSelected) {
            const model = {
                billingId: rowsSelected[0].id,
                note: selectedRowRejectionNotes,
            };

            const endPoint = ApiRoutes.invoiceGeneration.adminReject();
            const response = await util.fetch
                .post(endPoint, model)
                .catch(this.handleSaveError);

            if (response) {
                toasty.success('Billing: Timesheet Rejected');
            }
        }

        this.state.gridOptions.refresh();

        this.setState({
            selectedRow: null,
            showRejectTimesheetModal: false,
            selectedRowRejectionNotes: '',
            isRejectingTimesheet: false,
            adminRejecting: false,
        });
    };

    removeOnHold = async (id) => {
        if (id) {
            const response = await util.fetch
                .post(ApiRoutes.invoiceGeneration.removeOnHold(), id)
                .catch(this.handleSaveError);

            if (response) {
                toasty.error(`Error setting removing on hold: ${response}`);
            } else {
                toasty.success('Hold removed');
            }

            this.state.gridOptions.refresh();
        }
    };

    async toggleExportModal() {
        const { showExportModal } = this.state;
        this.setState({ showExportModal: !showExportModal });
    }

    toggleMovingTimesheetModal = async () => {
        const { rowsSelected, showMoveTimesheetModal } = this.state;
        let contracts = [];
        let allTimesheetsByJob = [];

        if (!showMoveTimesheetModal) {
            if (rowsSelected) {
                const jobNums = rowsSelected.map((e) => {
                    const p = e.timesheetNumber.split('-');
                    return `${p[0]}-${p[1]}`;
                });

                const distinctjobs = [...new Set(jobNums)];

                if (distinctjobs.length !== 1) {
                    // throw error
                    toasty.error(
                        'Selected timesheets are not from the same job.',
                    );
                    return;
                }

                // Get contracts based on company
                contracts = await util.fetch.js(
                    ApiRoutes.typeAheads.contractsByCompany(
                        rowsSelected[0].customerId,
                    ),
                );

                contracts = contracts.map((x) => ({
                    label:
                            x.number
                            + (x.operationsCenterName != null
                                ? ` (${x.operationsCenterName})`
                                : ''),
                    value: x.id,
                }));

                // Get all timesheets belong to jobs involved and display them
                const timesheetIds = rowsSelected.map((x) => x.timesheetId);

                const timesheetList = timesheetIds.join(',');
                allTimesheetsByJob = await util.fetch.js(
                    ApiRoutes.timesheetManagement.timesheetsBelongingToJobs(
                        timesheetList,
                    ),
                );
            }
        }

        await this.setState({
            showMoveTimesheetModal: !showMoveTimesheetModal,
            contracts,
            allTimesheetsByJob,
            selectedContract: 0,
        });
    };

    toggleRejectBillingTimesheetModal = async () => {
        const { showRejectBillingTimesheetModal } = this.state;

        this.setState({
            showRejectBillingTimesheetModal: !showRejectBillingTimesheetModal,
        });
    };

    toggleRejectTimesheetModal = async () => {
        const { showRejectTimesheetModal } = this.state;

        if (!this.context?.user?.isAdmin) {
            toasty.info('Only admins are allowed to reject timesheets');
            return;
        }

        this.setState({
            showRejectTimesheetModal: !showRejectTimesheetModal,
            adminRejecting: true,
        });
    };

    renderTimesheetByjobList = () => {
        const { allTimesheetsByJob } = this.state;

        const groupContainsInvoice = allTimesheetsByJob.filter((e) => e.invoiceStatusId > 0).length > 0;
        const groupMixSelected = allTimesheetsByJob.filter((e) => e.convert == false).length > 0;

        return allTimesheetsByJob.map((timesheet, timesheetIndex) => {
            const tsHasInvoice = timesheet.invoiceStatusId > 0;

            return (
                <tr key={allTimesheetsByJob}>
                    <td>
                        {tsHasInvoice && (
                            <FormCheckbox
                                id={timesheet.id}
                                checked={false}
                                labelText={timesheet.timesheetNumber}
                                disabled
                            />
                        )}
                        {!tsHasInvoice && (
                            <FormCheckbox
                                id={timesheet.id}
                                checked={timesheet.convert}
                                onChange={(e) => {
                                    const { allTimesheetsByJob } = this.state;
                                    timesheet.convert = e.target.checked;
                                    this.setState({
                                        allTimesheetsByJob,
                                    });
                                }}
                                labelText={timesheet.timesheetNumber}
                            />
                        )}
                    </td>
                    <td>
                        {timesheet.convert && (
                            <>
                                {!tsHasInvoice
                                    && (groupContainsInvoice
                                        || groupMixSelected) && (
                                    <span>
                                        <ul
                                            style={{
                                                paddingLeft: '18px',
                                                marginBottom: '0px',
                                            }}
                                        >
                                            <li>New job</li>
                                            <li>New timesheet number</li>
                                        </ul>
                                    </span>
                                )}
                                {!tsHasInvoice
                                    && !groupContainsInvoice
                                    && !groupMixSelected && (
                                    <span>
                                        <ul
                                            style={{
                                                paddingLeft: '18px',
                                                marginBottom: '0px',
                                            }}
                                        >
                                            <li>Same job</li>
                                            <li>New timesheet number</li>
                                        </ul>
                                    </span>
                                )}
                            </>
                        )}
                        {!timesheet.convert && (
                            <>
                                {tsHasInvoice && <span>(unavailable)</span>}
                                {!tsHasInvoice && <span>(none)</span>}
                            </>
                        )}
                    </td>
                    <td>{timesheet.timesheetStatus}</td>
                    <td>{timesheet.billingStatus}</td>
                    <td>{timesheet.invoiceStatus}</td>
                </tr>
            );
        });
    };

    render() {
        const {
            rowData,
            gridOptions,
            filterModel,
            showRejectTimesheetModal,
            showRejectBillingTimesheetModal,
            showMoveTimesheetModal,
            isRejectingTimesheet,
            isRejectingBillingTimesheet,
            isMovingTimesheet,
            isInvoicing,
            selectedRowRejectionNotes,
            rowsSelected,
            isExporting,
            showExportModal,
            contracts,
            allTimesheetsByJob,
            selectedContract,
            selectedRow,
            billingIssueMessages,
            scoreboardData,
            canMoveTimesheetContract,
            invoicedTimesheetsSelected,
        } = this.state;

        let counts = {
            onHold: 0,
            pendingReview: 0,
            billingIssue: 0,
            invoiceEstimate: 0,
        };
        if (scoreboardData) {
            counts = scoreboardData;
        }

        const countOnHold = `mr-3 badge badge badge-lg ${
            counts.onHold === 0 ? 'badge-success p-2' : 'badge-warning p-2'}`;
        const countPendingReview = `mr-3 badge badge badge-lg ${
            counts.pendingReview === 0
                ? 'badge-success p-2'
                : 'badge-warning p-2'}`;
        const countBillingIssue = `mr-3 badge badge badge-lg ${
            counts.billingIssue === 0
                ? 'badge-success p-2'
                : 'badge-warning p-2'}`;
        const countInvoiceEstimate = `mr-3 badge badge badge-lg ${
            counts.invoiceEstimate === 0
                ? 'badge-success p-2'
                : 'badge-warning p-2'}`;

        let billingFilter = BillingStatus.ReadyToInvoice;
        if (filterModel != null) {
            billingFilter = filterModel?.BillingStatusId?.value != null
                ? filterModel.BillingStatusId.value
                : billingFilter;
        }

        return (
            <CommonContext.Consumer>
                {(value) => {
                    const { tenantSettings } = (value ?? {}).tenant ?? {};

                    if (this.state.loading || !tenantSettings) {
                        return (
                            <Progress />
                        );
                    }

                    if (
                        !tenantSettings.billingEnabled
                        || !tenantSettings.invoicingEnabled
                    ) return <NotAuthorizedPage />;

                    const isAdmin = this.context?.user?.isAdmin;

                    return (
                        <PageWrap>
                            <PageHeading>
                                <FontAwesomeIcon
                                    icon={faTasks}
                                    className="mr-2 text-muted"
                                />
                                <span>Invoice Generation</span>

                                {billingFilter != BillingStatus.IssueReject
                                    && billingFilter
                                    != BillingStatus.PendingReview
                                    && billingFilter
                                    != BillingStatus.Invoiced
                                    && (
                                        <span
                                            style={{
                                                float: 'right',
                                                position: 'relative',
                                                top: '-5px',
                                            }}
                                        >
                                            {/*    <Button */}
                                            {/*        size="sm" */}
                                            {/*        style={{ marginRight: "10px" }} */}
                                            {/*        color="danger" */}
                                            {/*        onClick={() => this.toggleExportModal()} */}
                                            {/*    > */}
                                            {/*        Export */}
                                            {/* </Button> */}
                                            {rowsSelected.length > 0 && !invoicedTimesheetsSelected && (
                                                <Button
                                                    size="sm"
                                                    color="success"
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                    onClick={() => this.onGenerateInvoice()}
                                                    disabled={
                                                        rowsSelected.length
                                                        === 0 || isInvoicing
                                                    }
                                                >
                                        Generate Invoice
                                                </Button>
                                            )}
                                            {rowsSelected.length > 0 && !invoicedTimesheetsSelected && (
                                                <Button
                                                    size="sm"
                                                    color="danger"
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                    onClick={() => this.toggleRejectBillingTimesheetModal()}
                                                    disabled={
                                                        rowsSelected.length
                                                        === 0
                                                        || isRejectingBillingTimesheet
                                                    }
                                                >
                                        Return To Billing Charges
                                                </Button>
                                            )}
                                            {isAdmin
                                                && rowsSelected.length == 1 && !invoicedTimesheetsSelected && (
                                                <Button
                                                    size="sm"
                                                    color="danger"
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                    onClick={() => this.toggleRejectBillingTimesheetModal()}
                                                    disabled={
                                                        rowsSelected.length
                                                            !== 1
                                                            || isRejectingTimesheet
                                                    }
                                                >
                                                  Return To Timesheet
                                                  Management
                                                </Button>
                                            )}
                                            {rowsSelected.length > 0 && !invoicedTimesheetsSelected && (
                                                <Button
                                                    size="sm"
                                                    color="info"
                                                    style={{
                                                        marginRight: '10px',
                                                    }}
                                                    onClick={() => this.onInvoiceChargeReport()}
                                                >
                                        Invoice Charge Report
                                                </Button>
                                            )}
                                            {canMoveTimesheetContract
                                                && rowsSelected.length > 0 && !invoicedTimesheetsSelected && (
                                                <Button
                                                    size="sm"
                                                    color="warning"
                                                    onClick={() => this.toggleMovingTimesheetModal()}
                                                    disabled={
                                                        rowsSelected.length
                                                            == 0
                                                            || isMovingTimesheet
                                                    }
                                                >
                                                  Move Timesheet OPS
                                                  Center
                                                </Button>
                                            )}
                                        </span>
                                    )}
                            </PageHeading>

                            <FlexCenterRow
                                className="pb-3 pt-2"
                                id="invoiceGenerationWorkflowStatus"
                            >
                                <span
                                    style={{ minWidth: '100px' }}
                                    className={countOnHold}
                                >
                  On Hold
                                    <span className="ml-2">
                                        {counts.onHold}
                                    </span>
                                </span>
                                <span
                                    style={{ minWidth: '100px' }}
                                    className={countBillingIssue}
                                >
                  Billing Issue
                                    <span className="ml-2">
                                        {counts.billingIssue}
                                    </span>
                                </span>
                                <span
                                    style={{ minWidth: '100px' }}
                                    className={countPendingReview}
                                >
                  Pending Review
                                    <span className="ml-2">
                                        {counts.pendingReview}
                                    </span>
                                </span>
                                {isAdmin && (
                                    <>
                                        <span
                                            style={{ minWidth: '100px' }}
                                            className={countInvoiceEstimate}
                                        >
                            Invoices Estimate
                                            <span className="ml-2">
                                                ${counts.invoiceEstimate.toFixed(2)}
                                            </span>
                                        </span>
                                    </>
                                )}
                            </FlexCenterRow>

                            <DataGridToolbar
                                entity="Billing"
                                gridApi={this.state.gridApi}
                                dataSource={this.state.dataSource}
                                hideAdd
                                hideExcelButton
                                gridOptions={this.state.gridOptions}
                                serverExport={{
                                    apiPath:
                                        ApiRoutes.invoiceGeneration.excelExport(),
                                    filePrefix: 'BillingSearch',
                                }}
                                serverExportDisabled={
                                    !!this.state.saving
                                    || !!this.state.loading
                                    || !!this.state.loadingData
                                }
                            />
                            {!!this.state.loadingReport && (
                                <CustomCircularProgress />
                            )}

                            {!this.state.loadingReport && (
                                <DataGrid
                                    domLayout="normal"
                                    rowData={rowData}
                                    gridOptions={gridOptions}
                                    gridStatus={this.state.gridStatus}
                                />
                            )}
                            <TimesheetDetails
                                ref={this.timesheetDetailsRef}
                                show={this.state.timesheetDetailsOpen}
                                toggleShow={(open) => this.setState({
                                    timesheetDetailsOpen: open,
                                })}
                                onClose={this.onTimesheetDetailsClosed}
                                onAccept={this.onAcceptTimesheet}
                                onReject={this.onRejectTimesheet}
                                onlyBillable
                                isBilling
                            />
                            <InvoiceSlideout
                                ref={this.invoiceSlideoutRef}
                                show={this.state.invoiceSlideoutOpen}
                                toggleShow={(open) => this.setState({ invoiceSlideoutOpen: open })}
                                onClose={this.onInvoiceSlideoutClosed}
                            />
                            <Modal
                                isOpen={showRejectTimesheetModal}
                                toggle={this.toggleRejectTimesheetModal}
                            >
                                <ModalHeader
                                    toggle={this.toggleRejectTimesheetModal}
                                >
                  Billing: Reject Timesheet
                                </ModalHeader>
                                <ModalBody>
                                    <div
                                        style={{ marginBottom: '20px' }}
                                        className="form-text text-danger"
                                    >
                    This administrative action will delete
                    any approved payroll or billing records,
                    and reset the timesheet. Please take
                    note of Payroll and Billing
                    implications.
                                    </div>

                                    <FormGroup>
                                        <FormLabel
                                            required
                                            text="Notes"
                                        />
                                        <textarea
                                            id="selectedRowRejectionNotes"
                                            name="selectedRowRejectionNotes"
                                            className="form-control"
                                            defaultValue={
                                                selectedRowRejectionNotes ?? ''
                                            }
                                            onChange={this.onChange}
                                            required
                                            placeholder="Enter notes regarding the rejection."
                                            type="text"
                                            maxLength="500"
                                            rows="5"
                                        />
                                        <small className="text-danger">
                      Notes are required.
                                        </small>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isRejectingTimesheet && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isRejectingTimesheet}
                                        onClick={this.rejectTimesheet}
                                    >
                    Ok
                                    </Button>
                                    {' '}
                                </ModalFooter>
                            </Modal>
                            <Modal
                                isOpen={showRejectBillingTimesheetModal}
                                toggle={this.toggleRejectBillingTimesheetModal}
                            >
                                <ModalHeader
                                    toggle={
                                        this.toggleRejectBillingTimesheetModal
                                    }
                                >
                  Reject Timesheet To Billing: Charges
                                </ModalHeader>
                                <ModalBody>
                                    <div
                                        style={{ marginBottom: '20px' }}
                                        className="form-text text-danger"
                                    >
                    This action will reset all charges to
                    associated with these timesheets back to
                    Billing: Charges.
                                    </div>

                                    <FormGroup>
                                        <FormLabel
                                            required
                                            text="Notes"
                                        />
                                        <textarea
                                            id="selectedRowRejectionNotes"
                                            name="selectedRowRejectionNotes"
                                            className="form-control"
                                            defaultValue={
                                                selectedRowRejectionNotes ?? ''
                                            }
                                            onChange={this.onChange}
                                            required
                                            placeholder="Enter notes regarding the rejection."
                                            type="text"
                                            maxLength="500"
                                            rows="5"
                                        />
                                        <small className="text-danger">
                      Notes are required.
                                        </small>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isRejectingTimesheet && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isRejectingBillingTimesheet}
                                        onClick={this.rejectBillingTimesheet}
                                    >
                    Ok
                                    </Button>
                                    {' '}
                                </ModalFooter>
                            </Modal>
                            <Modal
                                isOpen={showExportModal}
                                toggle={() => this.toggleExportModal()}
                            >
                                <ModalHeader
                                    toggle={() => this.toggleExportModal()}
                                >
                  Export Billing
                                </ModalHeader>
                                <ModalBody>
                                    <p>
                    Export will be generated based upon
                    search criteria entered on the billing
                    screen. If the result set is too large
                    the export may timeout.
                                    </p>
                                    {/* <FormGroup> */}
                                    {/*    <label>Dispatching</label> */}
                                    {/*    <Select */}
                                    {/*        placeholder={'Select Dispatch Location'} */}
                                    {/*        id="selectedDispatchLocation" */}
                                    {/*        name="selectedDispatchLocation" */}
                                    {/*        styles={CompactSelectStyles} */}
                                    {/*        isClearable={false} */}
                                    {/*        className="react-select" */}
                                    {/*        options={dispatchLocations} */}
                                    {/*        value={(dispatchLocations ?? []).find(x => x.value === selectedDispatchLocation) ?? ''} */}
                                    {/*        onChange={this.onSelectedDispatchLocationChanged} */}
                                    {/*    /> */}
                                    {/* </FormGroup> */}
                                    {/* <FormGroup> */}
                                    {/*    <label>Week Of</label> */}
                                    {/*    <input */}
                                    {/*        id="weekOf" */}
                                    {/*        name="selectedWeekOf" */}
                                    {/*        className="form-control" */}
                                    {/*        defaultValue={selectedWeekOf ?? ''} */}
                                    {/*        onChange={this.onChange} */}
                                    {/*        type="date" */}
                                    {/*    /> */}
                                    {/* </FormGroup> */}
                                </ModalBody>
                                <ModalFooter>
                                    {isExporting && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={isExporting}
                                        onClick={() => this.onExportClick()}
                                    >
                    Ok
                                    </Button>
                                    {' '}
                                    <Button
                                        color="secondary"
                                        onClick={() => this.setState({
                                            showExportModal: false,
                                        })}
                                    >
                    Cancel
                                    </Button>
                                </ModalFooter>
                            </Modal>
                            <Modal
                                size="lg"
                                isOpen={showMoveTimesheetModal}
                                toggle={this.toggleMovingTimesheetModal}
                            >
                                <ModalHeader
                                    toggle={this.toggleMovingTimesheetModal}
                                >
                  Move Timesheets to Contract / OPS Center
                                </ModalHeader>
                                <ModalBody>
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="timesheetJobsTable"
                                            text="Job Timesheets" 
                                        />

                                        <SimpleTable
                                            className="mb-0"
                                            id="timesheetJobsTable"
                                            noTopBorder
                                            permission="contract"
                                            tableHeaderLabels={[
                                                {
                                                    name: 'Timesheet Number',
                                                },
                                                {
                                                    name: 'Action(s)',
                                                },
                                                {
                                                    name: 'Timesheet Status',
                                                },
                                                {
                                                    name: 'Billing Status ',
                                                },
                                                {
                                                    name: 'Invoice Status ',
                                                },
                                            ]}
                                            editable={false}
                                            entities={allTimesheetsByJob}
                                            rowRenderer={
                                                this.renderTimesheetByjobList
                                            }
                                            noDataText="No timesheets found."
                                        />
                                    </FormGroup>

                                    <FormGroup style={{ marginTop: '15px' }}>
                                        <FormLabel
                                            htmlFor="selectedContract"
                                            text="Contract / OPS Center"
                                        />
                                        <Select
                                            placeholder="Select Contract"
                                            id="selectedContract"
                                            name="selectedContract"
                                            styles={CompactSelectStyles}
                                            isClearable={false}
                                            className="react-select"
                                            options={contracts}
                                            value={
                                                (contracts ?? []).find(
                                                    (x) => x.value
                                                        === selectedContract,
                                                ) ?? ''
                                            }
                                            onChange={(evt) => {
                                                const { value } = evt;
                                                this.setState({
                                                    selectedContract: value,
                                                });
                                            }}
                                        />
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    {isMovingTimesheet && (
                                        <FontAwesomeIcon
                                            icon={faCircleNotch}
                                            className="fa-spin mr-2"
                                            size="sm"
                                        />
                                    )}
                                    <Button
                                        color="primary"
                                        disabled={
                                            selectedContract == null
                                            || selectedContract == 0
                                            || isMovingTimesheet
                                            || allTimesheetsByJob.filter(
                                                (x) => x.convert == true,
                                            ).length == 0
                                        }
                                        onClick={this.moveTimesheets}
                                    >
                    Ok
                                    </Button>
                                    {' '}
                                </ModalFooter>
                            </Modal>

                            <SlideForm
                                loading={false}
                                show={this.state.showBillingIssuesSlideout}
                                id="billingIssuesSlideout"
                                formIcon={faComments}
                                formTitle="Billing Issues"
                                onClose={() => {
                                    this.setState({
                                        showBillingIssuesSlideout: false,
                                    });
                                }}
                            >
                                <BillingIssuesWidget
                                    ref={this.billingIssuesSlideoutRef}
                                    billing={selectedRow}
                                    messages={billingIssueMessages}
                                    context="rejecting"
                                    onRejectCallback={(id, message, updateStatus) => {
                                        this.onRejectToBillingIssues(
                                            id,
                                            message,
                                            updateStatus,
                                        );
                                    }}
                                    onAcceptCallback={(id, message) => {
                                        this.onAcceptBillingIssueReply(
                                            id,
                                            message,
                                        );
                                    }}
                                    onCloseCallback={(id, message) => {
                                        this.setState({
                                            showBillingIssuesSlideout: false,
                                        });
                                    }}
                                />
                            </SlideForm>
                        </PageWrap>
                    );
                }}
            </CommonContext.Consumer>
        );
    }
}

export default withRouter(InvoiceGenerationIndex);

import { useState, useEffect } from 'react';

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)'); // Define your breakpoint
        const updateIsMobile = () => setIsMobile(mediaQuery.matches);

        updateIsMobile(); // Set initial state
        mediaQuery.addEventListener('change', updateIsMobile); // Listen for changes

        return () => mediaQuery.removeEventListener('change', updateIsMobile); // Cleanup
    }, []);

    return isMobile;
};

export const Hide = ({ children, mdUp = false, smDown = false }) => {
    const isMobile = useIsMobile();

    if (
        (smDown && isMobile)
        || (mdUp && !isMobile)
    ) {
        return children;
    }

    return null;
};

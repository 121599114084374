import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CommonContext from '../../Common';

export const HistoryWatcher = () => {
    const location = useLocation();
    const context = useContext(CommonContext);
    
    useEffect(() => {
        if (location) {
            context.setFormOpened(false);
        }
    }, [location, context]);

    return null;
};
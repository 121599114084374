import React from 'react';
import { faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Input } from 'reactstrap';
import { BaseFormViewModel } from '../common/ViewModel';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import {
    FormLabel,
    onFieldChange,
    onReactSelectChanged,
    toasty,
    ValidationErrorMessage,
} from '../common/forms/FormElements';
import { EquipmentCompliance } from './EquipmentCompliance';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { isComplianceAssociatedWithEquipmentType } from '../complianceType/ComplianceType';

export default class EquipmentComplianceForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const { allKnownEquipment, allEquipmentComplianceTypes } = this.props;

        const stateBase = {
            equipmentCompliance:
                    this.props.equipmentCompliance ?? new EquipmentCompliance(),
            allKnownEquipment,
            allEquipmentComplianceTypes,
            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
        this.onDateChanged = this.onDateChanged.bind(this);
        this.isNew = this.isNew.bind(this);
    }

    componentDidMount() {
        return this.populateState(); 
    }

    onChange = onFieldChange;

    onClose = () => {
        this.resetForm();
        this.props.toggleShow(false);
        this.context.setFormOpened(false);
        this.props.onClose();
    };

    onDateChanged = (event) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;

        const { equipmentCompliance } = this.state;

        if (fieldName.includes('effectiveDate')) {
            equipmentCompliance.effectiveDate = fieldValue;

            if (equipmentCompliance.expirationDate) {
                const effectiveDate = new Date(fieldValue);
                const expirationDate = new Date(
                    equipmentCompliance.expirationDate,
                );

                if (effectiveDate.getTime() >= expirationDate.getTime()) {
                    toasty.info('Expiration date cleared');
                    equipmentCompliance.expirationDate = null;
                }
            }
        } else {
            equipmentCompliance.expirationDate = fieldValue;

            if (equipmentCompliance.expirationDate) {
                const effectiveDate = new Date(
                    equipmentCompliance.effectiveDate,
                );
                const expirationDate = new Date(fieldValue);

                if (effectiveDate.getTime() >= expirationDate.getTime()) {
                    toasty.info('Expiration date cleared');
                    equipmentCompliance.expirationDate = null;
                }
            }
        }

        this.setState({ equipmentCompliance });
    };

    onDelete = async (e) => {
        const { equipmentCompliance } = this.state;
        const response = await util.fetch.delete(
            ApiRoutes.equipmentCompliances.delete(equipmentCompliance.id),
        );
        if (response) {
            this.onClose();
        }
    };

    onSelectChange = onReactSelectChanged;

    onSelectedComplianceTypeChanged = async (selectedItem) => {
        const { equipmentCompliance } = this.state;

        equipmentCompliance.complianceTypeId = selectedItem.id;

        this.setState({ equipmentCompliance });
    };

    onSelectedEquipmentChanged = async (selectedItem) => {
        const { equipmentCompliance } = this.state;

        if (!selectedItem) {
            equipmentCompliance.equipmentId = 0;

            this.setState({
                equipmentCompliance,
            });

            return;
        }

        const equipmentId = selectedItem.id;
        equipmentCompliance.equipmentId = equipmentId;

        this.setState({
            equipmentCompliance,
        });
    };

    onSubmit = async (e) => {
        const { equipmentCompliance } = this.state;

        this.setState((state) => ({ errors: {}, saving: true }));

        // Is this POST or PUT?
        const url = this.isNew()
            ? ApiRoutes.equipmentCompliances.create()
            : ApiRoutes.equipmentCompliances.update(equipmentCompliance.id);
        const fetchCte = this.isNew() ? util.fetch.post : util.fetch.put;

        const response = await fetchCte(url, equipmentCompliance).catch(
            this.handleSaveError,
        );

        if (response) {
            this.onClose();
            toasty.success(
                'Equipment Compliance Saved',
                'Equipment Compliance saved successfully.',
            );
        }
    };

    getApplicableEquipmentComplianceTypes = (
        allEquipmentComplianceTypes,
        equipment,
    ) => {
        if (!allEquipmentComplianceTypes || !equipment) {
            return [];
        }

        const { equipmentTypeId } = equipment;

        return allEquipmentComplianceTypes.filter(
            (ect) => isComplianceAssociatedWithEquipmentType(ect, equipmentTypeId),
        );
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    isNew = () => {
        const { equipmentCompliance } = this.state;

        return Boolean(
            !equipmentCompliance
                || !equipmentCompliance.id
                || equipmentCompliance.id <= 0,
        );
    };

    open = async () => {
        await this.setState({ loading: true });

        await this.populateState();

        const equipmentCompliance = new EquipmentCompliance();

        this.resetForm();

        this.props.toggleShow(true);
        this.context.setFormOpened(true);

        await this.setState({
            equipmentCompliance,
            loading: false,
        });
    };

    openEquipment = async (equipmentId) => {
        await this.setState({ loading: true });

        await this.populateState();

        const { allKnownEquipment } = this.state;

        const equipment = allKnownEquipment.find((e) => e.id == equipmentId);

        const equipmentCompliance = new EquipmentCompliance();

        if (equipment) {
            equipmentCompliance.equipmentId = equipment.id;
        }

        await this.setState({
            equipmentCompliance,
            loading: false,
        });

        this.props.toggleShow(true);
        this.context.setFormOpened(true);
    };

    openEquipmentCompliance = async (equipmentComplianceId) => {
        await this.setState({ loading: true });

        await this.populateState();

        let equipmentCompliance = null;

        this.resetForm();

        if (equipmentComplianceId) {
            equipmentCompliance = await util.fetch.js(
                ApiRoutes.equipmentCompliances.byId(equipmentComplianceId),
            );

            equipmentCompliance.effectiveDate = util.date.getInputFormat(
                equipmentCompliance.effectiveDate,
            );
            equipmentCompliance.expirationDate = util.date.getInputFormat(
                equipmentCompliance.expirationDate,
            );
        } else {
            equipmentCompliance = new EquipmentCompliance();
        }

        await this.setState({
            equipmentCompliance,
        });

        this.props.toggleShow(true);
        this.context.setFormOpened(true);

        await this.setState({ loading: false });
    };

    async populateState() {}

    resetForm = () => {
        this.setState({
            showEquipmentAssignment: false,
            formValidated: false,
        });
    };

    render() {
        const {
            allEquipmentComplianceTypes,
            formValidated,
            validationMessage,
            equipmentCompliance,
            allKnownEquipment,
        } = this.state;

        const equipment = allKnownEquipment.find(
            (e) => e.id == equipmentCompliance.equipmentId,
        );

        const equipmentComplianceTypes = this.getApplicableEquipmentComplianceTypes(
            allEquipmentComplianceTypes,
            equipment,
        );

        return (
            <SlideForm
                loading={this.state.loading}
                show={this.props.show}
                id="equipmentForm"
                formIcon={faToolbox}
                formTitle={
                    this.isNew()
                        ? 'Add Equipment Compliance'
                        : 'Edit Equipment Compliance'
                }
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value });
                }}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                onSave={this.onSave}
                onDelete={this.onDelete}
                errors={this.state.errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}
                entityId={equipmentCompliance.id}
            >
                <FormGroup>
                    <FormLabel
                        htmlFor="equipmentCompliance.equipmentId"
                        text="Equipment"
                        required
                    />
                    <ValidatedSelect
                        id="equipmentCompliance.equipmentId"
                        name="equipmentCompliance.equipmentId"
                        required
                        options={allKnownEquipment}
                        value={
                            allKnownEquipment.find(
                                (e) => e.id === equipmentCompliance.equipmentId,
                            ) ?? ''
                        }
                        getOptionLabel={(option) => option.displayName}
                        getOptionValue={(option) => option.id}
                        onChange={this.onSelectedEquipmentChanged}
                        validationMessage="Equipment is required."
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="equipmentCompliance.complianceTypeId"
                        text="Compliance Name"
                        required
                    />
                    <ValidatedSelect
                        id="equipmentCompliance.complianceTypeId"
                        name="equipmentCompliance.complianceTypeId"
                        required
                        options={equipmentComplianceTypes}
                        value={
                            equipmentComplianceTypes.find(
                                (eqc) => eqc.id
                                    === equipmentCompliance.complianceTypeId,
                            ) ?? ''
                        }
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        onChange={this.onSelectedComplianceTypeChanged}
                        validationMessage="The compliance type is required."
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="equipmentCompliance.effectiveDate"
                        text="Effective Date"
                        required
                    />
                    <Input
                        id="equipmentCompliance.effectiveDate"
                        name="equipmentCompliance.effectiveDate"
                        value={equipmentCompliance.effectiveDate}
                        onChange={this.onDateChanged}
                        placeholder="Effective Date"
                        type="date"
                        required
                    />
                    <ValidationErrorMessage>
            Effective Date is required.
                    </ValidationErrorMessage>
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="equipmentCompliance.expirationDate"
                        text="Expiration Date"
                        required={false}
                    />
                    <Input
                        id="equipmentCompliance.expirationDate"
                        name="equipmentCompliance.expirationDate"
                        value={equipmentCompliance.expirationDate ?? ''}
                        min={equipmentCompliance.effectiveDate}
                        onChange={this.onDateChanged}
                        placeholder="Expiration Date"
                        type="date"
                    />
                    <ValidationErrorMessage />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="equipmentCompliance.notes"
                        text="Notes"
                        required={false}
                    />
                    <Input
                        id="equipmentCompliance.notes"
                        name="equipmentCompliance.notes"
                        value={equipmentCompliance.notes ?? ''}
                        onChange={this.onChange}
                        placeholder="Notes"
                        type="textarea"
                        max={500}
                        maxLength={500}
                    />
                    <ValidationErrorMessage />
                </FormGroup>
            </SlideForm>
        );
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const Chip = ({ text, onRemove }) => (
    <div className="d-inline-flex align-items-center border rounded-pill px-2 py-1 bg-light">
        <span className="text-truncate mr-2">{text}</span>
        {Boolean(onRemove) && (
            <Button
                color="link"
                className="p-0 chip-close-btn"
                onClick={(e) => {
                    e.stopPropagation();
                    onRemove();
                }}
            >
                <FontAwesomeIcon icon={faTimes} />
            </Button>
        )}
    </div>
);

Chip.propTypes = {
    text: PropTypes.string.isRequired,
    onRemove: PropTypes.func,
};

Chip.defaultProps = {
    onRemove: null,
};

export default Chip;
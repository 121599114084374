import React, { Fragment } from 'react';
import {
    faCheckCircle,
    faExclamation,
    faPhone,
    faSave,
    faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import {
    Button, FormGroup, List, UncontrolledTooltip, Progress,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prompt, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash-es';
import {
    AppPageForm,
    FlexColumnCenter,
    FormCheckbox,
    SubHeading,
    ToastMessage,
    FormLabel, 
    onReactSelectChanged,
    toasty,
} from '../common/forms/FormElements';
import EmailRecipients from '../common/forms/EmailRecipients';
import { BaseFormViewModel } from '../common/ViewModel';
import CommonContext, {
    ApiRoutes,
    SupportedFileExtensions,
    LocalizationKeys as l,
} from '../Common';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { handleFormSaveError } from '../common/forms/ValidationError';
import NumericInput from '../common/forms/NumericInput';
import FileUpload from '../common/forms/FileUpload';
import { TenantSettings, CountyCaptureType } from './Tenant';
import { ValidatedEmailDistributionList, getAddressesAndValidity } from './ValidatedEmailDistributionList';

class TenantForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.asncnlRef = React.createRef();
        this.brandLogoUploadsRef = React.createRef();
        this.reportLogoUploadsRef = React.createRef();

        const stateBase = {
            daysOfWeek: [
                { id: -1, name: '<Select>' },
                { id: 0, name: 'Sunday' },
                { id: 1, name: 'Monday' },
                { id: 2, name: 'Tuesday' },
                { id: 3, name: 'Wednesday' },
                { id: 4, name: 'Thursday' },
                { id: 5, name: 'Friday' },
                { id: 6, name: 'Saturday' },
            ],
            employeeGroups: [],
            optionalCompanyTypes: [],
            countyCaptureTypes: [],
            useAsAppLogo: false,
            currentSubmittedTimesheetEmailList: null,
            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
        this.onSubmit = this.onSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.addLogoFormData = this.addLogoFormData.bind(this);
        this.getTimesheetResponsibilityTypeOptions = this.getTimesheetResponsibilityTypeOptions.bind(this);
    }

    componentDidMount() {
        this.populateState();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps
            && this.props.match.params.id !== (prevProps.match.params ?? {}).id
        ) {
            this.populateState();
        }
    }

    onClearErrors = () => this.setState((state) => ({ errors: {} }));

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    };

    onCompanyTypeChanged = (value) => {
        const { tenant } = { ...this.state };

        this.setState({ tenant });
    };

    onDelete = async (e) => {
        const { organization } = this.state;
        const response = await util.fetch
            .delete(ApiRoutes.company.delete(organization.id))
            .catch(this.onCatchFetchError);
        if (response) this.onClose(response);
    };

    onEmpOnboardingCheckedChanged = (event) => {
        const value = event.target.checked.toString();
        const { tenant } = { ...this.state };
        const { tenantSettings } = { ...tenant };

        for (const setting of tenantSettings) {
            if (setting.name === TenantSettings.EmployeeOnboardingEnabled) {
                setting.value = value;
            } else if (
                setting.name
                    === TenantSettings.EmployeeOnboardingDefaultGroup
                && value === 'false'
            ) {
                setting.value = null; // clear out emp group if disabling onboarding.
            }
        }

        tenant.tenantSettings = tenantSettings;
        this.setState({ tenant });
    };

    onSelectChange = onReactSelectChanged;

    onSettingChanged = (name, value) => {
        const { tenant } = this.state;

        const setting = tenant.tenantSettings.find((s) => s.name === name);

        if (setting) {
            setting.value = value;
            this.setState({ tenant });
        }
    };

    onSubmit = async (e) => {
        const expiryListErrors = this.findContractExpiryListErrors();

        if (expiryListErrors) {
            toast.error(
                <ToastMessage
                    icon={faExclamation}
                    header="Contract Expiry Distribution List Invalid"
                    message={expiryListErrors}
                />,
            );

            return;
        }

        const staffingReportListErrors = this.findStaffingReportDistributionListErrors();

        if (staffingReportListErrors) {
            toast.error(
                <ToastMessage
                    icon={faExclamation}
                    header="Staffing Report Distribution List Invalid"
                    message={staffingReportListErrors}
                />,
            );

            return;
        }

        const newCustomerBillableHoursListErrors = this.findNewCustomerBillableListErrors();

        if (newCustomerBillableHoursListErrors) {
            toast.error(
                <ToastMessage
                    icon={faExclamation}
                    header="New Customer Billable Hours Report Distribution List Invalid"
                    message={newCustomerBillableHoursListErrors}
                />,
            );

            return;
        }

        // Clear any fluent api errors
        this.onClearErrors();
        this.setSaving(true);

        const { tenant } = this.state;

        const response = await util.fetch
            .put(ApiRoutes.tenant.byId(tenant.id), tenant)
            .catch(this.handleSaveError);

        if (response && parseInt(response.id ?? 0) >= 0) {
            this.setState({
                tenant: response,
                originalData: response,
                saving: false,
                formValidated: false,
            });

            toast.success(
                <ToastMessage
                    icon={faCheckCircle}
                    header="Save Successful"
                    message={`Tenant settings for [${response.companyName}] saved.`}
                />,
            );
        }

        this.setSaving(false);
    };

    getTenantSetting = (settingName) => {
        const { tenant } = { ...this.state };
        const { tenantSettings } = { ...tenant };

        const ret = { ...tenantSettings.find((x) => x.name === settingName) }
            .value;

        return ret;
    };

    getTimesheetResponsibilityTypeOptions(strings) {
    // Moved from Tenant.js since we're using localized strings now.
        const crewLead = strings[l.crewLead];

        const ret = [
            {
                id: 1,
                name: crewLead,
                description: `${crewLead}s are responsible for all timesheet entry.`,
            },
            {
                id: 2,
                name: 'Individual',
                description: 'Individual employees enter timesheets.',
            },
            {
                id: 3,
                name: 'Contract',
                description: `Timesheet responsibility is decided between individual/${crewLead} on a contract-by-contract basis.`,
            },
        ];

        return ret;
    }

    setSaving = (b) => this.setState({ saving: b });

    addLogoFormData(_file, formData) {
        const { useAsAppLogo } = this.state;
        formData.append('useAsAppLogo', !!useAsAppLogo);
    }

    onEmailAdded = (e) => {
        const { currentSubmittedTimesheetEmailList } = { ...this.state };
        if (currentSubmittedTimesheetEmailList) {
            const emailAddress = currentSubmittedTimesheetEmailList.trim();
            const match = util.validation.email(emailAddress);
            const value = this.getTenantSetting(TenantSettings.SubmittedTimesheetsReportEmailList);

            if (match) {
                let newValue = value?.length ? value.split(',') : [];
                if (!newValue.includes(emailAddress)) {
                    newValue.push(emailAddress);
                }
                this.onSettingChanged(TenantSettings.SubmittedTimesheetsReportEmailList, newValue.join(','));
                this.setState({
                    currentSubmittedTimesheetEmailList: null,
                });
            } else {
                toasty.error('Please enter a single valid email address.');
            }
        }
    };

    onEmailChanged = (e) => {
        const val = e.currentTarget.value;
        this.setState({ currentSubmittedTimesheetEmailList: val });
    };

    onEmailRemoved = (selected) => {
        const value = this.getTenantSetting(TenantSettings.SubmittedTimesheetsReportEmailList);
        const valueArr = value.split(',');
        const inx = valueArr.findIndex(
            (ea) => ea === selected,
        );
        valueArr.splice(inx, 1);
        this.onSettingChanged(TenantSettings.SubmittedTimesheetsReportEmailList, valueArr.join(','));
    };
    
    findContractExpiryListErrors = () => {
        if (
            (this.getTenantSetting(TenantSettings.EnableContractDateRange)
                ?? 'false') === 'false'
        ) {
            return null;
        }

        const contractExpiryDistributionList = this.getTenantSetting(
            TenantSettings.ContractExpiryDistributionList,
        );

        if (!contractExpiryDistributionList) {
            return 'Expiration distribution list must be set.';
        }

        const validatedEmailAddresses = getAddressesAndValidity(
            contractExpiryDistributionList,
        );

        if (validatedEmailAddresses.length == 0) {
            return 'One or more email addresses must be provided.';
        }

        if (validatedEmailAddresses.every((vea) => vea.isValid)) {
            return null;
        }

        const invalidAddresses = validatedEmailAddresses.filter(
            (vea) => !vea.isValid,
        );

        return `The following email address(es) are invalid: [${invalidAddresses
            .map((vea) => vea.emailAddress)
            .join(', ')}]`;
    };

    findNewCustomerBillableListErrors = () => {
        if (
            (this.getTenantSetting(
                TenantSettings.EnableNewCustomerBillableHoursReport,
            ) ?? 'false') === 'false'
        ) {
            return null;
        }

        const newCustomerBillableHoursReportDistributionList = this.getTenantSetting(
            TenantSettings.NewCustomerBillableHoursReportDistributionList,
        );

        if (!newCustomerBillableHoursReportDistributionList) {
            return 'New customer billable hours report distribution list must be set.';
        }

        const validatedEmailAddresses = getAddressesAndValidity(
            newCustomerBillableHoursReportDistributionList,
        );

        if (validatedEmailAddresses.length == 0) {
            return 'One or more email addresses must be provided.';
        }

        if (validatedEmailAddresses.every((vea) => vea.isValid)) {
            return null;
        }

        const invalidAddresses = validatedEmailAddresses.filter(
            (vea) => !vea.isValid,
        );

        return `The following email address(es) are invalid: [${invalidAddresses
            .map((vea) => vea.emailAddress)
            .join(', ')}]`;
    };

    findStaffingReportDistributionListErrors = () => {
        if (
            (this.getTenantSetting(TenantSettings.EnableStaffingReport)
                ?? 'false') === 'false'
        ) {
            return null;
        }

        const staffingReportDistributionList = this.getTenantSetting(
            TenantSettings.StaffingReportDistributionList,
        );

        if (!staffingReportDistributionList) {
            return 'Expiration distribution list must be set.';
        }

        const validatedEmailAddresses = getAddressesAndValidity(
            staffingReportDistributionList,
        );

        if (validatedEmailAddresses.length == 0) {
            return 'One or more email addresses must be provided.';
        }

        if (validatedEmailAddresses.every((vea) => vea.isValid)) {
            return null;
        }

        const invalidAddresses = validatedEmailAddresses.filter(
            (vea) => !vea.isValid,
        );

        return `The following email address(es) are invalid: [${invalidAddresses
            .map((vea) => vea.emailAddress)
            .join(', ')}]`;
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    async populateState() {
        const tenantId = this.props.match.params.id;
        let [
            strings,
            tenant,
            groups,
            optionalCompanyTypes,
            countyCaptureTypes,
        ] = await Promise.all([
            util.l10n.getStrings([l.crewLead]),
            util.fetch.js(ApiRoutes.tenant.byId(tenantId)),
            util.fetch.js(ApiRoutes.auth.groups()),
            util.fetch.js(ApiRoutes.tenant.optionalCompanyTypes()),
            util.fetch.js(ApiRoutes.tenant.countyCaptureTypes()),
        ]);

        groups = groups.filter((x) => x.groupName !== 'Administrator');

        const originalData = util.object.clone(tenant);

        this.setState((state) => ({
            originalData,
            tenant,
            loading: false,
            saving: false,
            employeeGroups: groups,
            optionalCompanyTypes,
            countyCaptureTypes,
            strings,
            timesheetResponsibilities:
                    this.getTimesheetResponsibilityTypeOptions(strings),
        }));
    }

    resetForm = () => this.setState({ formValidated: false });

    render() {
        const {
            loading,
            daysOfWeek,
            employeeGroups,
            tenant,
            originalData,
            errors,
            formValidated,
            saving,
            timesheetResponsibilities,
            optionalCompanyTypes,
            countyCaptureTypes,
            useAsAppLogo,
            currentSubmittedTimesheetEmailList
        } = this.state;

        if (
            !!loading
            || !this.context?.permissions
            || !tenant
            || !employeeGroups
        ) {
            return <Progress />;
        }
        const companyName = tenant.companyName || '';

        const empOnboardingEnabled = (this.getTenantSetting(
            TenantSettings.EmployeeOnboardingEnabled,
        ) ?? 'false') === 'true';
        const currentOnboardEmpGroup = (this.state.employeeGroups ?? []).find(
            (x) => x.id
                        === parseInt(
                            this.getTenantSetting(
                                TenantSettings.EmployeeOnboardingDefaultGroup,
                            ) ?? -1,
                        ),
        ) ?? '';
        const jobUploadsEnabled = this.getTenantSetting(
            TenantSettings.JobUploadsEnabled ?? 'false',
        ) === 'true';
        const timesheetResponsibility = parseInt(
            this.getTenantSetting(TenantSettings.TimesheetResponsibility)
                    ?? -1,
        );
        const payPeriodBeginDayOfWeek = (this.state.daysOfWeek ?? []).find(
            (x) => x.id
                        === parseInt(
                            this.getTenantSetting(
                                TenantSettings.PayPeriodStartDay,
                            ) ?? -1,
                        ),
        ) ?? '';
        const payPeriodEndDayOfWeek = (this.state.daysOfWeek ?? []).find(
            (x) => x.id
                        === parseInt(
                            this.getTenantSetting(
                                TenantSettings.PayPeriodEndDay,
                            ) ?? -1,
                        ),
        ) ?? '';
        const timesheetAdjustmentsEnabled = (this.getTenantSetting(
            TenantSettings.TimesheetAdjustmentsEnabled,
        ) ?? 'false') === 'true';
        const crewsEnabled = (this.getTenantSetting(TenantSettings.CrewsEnabled)
                    ?? 'false') === 'true';
        const hoursEnabled = (this.getTenantSetting(TenantSettings.DispatchHoursEnabled)
                    ?? 'false') === 'true';
        const defaultChargeEnabled = (this.getTenantSetting(TenantSettings.DefaultChargeEnabled)
                    ?? 'false') === 'true';
        const rollUpDriveTimeEnabled = (this.getTenantSetting(TenantSettings.RollUpDriveTime)
                    ?? 'false') === 'true';
        const attendanceEventsEnabled = (this.getTenantSetting(
            TenantSettings.AttendanceEventsEnabled,
        ) ?? 'false') === 'true';
        const assignmentCancelThresh = Number(
            this.getTenantSetting(
                TenantSettings.AssignmentCancellationThreshold,
            ) ?? null,
        ) ?? '';
        const assignmentCancelMin = Number(
            this.getTenantSetting(
                TenantSettings.AssignmentCancellationMinimumCharge,
            ) ?? null,
        ) ?? '';
        const timesheetEmergencyEnabled = (this.getTenantSetting(
            TenantSettings.TimesheetEmergencyEnabled,
        ) ?? 'false') === 'true';
        const timesheetEmergencyStart = this.getTenantSetting(
            TenantSettings.TimesheetEmergencyStart,
        );
        const timesheetEmergencyEnd = this.getTenantSetting(
            TenantSettings.TimesheetEmergencyEnd,
        );
        const billingEnabled = (this.getTenantSetting(TenantSettings.BillingEnabled)
                    ?? 'false') === 'true';
        const billingRatesEnabled = (this.getTenantSetting(TenantSettings.BillingRatesEnabled)
                    ?? 'false') === 'true';
        const payrollEnabled = (this.getTenantSetting(TenantSettings.PayrollEnabled)
                    ?? 'false') === 'true';
        const timesheetEmailEnabled = (this.getTenantSetting(TenantSettings.TimesheetEmailEnabled)
                    ?? 'false') === 'true';
        const timesheetDashboardEnabled = (this.getTenantSetting(
            TenantSettings.TimesheetDashboardEnabled,
        ) ?? 'false') === 'true';
        const timesheetExportEnabled = (this.getTenantSetting(TenantSettings.TimesheetExportEnabled)
                    ?? 'false') === 'true';
        const timesheetUseJobCity = (this.getTenantSetting(TenantSettings.TimesheetUseJobCity)
                    ?? 'false') === 'true';
        const timesheetEquipmentAuditEnabled = (this.getTenantSetting(TenantSettings.TimesheetEquipmentAuditEnabled)
                    ?? 'false') === 'true';
        const complianceEnabled = (this.getTenantSetting(TenantSettings.ComplianceEnabled)
                    ?? 'false') === 'true';
        const blockBillingCancelEnabled = (this.getTenantSetting(
            TenantSettings.BlockBillingCancelEnabled,
        ) ?? 'false') === 'true';
        const blockPayrollCancelEnabled = (this.getTenantSetting(
            TenantSettings.BlockPayrollCancelEnabled,
        ) ?? 'false') === 'true';
        const useStateMinimum = (this.getTenantSetting(TenantSettings.UseStateMinimum)
                    ?? 'false') === 'true';
        const enableAlternateBillTo = (this.getTenantSetting(TenantSettings.EnableAlternateBillTo)
                    ?? 'false') === 'true';
        const enablePredefinedTimesheetMissingSigReason = (this.getTenantSetting(
            TenantSettings.EnablePredefinedTimesheetMissingSigReason,
        ) ?? 'false') === 'true';
        const invoicingEnabled = (this.getTenantSetting(TenantSettings.InvoicingEnabled)
                    ?? 'false') === 'true';
        const chargeTypeGroupsEnabled = (this.getTenantSetting(
            TenantSettings.ChargeTypeGroupsEnabled,
        ) ?? 'false') === 'true';
        const allowCrossDispatching = (this.getTenantSetting(TenantSettings.AllowCrossDispatching)
                    ?? 'false') === 'true';
        const enableSelfDispatchTimesheets = (this.getTenantSetting(
            TenantSettings.EnableSelfDispatchTimesheets,
        ) ?? 'false') === 'true';
        const dispatchStatusFilterEnabled = (this.getTenantSetting(
            TenantSettings.DispatchStatusFilterEnabled,
        ) ?? 'false') === 'true';
        const contactScreenEnabled = (this.getTenantSetting(TenantSettings.ContactScreenEnabled)
                    ?? 'false') === 'true';
        const contactActivitiesEnabled = contactScreenEnabled
                && (this.getTenantSetting(
                    TenantSettings.ContactActivitiesEnabled,
                ) ?? 'false') === 'true';
        const employeeLongTermStatusManagementEnabled = (this.getTenantSetting(
            TenantSettings.EmployeeLongTermStatusManagementEnabled,
        ) ?? 'false') === 'true';
        const useLimitedApprovals = (this.getTenantSetting(TenantSettings.UseLimitedApprovals)
                    ?? 'false') === 'true';
        const captureCounty = parseInt(
            this.getTenantSetting(TenantSettings.CaptureCounty) ?? 1,
        );
        const enableContractDateRange = (this.getTenantSetting(
            TenantSettings.EnableContractDateRange,
        ) ?? 'false') === 'true';
        const allowTimesheetStatusRollbacks = (this.getTenantSetting(
            TenantSettings.AllowTimesheetStatusRollbacks,
        ) ?? 'false') === 'true';

        const showContractDescriptionInTimesheetView = (this.getTenantSetting(
            TenantSettings.ShowContractDescriptionInTimesheetView,
        ) ?? 'false') === 'true';

        const contractExpiryDistributionList = this.getTenantSetting(
            TenantSettings.ContractExpiryDistributionList,
        );

        const contractExpiryCompanyName = this.getTenantSetting(
            TenantSettings.ContractExpiryCompanyName,
        );

        const googleMapsApiKey = this.getTenantSetting(
            TenantSettings.GoogleMapsApiKey,
        );

        const includeSchedulingOnDailyJobExport = (this.getTenantSetting(
            TenantSettings.IncludeSchedulingOnDailyJobExport,
        ) ?? 'false') === 'true';

        const showLongTermStatusCountOnRoster = (this.getTenantSetting(
            TenantSettings.ShowLongTermStatusCountOnRoster,
        ) ?? 'false') === 'true';

        const allowDefaultChargeTypeValues = (this.getTenantSetting(
            TenantSettings.AllowDefaultChargeTypeValues,
        ) ?? 'false') === 'true';

        const enableRequestedPersonnel = (this.getTenantSetting(
            TenantSettings.EnableRequestedPersonnel,
        ) ?? 'false') === 'true';

        const enableDriverReportingInTimesheet = (this.getTenantSetting(
            TenantSettings.EnableDriverReportingInTimesheet,
        ) ?? 'false') === 'true';

        const enableAFADChargeTypeGrouping = (this.getTenantSetting(
            TenantSettings.EnableAFADChargeTypeGrouping,
        ) ?? 'false') === 'true';

        const enableJobOverrides = (this.getTenantSetting(
            TenantSettings.EnableJobOverrides,
        ) ?? 'false') === 'true';

        const supportedFiles = SupportedFileExtensions.image;

        const bannerMessage = this.getTenantSetting(
            TenantSettings.BannerMessage,
        );

        const bannerMessageExternallyVisible = (this.getTenantSetting(
            TenantSettings.BannerMessageExternallyVisible,
        ) ?? 'false') === 'true';

        const enableAutoEscalatingContractRates = (this.getTenantSetting(
            TenantSettings.EnableAutoEscalatingContractRates,
        ) ?? 'false') === 'true';

        const enablePrevailingWageContracts = (this.getTenantSetting(
            TenantSettings.EnablePrevailingWageContracts,
        ) ?? 'false') === 'true';

        const gLStringsEnabled = (this.getTenantSetting(TenantSettings.GLStringsEnabled)
                    ?? 'false') === 'true';

        const activeEmployeeListEnabled = (this.getTenantSetting(
            TenantSettings.ActiveEmployeeListEnabled,
        ) ?? 'false') === 'true';

        const enableNewCustomerBillableHoursReport = (this.getTenantSetting(
            TenantSettings.EnableNewCustomerBillableHoursReport,
        ) ?? 'false') === 'true';

        const newCustomerBillableHoursReportDistributionList = this.getTenantSetting(
            TenantSettings.NewCustomerBillableHoursReportDistributionList,
        );

        const enableStaffingReport = (this.getTenantSetting(TenantSettings.EnableStaffingReport)
                    ?? 'false') === 'true';

        const staffingReportDistributionList = this.getTenantSetting(
            TenantSettings.StaffingReportDistributionList,
        );
        const quirksEnabled = (this.getTenantSetting(TenantSettings.QuirksEnabled)
            ?? 'false') === 'true';

        const enforceContractUnionWork = (this.getTenantSetting(
            TenantSettings.EnforceContractUnionWork,
        ) ?? 'false') === 'true';

        const timesheetEmailReplyTo = this.getTenantSetting(
            TenantSettings.TimesheetEmailReplyTo
        );

        const timesheetEmailBcc = this.getTenantSetting(
            TenantSettings.TimesheetEmailBcc
        );

        const timesheetEmailFromName = this.getTenantSetting(
            TenantSettings.TimesheetEmailFromName
        );
        
        const enableDriverRideConfirmationFields = (this.getTenantSetting(
            TenantSettings.EnableDriverRideConfirmationFields,
        ) ?? 'false') === 'true';

        const contractsCanEnforceProjectManagers = (this.getTenantSetting(TenantSettings.ContractsCanEnforceProjectManagers)
            ?? 'false') === 'true';

        const submittedTimesheetEmailList = this.getTenantSetting(
            TenantSettings.SubmittedTimesheetsReportEmailList
        )?.split(',') ?? [];

        return (
            <>
                <Prompt
                    when={!saving && !isEqual(originalData, tenant)}
                    message="You have unsaved changes, are you sure you want to leave?"
                />
                <AppPageForm
                    formShown={this.context.formIsOpen}
                    formId="tenantForm"
                    formHeadingIcon={faWarehouse}
                    formHeading="Tenant Settings"
                    formName="tenantForm"
                    formRef={this.formRef}
                    onSubmit={this.onSubmit}
                    setIsValidated={(value) => {
                        this.setState({ formValidated: value });
                    }}
                    isValidated={formValidated}
                    saving={saving}
                    errors={errors}
                    onClearErrors={this.onClearErrors}
                    loading={this.state.loading}
                >
                    <FlexColumnCenter className="mb-3 pt-3 col-sm-12 offset-sm-0 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                        <SubHeading>Brand/App Logo</SubHeading>
                        {/* 2022-08-04 - M. Nicol - Logo dimensions set in /style/App.scss .navbar-brand-image
                             *  Also see NavMenu.js. */}

                        <List>
                            <li>
                                <strong>
                  Save button below does not apply to this
                  section.
                                </strong>
                            </li>
                            <li>
                You must reload or log out/back in to see
                new logo images.
                            </li>
                        </List>

                        <FormGroup>
                            <FileUpload
                                id="brandLogoUpoader"
                                ref={this.brandLogoUploadsRef}
                                accept={supportedFiles}
                                maxNameLength={150}
                                url={ApiRoutes.tenant.updateBrandLogo(
                                    tenant.id,
                                )}
                                onPreSend={this.addLogoFormData}
                            />

                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkLogoAsAppLogo"
                                checked={useAsAppLogo}
                                onChange={(e) => this.setState({
                                    useAsAppLogo: e.target.checked,
                                })}
                                labelText="Use as App Logo (login page logo)"
                            />
                        </FormGroup>

                        <SubHeading>Report Logo</SubHeading>
                        <FormGroup>
                            <List>
                                <li>
                                    <strong>
                    Save button below does not apply to
                    this section.
                                    </strong>
                                </li>
                                <li>
                  Image must be 50px tall. Width can be
                  variable to an extent.
                                </li>
                            </List>

                            <FileUpload
                                id="reportLogoUpoader"
                                ref={this.reportLogoUploadsRef}
                                accept={supportedFiles}
                                maxNameLength={150}
                                url={ApiRoutes.tenant.updateReportLogo(
                                    tenant.id,
                                )}
                            />
                        </FormGroup>

                        <SubHeading>Site</SubHeading>
                        <FormGroup>
                            <FormLabel
                                htmlFor="bannerMessage"
                                text="Banner Message"
                            />
                            <input
                                id="bannerMessage"
                                type="text"
                                className="form-control"
                                name="bannerMessage"
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.BannerMessage,
                                    event.target.value,
                                )}
                                value={bannerMessage}
                                placeholder="Optional Banner Message"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="bannerMessage"
                            >
                This message appears in the main nav banner at the top of the screen
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkBannerMessageExternallyVisible"
                                checked={bannerMessageExternallyVisible}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.BannerMessageExternallyVisible,
                                    event.target.checked.toString(),
                                )}
                                labelText="Show Banner Message To Unauthenticated Users"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkBannerMessageExternallyVisible"
                            >
                Causes the banner message to appear on the login page
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="QuirksEnabled"
                                checked={quirksEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.QuirksEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="This customer wants changes rolled out slowly"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="QuirksEnabled"
                            >
                                Disables certain features and enhancements for this customer in case they want these improvements to be rolled out slowly.
                            </UncontrolledTooltip>
                        </FormGroup>

                        <SubHeading>Employees</SubHeading>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkEmployeeOnboardingEnabled"
                                checked={empOnboardingEnabled}
                                onChange={
                                    this.onEmpOnboardingCheckedChanged
                                }
                                labelText="Enable Employee Onboarding"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkEmployeeOnboardingEnabled"
                            >
                Enables the "New Hire" button in the Employee listing page
                            </UncontrolledTooltip>
                        </FormGroup>

                        {!!empOnboardingEnabled && (
                            <FormGroup>
                                <FormLabel
                                    htmlFor="onboardingDefaultGroup"
                                    text="Default Employee Group"
                                    required={empOnboardingEnabled}
                                />
                                <ValidatedSelect
                                    id="onboardingDefaultGroup"
                                    name="onboardingDefaultGroup"
                                    required={empOnboardingEnabled}
                                    options={employeeGroups}
                                    value={currentOnboardEmpGroup}
                                    getOptionValue={(option) => option.id}
                                    getOptionLabel={(option) => option.description}
                                    onChange={(item) => this.onSettingChanged(
                                        TenantSettings.EmployeeOnboardingDefaultGroup,
                                        (item ?? {}).id ?? '',
                                    )}
                                    validationMessage="When enabling emp onboarding, a default employee group selection is required."
                                />

                                <UncontrolledTooltip
                                    placement="left"
                                    target="onboardingDefaultGroup"
                                >
                Default user group to place new hires in
                                </UncontrolledTooltip>
                            </FormGroup>
                        )}

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkactiveEmployeeListEnabled"
                                checked={activeEmployeeListEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.ActiveEmployeeListEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Active Employee List"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkactiveEmployeeListEnabled"
                            >
                Allows access to see employees who were active OR new hire within a searchable time period
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkEnableStaffingReport"
                                checked={enableStaffingReport}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.EnableStaffingReport,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Monthly Staffing Report"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkEnableStaffingReport"
                            >
                Sends a monthly report to the list of email addresses below. Report is generated as an excel sheet.
                            </UncontrolledTooltip>
                        </FormGroup>

                        {enableStaffingReport && (
                            <FormGroup>
                                <FormLabel
                                    htmlFor="staffingReportDistributionList"
                                    text="Staffing Report - Email Distribution List"
                                    helpMessage="Email addresses should be split by either a comma (,) or a semicolon (;)."
                                />
                                <input
                                    id="staffingReportDistributionList"
                                    required={enableStaffingReport}
                                    type="text"
                                    className="form-control"
                                    name="staffingReportDistributionList"
                                    onChange={(event) => this.onSettingChanged(
                                        TenantSettings.StaffingReportDistributionList,
                                        event.target.value,
                                    )}
                                    value={
                                        staffingReportDistributionList
                                    }
                                />
                                <small className="invalid-feedback text-danger">
                A distribution list is required to
                send the monthly staffing report.
                                </small>

                                <div className="mt-2">
                                    <ValidatedEmailDistributionList
                                        emailAddresses={
                                            staffingReportDistributionList
                                        }
                                    />
                                </div>
                            </FormGroup>
                        )}

                        <SubHeading>Contracts</SubHeading>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkContractUnionWork"
                                checked={enforceContractUnionWork}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.EnforceContractUnionWork,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enforce union work types on contract"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkContractUnionWork"
                            >
                                Contracts must select one of the union work type options when being created - one of None, Distribution, or Heavy Highway
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkContractDateRange"
                                checked={enableContractDateRange}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.EnableContractDateRange,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Contract Effective Date Range"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkEnableStaffingReport"
                            >
                                Allows for contracts to automatically expire at the end of the specified date range. If enabled, also allows sending contract expiry notifications to an email list.
                            </UncontrolledTooltip>
                        </FormGroup>

                        {enableContractDateRange && (
                            <>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="contractExpiryDistributionList"
                                        text="Contract Expiry - Email Distribution List"
                                        helpMessage="Email addresses should be split by either a comma (,) or a semicolon (;)."
                                    />
                                    <input
                                        id="contractExpiryDistributionList"
                                        required={enableContractDateRange}
                                        type="text"
                                        className="form-control"
                                        name="contractExpiryDistributionList"
                                        onChange={(event) => this.onSettingChanged(
                                            TenantSettings.ContractExpiryDistributionList,
                                            event.target.value,
                                        )}
                                        value={
                                            contractExpiryDistributionList
                                        }
                                    />
                                    <small className="invalid-feedback text-danger">
                  A distribution list is required to
                  notify users about expiring/expired
                  contracts.
                                    </small>

                                    <div className="mt-2">
                                        <ValidatedEmailDistributionList
                                            emailAddresses={
                                                contractExpiryDistributionList
                                            }
                                        />
                                    </div>
                                </FormGroup>

                                <FormGroup>
                                    <FormLabel
                                        htmlFor="contractExpiryCompanyName"
                                        text="Contract Expiry - Company Name"
                                        helpMessage={`Optional; if not set, the tenant company name ("${companyName}") will be used on the subject line.`}
                                    />
                                    <input
                                        id="contractExpiryCompanyName"
                                        required={false}
                                        type="text"
                                        className="form-control"
                                        name="contractExpiryCompanyName"
                                        onChange={(event) => this.onSettingChanged(
                                            TenantSettings.ContractExpiryCompanyName,
                                            event.target.value,
                                        )}
                                        value={contractExpiryCompanyName}
                                    />
                                </FormGroup>
                            </>
                        )}

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkEnableAutoEscalatingContractRates"
                                checked={enableAutoEscalatingContractRates}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.EnableAutoEscalatingContractRates,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Auto-Escalating Rates"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkEnableAutoEscalatingContractRates"
                            >
                UNIMPLEMENTED: Adds an "Auto-Escalating Rates" option to the Contract form
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="EnablePrevailingWageContracts"
                                checked={enablePrevailingWageContracts}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.EnablePrevailingWageContracts,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Prevailing Wages"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="EnablePrevailingWageContracts"
                            >
                Add a field to contracts to enable prevailing wages and enables access to other features, such as county capture
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="GLStringsEnabled"
                                checked={gLStringsEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.GLStringsEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable GL Strings"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="GLStringsEnabled"
                            >
                Allows setting contracts to require a GL (general ledger) string, which appears on invoices
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkContractsCanEnforceProjectManagers"
                                checked={contractsCanEnforceProjectManagers}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.ContractsCanEnforceProjectManagers,
                                    event.target.checked.toString(),
                                )}
                                labelText="Contracts can enforce Project Managers"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkContractsCanEnforceProjectManagers"
                            >
                Allows Project Managers to be added to contract jobs.
                            </UncontrolledTooltip>
                        </FormGroup>
                        <SubHeading>Jobs</SubHeading>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkJobUploads"
                                checked={jobUploadsEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.JobUploadsEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Job File Attachments"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkJobUploads"
                            >
                Allows uploading attachments in the job form and displays the attachments on job assignments and timesheets
                            </UncontrolledTooltip>
                        </FormGroup>

                        <SubHeading>Timesheets</SubHeading>
                        <FormGroup className="mt-3">
                            <FormLabel
                                htmlFor="timesheetResponsibilityType"
                                text="Timesheet Responsibility"
                                required
                            />
                            <ValidatedSelect
                                id="timesheetResponsibilityType"
                                name="timesheetResponsibilityType"
                                required
                                options={timesheetResponsibilities}
                                value={(
                                    this.state.timesheetResponsibilities
                                        ?? []
                                ).find(
                                    (x) => x.id === timesheetResponsibility,
                                )}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                onChange={(item) => this.onSettingChanged(
                                    TenantSettings.TimesheetResponsibility,
                                    (item ?? {}).id ?? '',
                                )}
                                validationMessage="A timesheet responsibility selection is required."
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel
                                htmlFor="PayPeriodBeginDayOfWeek"
                                text="Pay Period Begins On"
                                required
                            />
                            <ValidatedSelect
                                id="payPeriodBeginDayOfWeek"
                                name="payPeriodBeginDayOfWeek"
                                required
                                options={daysOfWeek}
                                value={payPeriodBeginDayOfWeek}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                onChange={(item) => this.onSettingChanged(
                                    TenantSettings.PayPeriodStartDay,
                                    item.id,
                                )}
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="payPeriodBeginDayOfWeek"
                            >
                Unimplemented
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel
                                htmlFor="PayPeriodBeginEndOfWeek"
                                text="Pay Period Ends On"
                                required
                            />
                            <ValidatedSelect
                                id="payPeriodBeginEndOfWeek"
                                name="payPeriodBeginEndOfWeek"
                                required
                                options={daysOfWeek}
                                value={payPeriodEndDayOfWeek}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.name}
                                onChange={(item) => this.onSettingChanged(
                                    TenantSettings.PayPeriodEndDay,
                                    item.id,
                                )}
                            />
                            <UncontrolledTooltip
                                placement="left"
                                target="payPeriodBeginEndOfWeek"
                            >
                Unimplemented
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkDriverTimesheetAcknowledgement"
                                checked={enableDriverRideConfirmationFields}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.EnableDriverRideConfirmationFields,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Driver Ride Confirmation Fields"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkDriverTimesheetAcknowledgement"
                            >
                                Displays fields for drivers to confirm ride disclaimers and directives on timesheets. 
                                Toggle this setting to include or exclude driver-related ride acknowledgments in timesheet reports.
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkDriverTimesheetReportingEnabled"
                                checked={enableDriverReportingInTimesheet}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.EnableDriverReportingInTimesheet,
                                    event.target.checked.toString(),
                                )}
                                labelText="Include Assigned Driver in Timesheet Reports"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkDriverTimesheetReportingEnabled"
                            >
                Includes the assigned driver for vehicles that are billed hourly
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkTimesheetAdjustmentsEnabled"
                                checked={timesheetAdjustmentsEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.TimesheetAdjustmentsEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Timesheet Adjustments"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkTimesheetAdjustmentsEnabled"
                            >
                Allows for admins to adjust timesheets after they have been submitted by the crew lead
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkEnablePredefinedTimesheetMissingSigReason"
                                checked={
                                    enablePredefinedTimesheetMissingSigReason
                                }
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.EnablePredefinedTimesheetMissingSigReason,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Timesheet Predefined Missing Signature Reasons"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkEnablePredefinedTimesheetMissingSigReason"
                            >
                Displays a list of reasons when "No Signature" is selected for a timesheet client review
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel
                                htmlFor="startTime"
                                text="Timesheet Emergency Start Time"
                                required
                            />
                            <input
                                id="startTime"
                                required={timesheetEmergencyEnabled}
                                type="time"
                                className="form-control"
                                name="timesheetEmergencyStart"
                                defaultValue={timesheetEmergencyStart ?? ''}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.TimesheetEmergencyStart,
                                    event.target.value,
                                )}
                                readOnly={!timesheetEmergencyEnabled}
                            />
                            <small className="invalid-feedback text-danger">
                Start Time is required.
                            </small>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel
                                htmlFor="endTime"
                                text="Timesheet Emergency End Time"
                                required
                            />
                            <input
                                id="endTime"
                                required={timesheetEmergencyEnabled}
                                type="time"
                                className="form-control"
                                name="timesheetEmergencyEnd"
                                defaultValue={timesheetEmergencyEnd ?? ''}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.TimesheetEmergencyEnd,
                                    event.target.value,
                                )}
                                readOnly={!timesheetEmergencyEnabled}
                            />
                            <small className="invalid-feedback text-danger">
                End Time is required.
                            </small>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkEnableSelfDispatchTimesheets"
                                checked={enableSelfDispatchTimesheets}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.EnableSelfDispatchTimesheets,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Self-Dispatching Timesheets"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkEnableSelfDispatchTimesheets"
                            >
                Allows access to non-flagging, or "self-dispatching", timesheets
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkTimesheetEmailEnabled"
                                checked={timesheetEmailEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.TimesheetEmailEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Emailing of Timesheets"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkTimesheetEmailEnabled"
                            >
                Emails timesheets to a list of people determined on the contract whenever a timesheet is submitted
                            </UncontrolledTooltip>
                        </FormGroup>

                        {timesheetEmailEnabled && (
                            <>
                                <FormGroup>
                                    <FormLabel
                                        htmlFor="timesheetEmailFromName"
                                        text="Timesheet Email From Name"
                                        required
                                    />
                                    <input
                                        id="timesheetEmailFromName"
                                        required={timesheetEmailEnabled}
                                        type="text"
                                        placeholder="ex: Support"
                                        autoComplete="off"
                                        className="form-control"
                                        name="timesheetEmailFromName"
                                        onChange={(event) => this.onSettingChanged(
                                            TenantSettings.TimesheetEmailFromName,
                                            event.target.value,
                                        )}
                                        value={timesheetEmailFromName}
                                    />

                                    <UncontrolledTooltip
                                        placement="left"
                                        target="timesheetEmailFromName"
                                    >
                                        All timesheet emails will be sent from this name and address.
                                    </UncontrolledTooltip>
                                </FormGroup>

                                <FormGroup>
                                    <FormLabel
                                        htmlFor="timesheetEmailReplyTo"
                                        text="Timesheet Email From Email"
                                        required
                                    />
                                    <input
                                        id="timesheetEmailReplyTo"
                                        type="email"
                                        required={timesheetEmailEnabled}
                                        placeholder="ex: person@provider.com"
                                        autoComplete="off"
                                        className="form-control"
                                        name="timesheetEmailReplyTo"
                                        onChange={(event) => this.onSettingChanged(
                                            TenantSettings.TimesheetEmailReplyTo,
                                            event.target.value,
                                        )}
                                        value={timesheetEmailReplyTo}
                                    />

                                    <UncontrolledTooltip
                                        placement="left"
                                        target="timesheetEmailReplyTo"
                                    >
                                        All timesheet emails will have this address in the "From" field
                                    </UncontrolledTooltip>
                                </FormGroup>

                                <FormGroup>
                                    <FormLabel
                                        htmlFor="timesheetEmailBcc"
                                        text="Timesheet Digest Email BCC"
                                    />

                                    <input
                                        id="timesheetEmailBcc"
                                        type="text"
                                        placeholder="ex: person@provider.com,person2@provider.com"
                                        autoComplete="off"
                                        className="form-control"
                                        name="timesheetEmailBcc"
                                        onChange={(event) => this.onSettingChanged(
                                            TenantSettings.TimesheetEmailBcc,
                                            event.target.value,
                                        )}
                                        value={timesheetEmailBcc}
                                    />

                                    <UncontrolledTooltip
                                        placement="left"
                                        target="timesheetEmailBcc"
                                    >
                                        Email addresses in this field will be BCC'd on all digest emails.
                                    </UncontrolledTooltip>
                                </FormGroup>
                            </>
                        )}
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkTimesheetDashboardEnabled"
                                checked={timesheetDashboardEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.TimesheetDashboardEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Timesheet Dashboard"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkTimesheetDashboardEnabled"
                            >
                Provides access to the Timesheet Dashboard from the home page
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkTimesheetExportEnabled"
                                checked={timesheetExportEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.TimesheetExportEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Timesheet Export"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkTimesheetExportEnabled"
                            >
                Adds an "Export" button to the Timesheet Management page
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkTimesheetUseJobCity"
                                checked={timesheetUseJobCity}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.TimesheetUseJobCity,
                                    event.target.checked.toString(),
                                )}
                                labelText="Show Job City and Enforce on Approval"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkTimesheetUseJobCity"
                            >
                Requires the "Job City" field for timesheet approvals. Note: a system administrator is required to add new city options to the dropdown
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkUseLimitedApprovals"
                                checked={useLimitedApprovals}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.UseLimitedApprovals,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Limited Approvals"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkUseLimitedApprovals"
                            >
                Adds a field to the Charge Type that allows restricting timesheet approvals to specific user groups
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkAllowTimesheetStatusRollbacks"
                                checked={allowTimesheetStatusRollbacks}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.AllowTimesheetStatusRollbacks,
                                    event.target.checked.toString(),
                                )}
                                labelText="Allow Timesheet Statuses To Be Rolled Back"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkAllowTimesheetStatusRollbacks"
                            >
                Allows users to roll timesheets in the Approved and Exported status back to Submitted
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkShowContractDescriptionInTimesheetView"
                                checked={
                                    showContractDescriptionInTimesheetView
                                }
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.ShowContractDescriptionInTimesheetView,
                                    event.target.checked.toString(),
                                )}
                                labelText="Show Contract Description"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkShowContractDescriptionInTimesheetView"
                            >
                All places that display jobs also display the job - i.e. contract - name
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkenableNewCustomerBillableHoursReport"
                                checked={
                                    enableNewCustomerBillableHoursReport
                                }
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.EnableNewCustomerBillableHoursReport,
                                    event.target.checked.toString(),
                                )}
                                labelText="Send Weekly New Customer Billable Hours Report"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkenableNewCustomerBillableHoursReport"
                            >
                Sends a weekly report email and also allows for manually running the report through the "Collected Reporting" administrative page
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chktimesheetEquipmentAuditEnabled"
                                checked={
                                    timesheetEquipmentAuditEnabled
                                }
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.TimesheetEquipmentAuditEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable timesheet equipment audit logs"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chktimesheetEquipmentAuditEnabled"
                            >
                                Sets whether the user can view the timesheet equipment audit logs.
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormLabel
                                className="w-100 contract-email-label"
                                htmlFor="submittedTimesheetEmailList"
                                text="Daily Timesheets Report Email Recipients"
                            />
                            <small className="pl-2 pb-2 text-success">
                    Enter addresses at which you would like
                    to receive daily timesheets report below.
                            </small>
                            <EmailRecipients
                                currentEmailAddress={
                                    currentSubmittedTimesheetEmailList
                                }
                                onEmailAdded={this.onEmailAdded}
                                onEmailChanged={this.onEmailChanged}
                                onEmailRemoved={this.onEmailRemoved}
                                emailAddresses={
                                    submittedTimesheetEmailList
                                }
                            />
                        </FormGroup>
                        {enableNewCustomerBillableHoursReport && (
                            <FormGroup>
                                <FormLabel
                                    htmlFor="newCustomerBillableHoursReportDistributionList"
                                    text="New Customer Billable Hours - Email Distribution List"
                                    helpMessage="Email addresses should be split by either a comma (,) or a semicolon (;)."
                                />
                                <input
                                    id="newCustomerBillableHoursReportDistributionList"
                                    required={
                                        enableNewCustomerBillableHoursReport
                                    }
                                    type="text"
                                    className="form-control"
                                    name="contractExpiryDistributionList"
                                    onChange={(event) => this.onSettingChanged(
                                        TenantSettings.NewCustomerBillableHoursReportDistributionList,
                                        event.target.value,
                                    )}
                                    value={
                                        newCustomerBillableHoursReportDistributionList
                                    }
                                />
                                <small className="invalid-feedback text-danger">
                A distribution list is required to
                notify users about new customers'
                weekly billable hours.
                                </small>

                                <div className="mt-2">
                                    <ValidatedEmailDistributionList
                                        emailAddresses={
                                            newCustomerBillableHoursReportDistributionList
                                        }
                                    />
                                </div>
                            </FormGroup>
                        )}

                        <SubHeading>Bulk Billing</SubHeading>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkBillingEnabled"
                                checked={billingEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.BillingEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Billing"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkBillingEnabled"
                            >
                Adds billing support to the platform, which is required for payroll and invoicing
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkBlockBillingCancelEnabled"
                                checked={blockBillingCancelEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.BlockBillingCancelEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Show Block Cancellation if Charge > 0 for Billing"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkBlockBillingCancelEnabled"
                            >
                Adds a field to the Charge Type form that allows for blocking job cancellation if the charge is on it
                            </UncontrolledTooltip>
                        </FormGroup>

                        <SubHeading>Organization</SubHeading>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkEnableAlternateBillTo"
                                checked={enableAlternateBillTo}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.EnableAlternateBillTo,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Alternate Bill To"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkEnableAlternateBillTo"
                            >
                Adds Billing Address fields to the Organization form, which show up in invoices
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkEnableRequestedPersonnel"
                                checked={enableRequestedPersonnel}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.EnableRequestedPersonnel,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Requested Personnel"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkEnableRequestedPersonnel"
                            >
                Adds separate resource fields to the Job form whenever AFADs are allocated to a job
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkEnableJobOverrides"
                                checked={enableJobOverrides}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.EnableJobOverrides,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Overriding Job Details Per-Day"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkEnableJobOverrides"
                            >
                Allows adjusting job details from the Job form for every day that resources have been allocated
                            </UncontrolledTooltip>
                        </FormGroup>

                        <SubHeading>Billing Rates</SubHeading>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkBillingRatesEnabled"
                                checked={billingRatesEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.BillingRatesEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Billing Rates"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkBillingRatesEnabled"
                            >
                Allows customizing rates for charge types on a per-customer or per-organization level
                            </UncontrolledTooltip>
                        </FormGroup>
                        <SubHeading>Payroll</SubHeading>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkPayrollEnabled"
                                checked={payrollEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.PayrollEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Payroll"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkPayrollEnabled"
                            >
                Adds the Payroll feature, which includes the Payroll: Charges and Payroll: Pay Categories pages
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkBlockPayrollCancelEnabled"
                                checked={blockPayrollCancelEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.BlockPayrollCancelEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Show Block Cancellation if Charge > 0 for Payroll"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkBlockPayrollCancelEnabled"
                            >
                Adds a field to the Charge Type form that allows for blocking payroll cancellation if the charge is on the associated job
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkCaptureStateMinWage"
                                checked={useStateMinimum}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.UseStateMinimum,
                                    event.target.checked.toString(),
                                )}
                                labelText="Capture State Minimum Wage?"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkCaptureStateMinWage"
                            >
                Adds a field to the Charge Type form that specifies if it is to be paid at the state minimum rate
                            </UncontrolledTooltip>
                        </FormGroup>

                        <SubHeading>Invoicing</SubHeading>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkInvoicingEnabled"
                                checked={invoicingEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.InvoicingEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Invoicing"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkInvoicingEnabled"
                            >
                Adds the Invoicing feature
                            </UncontrolledTooltip>
                        </FormGroup>

                        <SubHeading>Dispatching</SubHeading>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkCrossDispatching"
                                checked={allowCrossDispatching}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.AllowCrossDispatching,
                                    event.target.checked.toString(),
                                )}
                                labelText="Allow Cross Dispatching of Resources"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkCrossDispatching"
                            >
                Allows administrators to dispatch any resource from any office to any job, rather than just the resources available to the job's office
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkCrews"
                                checked={crewsEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.CrewsEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Crews"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkCrews"
                            >
                Unimplemented: Adds a "Crews" tab to the Dispatch board
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkHours"
                                checked={hoursEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.DispatchHoursEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Hours Display/Sort"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkHours"
                            >
                Unimplemented: Adds a sort button to the Staff tab of the dispatch board
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkDefaultCharge"
                                checked={defaultChargeEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.DefaultChargeEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Default Charge"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkDefaultCharge"
                            >
                Unimplemented
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkRollUpDriveTime"
                                checked={rollUpDriveTimeEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.RollUpDriveTime,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Drive Time Roll up on Billing"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkRollUpDriveTime"
                            >
                Legacy billing only: Update legacy invoices to bill drive time at a flat rate rather than hourly
                            </UncontrolledTooltip>
                        </FormGroup>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="dispatchStatusFilterEnabled"
                                checked={dispatchStatusFilterEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.DispatchStatusFilterEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Status Filter"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="dispatchStatusFilterEnabled"
                            >
                Adds long term status and other status filters to the bottom of the Dispatch board
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkAttendanceEventsEnabled"
                                checked={attendanceEventsEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.AttendanceEventsEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Attendance Events"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkAttendanceEventsEnabled"
                            >
                Adds the Daily Attendance dashboard and adds reporting to the dispatch board and daily roster board
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormLabel
                                text="Assignment Cancellation Threshold"
                                required={false}
                            />
                            <NumericInput
                                required
                                ref={this.asncnlRef}
                                name="assignmentCancellationThreshold"
                                value={assignmentCancelThresh}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    this.onSettingChanged(
                                        TenantSettings.AssignmentCancellationThreshold,
                                        value,
                                    );
                                }}
                                placeholder="Enter Threshold Minutes"
                                type="number"
                                step="1"
                                max={999 ?? ''}
                                wholeNumber
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel
                                text="Assignment Cancellation Minimum Charge"
                                required
                            />
                            <NumericInput
                                required
                                ref={this.asncnlMinRef}
                                name="assignmentCancellationMinimumCharge"
                                value={assignmentCancelMin}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    this.onSettingChanged(
                                        TenantSettings.AssignmentCancellationMinimumCharge,
                                        value,
                                    );
                                }}
                                placeholder="Enter Minimum Minutes"
                                type="number"
                                step="1"
                                max={999 ?? ''}
                                wholeNumber
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel
                                htmlFor="companyTypes"
                                text="Optional Company Type(s)"
                            />
                            <ValidatedSelect
                                id="companyTypes"
                                name="tenant.companyTypes"
                                isMulti
                                options={optionalCompanyTypes}
                                value={
                                    (optionalCompanyTypes ?? []).filter(
                                        (x) => (
                                            tenant.companyTypes ?? []
                                        ).includes(x.value),
                                    ) ?? ''
                                }
                                onChange={this.onSelectChange}
                                validationMessage="The company type is required."
                            />
                        </FormGroup>

                        <SubHeading>Compliances</SubHeading>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkCompliance"
                                checked={complianceEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.ComplianceEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Compliances"
                            />
                        </FormGroup>

                        <SubHeading>Contacts</SubHeading>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkContactScreenEnabled"
                                checked={contactScreenEnabled}
                                onChange={(event) => {
                                    const contactScreenEnabled = event.target.checked.toString();

                                    this.onSettingChanged(
                                        TenantSettings.ContactScreenEnabled,
                                        contactScreenEnabled,
                                    );

                                    if (contactScreenEnabled !== 'true') {
                                        this.onSettingChanged(
                                            TenantSettings.ContactActivitiesEnabled,
                                            'false',
                                        );
                                    }
                                }}
                                labelText="Enable Contacts Screen"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkContactScreenEnabled"
                            >
                Adds the Contacts dashboard, which allows for viewing and searching organization contacts without needing to navigate through organizations
                            </UncontrolledTooltip>
                        </FormGroup>

                        {contactScreenEnabled && (
                            <FormGroup>
                                <FormCheckbox
                                    className="mt-2 mb-2"
                                    id="chkContactActivitiesEnabled"
                                    checked={contactActivitiesEnabled}
                                    onChange={(event) => this.onSettingChanged(
                                        TenantSettings.ContactActivitiesEnabled,
                                        event.target.checked.toString(),
                                    )}
                                    labelText="Enable Contact Activities"
                                />

                                <UncontrolledTooltip
                                    placement="left"
                                    target="chkContactActivitiesEnabled"
                                >
                Adds the Contact Activities dashboard, which allows adding calendar events and other notes to contacts
                                </UncontrolledTooltip>
                            </FormGroup>
                        )}

                        <SubHeading>Daily Job View Export</SubHeading>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="includeSchedulingOnDailyJobExport"
                                checked={includeSchedulingOnDailyJobExport}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.IncludeSchedulingOnDailyJobExport,
                                    event.target.checked.toString(),
                                )}
                                labelText="Include Job Scheduling Column in Excel Export"
                            />
                        </FormGroup>

                        <SubHeading>Mapping</SubHeading>

                        <FormGroup>
                            <FormLabel
                                htmlFor="googleMapsApiKey"
                                text="Google Maps API Key"
                            />
                            <input
                                id="googleMapsApiKey"
                                required={
                                    captureCounty
                                            == CountyCaptureType.AllTimesheets
                                        || CountyCaptureType.PrevailingWageTimesheets
                                }
                                type="text"
                                className="form-control"
                                name="googleMapsApiKey"
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.GoogleMapsApiKey,
                                    event.target.value,
                                )}
                                value={googleMapsApiKey}
                            />
                            <small className="invalid-feedback text-danger">
                Google Maps API key is required for county
                capture.
                            </small>

                            <UncontrolledTooltip
                                placement="left"
                                target="googleMapsApiKey"
                            >
                Required for county capture - used for performing a Places search when adding addresses
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormLabel
                                htmlFor="captureCounty"
                                text="County Capture"
                            />
                            <ValidatedSelect
                                id="countyCaptureType"
                                name="countyCaptureType"
                                options={countyCaptureTypes}
                                value={(countyCaptureTypes ?? []).filter(
                                    (x) => x.value == captureCounty ?? [],
                                )}
                                onChange={(item) => this.onSettingChanged(
                                    TenantSettings.CaptureCounty,
                                    item.value,
                                )}
                                validationMessage="The county capture type is required."
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="countyCaptureType"
                            >
                Automatically detects counties when adding meeting or job locations
                            </UncontrolledTooltip>
                        </FormGroup>

                        <SubHeading>Roster</SubHeading>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkShowLongTermStatusCountOnRoster"
                                checked={showLongTermStatusCountOnRoster}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.ShowLongTermStatusCountOnRoster,
                                    event.target.checked.toString(),
                                )}
                                labelText="Show Long Term Status Count On Roster"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkShowLongTermStatusCountOnRoster"
                            >
                Updates the Roster view to include counts of every long term status
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkEmployeeLongTermStatusManagementEnabled"
                                checked={
                                    employeeLongTermStatusManagementEnabled
                                }
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.EmployeeLongTermStatusManagementEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Employee Long-Term Status Management"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkEmployeeLongTermStatusManagementEnabled"
                            >
                Allows for setting long-term statuses in the roster view and employee form
                            </UncontrolledTooltip>
                        </FormGroup>

                        <SubHeading>Charge Types</SubHeading>
                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkAllowDefaultChargeTypeValues"
                                checked={allowDefaultChargeTypeValues}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.AllowDefaultChargeTypeValues,
                                    event.target.checked.toString(),
                                )}
                                labelText="Allow default values for charge types to be configured?"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkAllowDefaultChargeTypeValues"
                            >
                Adds support for setting default timesheet values for flat rate charge types
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkEnableAFADChargeTypeGrouping"
                                checked={enableAFADChargeTypeGrouping}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.EnableAFADChargeTypeGrouping,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable AFAD Charge Type Grouping"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkEnableAFADChargeTypeGrouping"
                            >
                Adds a "Num AFADs In Set" field to the Charge Type form for enabling billing units by set
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup hidden={!invoicingEnabled}>
                            <FormCheckbox
                                className="mt-2 mb-2"
                                id="chkChargeTypeGroupsEnabled"
                                checked={chargeTypeGroupsEnabled}
                                onChange={(event) => this.onSettingChanged(
                                    TenantSettings.ChargeTypeGroupsEnabled,
                                    event.target.checked.toString(),
                                )}
                                labelText="Enable Charge Type Groups"
                            />

                            <UncontrolledTooltip
                                placement="left"
                                target="chkChargeTypeGroupsEnabled"
                            >
                Adds the Charge Type Groups feature, which allows multiple charge types and quantities of charge types to be grouped into one line item
                            </UncontrolledTooltip>
                        </FormGroup>

                        <FormGroup>
                            <Button
                                disabled={!!this.state.saving}
                                size="sm"
                                type="submit"
                                color="primary"
                                name="tenantForm"
                            >
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faSave}
                                />
                                {this.state.saving ? 'Saving...' : 'Save'}
                            </Button>
                        </FormGroup>
                        <FormGroup>
                            <Button
                                disabled={!!this.state.saving}
                                size="sm"
                                type="button"
                                color="secondary"
                                onClick={async () => {
                                    const url = ApiRoutes.tenant.invalidPhoneNumbers();
                                    await util.fetch.downloadFile(
                                        url,
                                        null,
                                        `${this.state.tenant.companyName.replace(
                                            ' ',
                                            '_',
                                        )}_invalid_mobile_numbers.xlsx`,
                                    );
                                }}
                            >
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faPhone}
                                />
                Get Invalid Mobile Numbers Report
                            </Button>
                        </FormGroup>
                    </FlexColumnCenter>
                </AppPageForm>
            </>
        );
    }
}
export default withRouter(TenantForm);

import React from 'react';
import {
    faExclamationTriangle,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FormGroup, Input, Alert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BaseFormViewModel } from '../common/ViewModel';
import {
    FormLabel,
    onFieldChange,
    onReactSelectChanged,
    ValidationErrorMessage,
} from '../common/forms/FormElements';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import { handleFormSaveError } from '../common/forms/ValidationError';

export default class StaffingReportForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const stateBase = {
            tenants: [],
            forDate: null,
            loading: true,
            tenantId: null,
            saving: false,
            suppliedTenantData: props.tenants || null,
            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    componentDidMount() {
        return this.populateState(); 
    }

    onChange = onFieldChange;

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    };

    onSelectChange = onReactSelectChanged;

    onSubmit = async (e) => {
    // Clear any fluent api errors
        this.clearErrors();

        this.setState({ saving: true });

        const { tenantId, forDate } = this.state;

        const data = { tenantId, forDate };

        const dateString = forDate.replaceAll('-', '');
        const fileName = `StaffingReport_${dateString}.xlsx`;

        try {
            await util.fetch.downloadFile(
                ApiRoutes.report.staffing(),
                data,
                fileName,
            );
        } finally {
            this.setState({ saving: false });
        }
    };

    clearErrors = () => this.setState({ errors: {} });

    handleSaveError = (err) => handleFormSaveError(this, err);

    populateState = async () => {
        const { suppliedTenantData } = this.state;

        const [tenantData] = await Promise.all([
            suppliedTenantData || util.fetch.js(ApiRoutes.tenant.all()),
        ]);

        const tenants = tenantData.map((t) => ({ label: t.companyName, value: t.id }));

        this.setState({
            tenants,
            loading: false,
        });
    };

    resetForm = () => this.setState({ formValidated: false });

    render() {
        const {
            tenants,
            formValidated,
            validationMessage,
            loading,
            tenantId,
            forDate,
            errors,
            saving,
        } = this.state;

        const { show } = this.props;

        return (
            <SlideForm
                loading={loading || saving}
                show={show}
                id="staffingReportForm"
                formIcon={faUsers}
                formTitle="Staffing Report"
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value });
                }}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                onSave={this.onSubmit}
                errors={errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}
                saveButtonText="Generate Report"
                saveDisabled={saving}
            >
                <Alert color="warning" fade={false} className="mb-3">
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="mr-2"
                    />
          This report may impact application performance while
          running.
                </Alert>

                <FormGroup>
                    <FormLabel
                        htmlFor="tenantId"
                        text="Tenant"
                        required
                    />
                    <ValidatedSelect
                        id="tenantId"
                        name="tenantId"
                        required
                        options={tenants}
                        value={
                            (tenants ?? []).filter(
                                (x) => tenantId == x.value,
                            ) ?? ''
                        }
                        validationMessage="Tenant selection is required."
                        onChange={this.onSelectChange}
                    />
                </FormGroup>

                <FormGroup>
                    <FormLabel
                        htmlFor="forDate"
                        text="Report Date"
                        required
                    />

                    <Input
                        id="forDate"
                        name="forDate"
                        value={forDate ?? ''}
                        onChange={this.onChange}
                        type="date"
                        required
                    />
                    <ValidationErrorMessage>
            Report Date is required.
                    </ValidationErrorMessage>
                </FormGroup>
            </SlideForm>
        );
    }
}

import { faUserClock } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import {
    Button,
    FormGroup,
} from 'reactstrap';
import CommonContext, { ApiRoutes } from '../Common';
import {
    FormLabel,
    FlexCenterRow,
    toasty,
    onReactSelectChanged,
    FormCheckbox,
} from '../common/forms/FormElements';
import SlideForm from '../common/forms/SlideForm';
import { BaseFormViewModel } from '../common/ViewModel';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { SignatureReviewTable } from './SignatureReviewTable';
import './ClientReview.scss';
import { ClientTimesheetSignature } from './ClientTimesheetSignature';

export const TimesheetSignatureType = {
    ClientForeman: 1,
    ClientOtherAuthorized: 2,
    ClientNoSignature: 3,
    Employee: 4,
    EmployeeNoSignature: 5,
};

export default class ClientReview extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.signatureRef = React.createRef();
        const stateBase = {
            review: {},
            formValidated: false,
            isOpen: false,
            signatureTypes: [],
            currentEmailAddress: '',
            specificEmailAdds: [],
            sigIsComplete: false,
            displayReuse: false,
            ...new BaseFormViewModel(),
        };
        this.baseState = stateBase;
        this.state = stateBase;
        this.resetForm = this.resetForm.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidUpdate(e) {
    }

    onChangeNotes = (e) => {
        const { review } = { ...this.state };
        review.notes = e.target.value;
        this.setState({ review });
    };

    onClose = () => {
        this.resetForm();
        this.props.toggleShow(false);
        this.context.setFormOpened(false);
        this.props.onClose();
    };

    onDelete = async (e) => { };

    onEmailAdded = (e) => {
        const { currentEmailAddress, review } = { ...this.state };
        if (currentEmailAddress) {
            const emailAddress = currentEmailAddress.trim();
            const match = util.validation.email(emailAddress);
            if (match) {
                if (!(review.emailAddresses ?? []).length) review.emailAddresses = [];
                if (!review.emailAddresses.includes(emailAddress)) review.emailAddresses.push(emailAddress);
                this.setState({ review: { ...review }, currentEmailAddress: null });
            } else {
                toasty.error('Please enter a single valid email address.');
            }
        }
    };

    onEmailChanged = (e) => {
        const val = e.currentTarget.value;
        this.setState({ currentEmailAddress: val });
    };

    onEmailForemanChanged = (e) => {
        const include = e.target.checked;
        const { review } = { ...this.state };
        review.emailForeman = include;
        this.setState({ review });
    };

    onEmailRemoved = (selected) => {
        const { review } = { ...this.state };
        const inx = review.emailAddresses.findIndex((ea) => ea === selected);
        review.emailAddresses.splice(inx, 1);
        this.setState({ review });
    };

    onMissingSigReasonChanged = (reason) => {
        const { review } = { ...this.state };
        review.missingSigReasonId = reason.value;
        this.setState({ review });
    };

    onReuseSignatureChanged = (e) => {
        const include = e.target.checked;
        const { review } = { ...this.state };
        review.reuseClientSignature = include;
        this.setState({ review });
    };

    onSelectChanged = onReactSelectChanged;

    onSigSavedCallback = (sigData) => {
        const { review } = { ...this.state };
        review.reviewSignature.signatureData = sigData;
        review.newClientSignature = true;
        this.setState({ review, sigIsComplete: true });
    };

    onSignatureTypeChanged = (signatureType) => {
        let { review, sigIsComplete } = { ...this.state };

        review.signatureTypeId = signatureType.value;

        if (review.signatureTypeId === TimesheetSignatureType.ClientNoSignature) {
            review.reviewSignature.signatureData = null;
            sigIsComplete = false;
        }

        this.setState({ review, sigIsComplete });
    };

    onSubmit = async () => {
        const { review } = { ...this.state };
        const { tenantSettings } = { ...this.props };

        if (review.signatureTypeId !== TimesheetSignatureType.ClientNoSignature) {
            if (!((!!review.reviewSignature.signatureData
                    // Make sure it's not just the mime type prefix.
                    && review.reviewSignature.signatureData !== 'data:image/png;base64,') && (review.newClientSignature || review.reuseClientSignature))
            ) {
                toasty.error('A signature is required.');
                this.setState({ formValidated: true });
                return;
            }
        } else {
            // no signature notes or reason is required.
            if (!tenantSettings.enablePredefinedTimesheetMissingSigReason && !review.notes) {
                toasty.error('Provide a reason there was no signature.');
                this.setState({ formValidated: true });
                return;
            }
            if (!!tenantSettings.enablePredefinedTimesheetMissingSigReason && !review.missingSigReasonId) {
                toasty.error('Reason for no signature - a selection is required in the drop down.');
                this.setState({ formValidated: true });
                return;
            }
        }

        try {
            await util.fetch.post(ApiRoutes.timesheetReview.update(), review);
            toasty.success('Saved');
            this.onClose();
        } catch (err) {
            toasty.error(err.handledError.message);
        }
    };

    setRef = (ref) => {
    // keep a reference to the dom ref as an instance property
        this.ref = ref;
    // give the dom ref to react-beautiful-dnd
    // this.props.innerRef(ref);
    };

    handleSaveError = (err) => handleFormSaveError(this, err);

    open = async (timesheetId) => {
        this.setState(this.baseState);
        await this.populateState();

        let review = null;

        this.resetForm();

        await this.setState({ loading: true });

        if (timesheetId) {
            review = await util.fetch.js(ApiRoutes.timesheetReview.clientByTimesheetId(timesheetId));
        } else {
            review = null;//
        }
        const displayReuse = this.context?.user.isImpersonating && !!review.reviewSignature && !!review.reviewSignature.signatureData && !review.isComplete;
        this.props.toggleShow(true);
        this.context.setFormOpened(true);

        await this.setState({ review, loading: false, displayReuse });
    };

    async populateState() {
        const [signatureTypes, missingSigReasons] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.clientSignatureTypes()),
            util.fetch.js(ApiRoutes.timesheetReview.missingSignatureReasons()),
        ]);

        this.setState({
            signatureTypes,
            missingSignatureReasons: missingSigReasons,
        });
    }

    resetForm = () => {
        this.setState({
            formValidated: false,
        });
    };

    render() {
        const {
            review,
            formValidated,
            validationMessage,
            sigIsComplete,
            displayReuse,
        } = this.state;

        const {
            tenantSettings,
        } = { ...this.props };

        if (!review) return null;

        return (
            <SlideForm
                size="col-xl-6 col-lg-6 col-md-12 col-sm-12"
                loading={this.state.loading}
                show={this.props.show}
                id="timesheetReview"
                formIcon={faUserClock}
                formTitle="Client Review / Signature"
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value }); 
                }}
                isValidated={formValidated}
                onClose={this.onClose}
                onDelete={this.onDelete}
                errors={this.state.errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}
            >
                <FormGroup>
                    <span className="h5 font-weight-bold text-center text-dark mb-0 pb-0">Summary of Billable Charges</span>
                </FormGroup>

                <FormGroup>
                    <SignatureReviewTable timeCharges={(review ?? {}).reviewTable} />
                </FormGroup>
                {
                    !!(review ?? {}).workOrderNumber
                        && (
                            <FormGroup>
                                <FormLabel text="Work Order" />
                                <span className="ml-3 text-danger font-weight-bold">{`[${review.workOrderNumber}]`}</span>
                            </FormGroup>
                        )
                }

                {displayReuse && (
                    <FormCheckbox
                        labelClass="text-danger text-small"
                        className="mt-2 pl-2 mb-3"
                        id="reuseClientSignature"
                        readOnly={false}
                        onChange={this.onReuseSignatureChanged}
                        labelText="[Admin] Re-Use Signature"
                    />
                )}
                {!(review.reuseClientSignature)
                        && (
                            <ClientTimesheetSignature
                                review={review}
                                onSignatureTypeChanged={this.onSignatureTypeChanged}
                                onMissingSigReasonChanged={this.onMissingSigReasonChanged}
                                onChangeNotes={this.onChangeNotes}
                                onSigSavedCallback={this.onSigSavedCallback}
                                signatureRef={this.signatureRef}
                                tenantSettings={tenantSettings}
                                sigIsComplete={sigIsComplete}
                            />
                        )}
                {
                    !review.isComplete
                        && (
                            <FlexCenterRow className="mt-2">
                                <Button className="btn mr-2" color="primary" onClick={this.onSubmit}>Save</Button>
                            </FlexCenterRow>
                        )
                }
            </SlideForm>
        );
    }
}

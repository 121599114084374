import moment from 'moment';
import { Weekdays } from '../Common';

export class JobOverride {
    id = null;

    jobId = 0;

    weekOfDate = '';

    overrideDate = null;

    overrideDay = null;

    startTime = null;


    dispatchOfficeLocationId = null;

    workOrderNumber = '';

    meetingLocationId = null;

    jobLocationId = null;

    notes = '';

    requestedById = null;

    foremenId = null;
    reason = null;
    isDeleted = false;

    meetingLocation = null; // Address()

    jobLocation = null; // Address()

    constructor(day) {
        this.overrideDay = Weekdays[day.getDay()].name;
        this.overrideDate = moment(day).toDate();
    }
}

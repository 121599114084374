import React from 'react';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import {
    Col,
    Row,
    FormGroup,
    Container,
    Button,
    Table,
    List,
    Spinner,
} from 'reactstrap';
import CommonContext, { ApiRoutes } from '../Common';
import { util } from '../Util';
import authService from '../api-authorization/AuthorizeService';
import {
    AppPageForm, FormLabel, onFieldChange, toasty,
} from '../common/forms/FormElements';
import CustomCircularProgress from '../common/CustomCircularProgress';
import './ExecutionHistory.scss';

class RejectBillingPayrollVM {
    timesheetId = '';
}

class RejectBillingPayroll extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            formValidated: false,
            loading: true,
            saving: false,
            RejectBillingPayrollVM: new RejectBillingPayrollVM(),
            selectedTimesheetOption: null,
            usageHistory: [],
            isValidated: false,
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    onChange = onFieldChange;

    onSubmit = async () => {
        const { RejectBillingPayrollVM } = { ...this.state };

        this.setState({ saving: true });
        try {
            await util.fetch.post(ApiRoutes.adminTool.rejectBillingPayroll(), RejectBillingPayrollVM);

            this.setState({
                RejectBillingPayrollVM: {
                    ...this.state.RejectBillingPayrollVM,
                    timesheetNumber: '',
                },
                selectedTimesheetOption: null,
                isValidated: false,
            });

            toasty.success('Billing Payroll Rejection Success');

            // Refresh the table after successful submission
            await this.getAdminToolHistory();
        } catch (err) {
            toasty.error('Error: Billing Payroll Rejection Failed. Please Check Timesheet Number');
            await this.getAdminToolHistory();
        } finally {
            this.setState({ saving: false });
        }
    };

    getAdminToolHistory = async () => {
        const [usageHistory] = await Promise.all([
            util.fetch.js(ApiRoutes.adminTool.getAdminToolHistory(6)),
        ]);

        this.setState({ usageHistory, loading: false });
    };

    loadTimesheetOptions = (inputValue) => {
        return fetch(ApiRoutes.typeAheads.timesheetNumberSearch(inputValue))
            .then((response) => response.json())
            .then((data) => {
                return data.map((item) => ({
                    label: `${item.label} - [${item.customer}] - ${item.status}${item.isDeleted ? ' (Deleted)' : ''}`,
                    value: item.value,
                    isDeleted: item.isDeleted
                }));
            })
            .catch((error) => {
                console.error('Error fetching timesheet options:', error);
                return [];
            });
    };

    onTimesheetChanged = async (selectedOption) => {
        await this.setState((prevState) => ({
            RejectBillingPayrollVM: {
                timesheetId: selectedOption.value
            },
            selectedTimesheetOption: selectedOption,
        }));
    };


    formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    open = async () => {
        this.setState(this.baseState);

        await this.populateState();
    };

    async populateState() {
        const { RejectBillingPayrollVM } = { ...this.state };

        const isAuthenticated = await authService.isAuthenticated();
        if (isAuthenticated) {
            this.setState({ loading: true });
            await this.getAdminToolHistory();

            this.setState({ RejectBillingPayrollVM });
        }
    }

    render() {
        const {
            loading, saving, isValidated, usageHistory,
        } = this.state;
        if (loading) {
            return (
                <div className="confirmationContainer h-100 pt-2 d-flex flex-row align-items-center justify-content-center">
                    <CustomCircularProgress
                        color="secondary"
                        variant="indeterminate"
                        label={
                            this.props.match.params.id
                                ? 'Getting Tool History...'
                                : 'Loading form...'
                        }
                    />
                </div>
            );
        }

        if (!(this.context ?? {}).user) return null;

        return (
            <AppPageForm
                formShown={this.context.formIsOpen}
                formId="adminToolList"
                formName="adminToolList"
                formHeading="Admin Tool: Reject Billing Payroll"
                formRef={this.formRef}
                saving={saving}
                isValidated={isValidated}
                onSubmit={this.onSubmit}
                setIsValidated={(value) => {
                    this.setState({ isValidated: value });
                }}
            >
                <Container>
                    <Row>
                        <Col
                            className="mt-3 mb-2"
                            xs="12"
                        >
                            <h3>
                                Reject Billing Payroll
                            </h3>
                        </Col>

                        <Col
                            className="bg-light border"
                            xs="12"
                        >
                            <div>
                                <FormGroup className="mt-3">
                                    <FormLabel htmlFor="timesheetNum" text="Timesheet Number" required />
                                    <AsyncSelect
                                        classNamePrefix="react-async"
                                        className="smallReactAsync"
                                        loadOptions={this.loadTimesheetOptions}
                                        placeholder="Type Timesheet Number Here"
                                        id="timesheetNumber"
                                        value={this.state.selectedTimesheetOption}
                                        onChange={this.onTimesheetChanged}
                                        cacheOptions
                                    />
                                    <small className="invalid-feedback text-danger">
                                        Timesheet Number is Required.
                                    </small>
                                </FormGroup>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        className="btn mr-2 mt-3 mb-2"
                                        color="primary"
                                        type="submit"
                                        disabled={
                                            !!saving
                                        }
                                    >
                                        {' '}
                                        {!saving && (
                                            <span>Submit</span>
                                        )}
                                        {!!saving && (
                                            <>
                                                <Spinner
                                                    size="sm"
                                                    className="saving-button-progress text-success mr-2"
                                                />
                                                <span>Submitting, please wait...</span>
                                            </>
                                        )}
                                    </Button>
                                </div>
                            </div>
                        </Col>

                        <Col xs="12" className="execution-history">
                            <h3 className="mt-4">
                                Execution History
                            </h3>

                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>User</th>
                                        <th>Details</th>
                                        <th>Execution Date & Time</th>
                                        <th>Result</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {usageHistory.map((record) => {
                                        const errorMessage = record.output.message;

                                        return (
                                            <tr key={record.id}>
                                                <td>{record.id}</td>
                                                <td>{record.userName}</td>
                                                <td>
                                                    <div>
                                                        <List type="unstyled">
                                                            {record.input.timesheetNumber && (
                                                                <li>
                                                                    <strong>Timesheet:</strong>
                                                                    {' '}
                                                                    {record.input.timesheetNumber}
                                                                </li>
                                                            )}
                                                        </List>
                                                    </div>

                                                    {errorMessage ? (
                                                        <div>
                                                            <strong>Error:</strong>
                                                            {' '}
                                                            {errorMessage}
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <List type="unstyled">
                                                                {record.output.removedBillingDetails && (
                                                                    <li>
                                                                        <strong>Removed Billing:</strong>
                                                                        {' '}
                                                                        {record.output.removedBillingDetails}
                                                                    </li>
                                                                )}
                                                                {record.output.removedPayrollCalculationIds && (
                                                                    <li>
                                                                        <strong>Removed Payroll Calcuations (ID):</strong>
                                                                        {' '}
                                                                        {record.output.removedPayrollCalculationIds}
                                                                    </li>
                                                                )}
                                                                {record.output.removedPayrollIds && (
                                                                    <li>
                                                                        <strong>Removed Payrolls (ID):</strong>
                                                                        {' '}
                                                                        {record.output.removedPayrollIds}
                                                                    </li>
                                                                )}
                                                                {!record.output.removedPayrollIds && !record.output.removedPayrollCalculationIds && !record.output.removedBillingDetails && (
                                                                    <li>
                                                                        <strong>Result:</strong>
                                                                        {' '}
                                                                        Billing Payroll Removal Success
                                                                    </li>
                                                                )}
                                                            </List>
                                                        </div>
                                                    )}
                                                </td>
                                                <td>{this.formatDate(record.executionDateTime)}</td>
                                                <td>{record.isSucceed ? 'Succeed' : 'Failed'}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>

            </AppPageForm>
        );
    }
}

export default withRouter(RejectBillingPayroll);

import React from 'react';
import { Alert } from 'reactstrap';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import { BaseFormViewModel } from '../common/ViewModel';
import {
    onFieldChange,
    onReactSelectChanged,
} from '../common/forms/FormElements';
import CommonContext, { ApiRoutes } from '../Common';
import SlideForm from '../common/forms/SlideForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';

export default class ExpiringContractsReportForm extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();

        const stateBase = {
            loading: true,
            saving: false,
            ...new BaseFormViewModel(),
        };

        this.state = stateBase;
        this.onSubmit = this.onSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    componentDidMount() {
        return this.populateState(); 
    }

    onChange = onFieldChange;

    onClose = (response) => {
        this.resetForm();
        this.context.setFormOpened(false);
        this.props.onClose(response);
    };

    onSelectChange = onReactSelectChanged;

    onSubmit = async (e) => {
    // Clear any fluent api errors
        this.clearErrors();

        this.setState({ saving: true });

        const data = {};

        const date = new Date();
        const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(
            date,
        );
        const month = new Intl.DateTimeFormat('en', {
            month: 'numeric',
        }).format(date);
        const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(
            date,
        );

        const dateString = `${year}${month}${day}`;
        const fileName = `ExpiringContracts_${dateString}.xlsx`;

        try {
            await util.fetch.downloadFile(
                ApiRoutes.report.expiringContract(),
                data,
                fileName,
            );
        } finally {
            this.setState({ saving: false });
        }
    };

    clearErrors = () => this.setState({ errors: {} });

    handleSaveError = (err) => handleFormSaveError(this, err);

    populateState = async () => {
        this.setState({
            loading: false,
        });
    };

    resetForm = () => this.setState({ formValidated: false });

    render() {
        const {
            formValidated, validationMessage, loading, errors, saving,
        } = this.state;

        const { show } = this.props;

        return (
            <SlideForm
                loading={loading || saving}
                show={show}
                id="expiringContractsReportForm"
                formIcon={faAddressCard}
                formTitle="Expiring Contracts Report"
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value });
                }}
                isValidated={formValidated}
                onSubmit={this.onSubmit}
                onClose={this.onClose}
                onSave={this.onSubmit}
                errors={errors}
                onClearErrors={this.onClearErrors}
                validationMessage={validationMessage}
                saveButtonText="Generate Report"
                saveDisabled={saving}
            >
                <Alert color="success" fade={false}>
          Expiring/expired contracts as of today's date will be
          returned.
                </Alert>
            </SlideForm>
        );
    }
}

import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withRouter } from 'react-router-dom';

import CommonContext, { ApiRoutes, AppNavPaths } from '../Common';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    indexCellRenderer,
    LinkCellRenderer,
    IconCellRenderer,
    VariableLinkCellRenderer,
    TextFilterDefaults,
    DateFilterDefaults,
} from '../common/dataGrid/DataGrid';

import { Progress } from 'reactstrap';

import DataGridSelectFilter from '../common/dataGrid/DataGridSelectFilter';
import DataGridSelectFloatingFilter from '../common/dataGrid/DataGridSelectFloatingFilter';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import {
    PageHeading,
    PageWrap,
    onFieldChange,
} from '../common/forms/FormElements';
import { handleFormSaveError } from '../common/forms/ValidationError';
import { util } from '../Util';
import CustomCircularProgress from '../common/CustomCircularProgress';
import { NotAuthorizedPage } from '../status/StatusCodes';

class BillingRateIndex extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.timesheetDetailsRef = React.createRef();
        const current = [
            { label: 'Active', value: 'true' },
            { label: 'Inactive', value: 'false' },
        ];

        this.state = {
            loading: true,
            rowData: [],
            rowsSelected: [],
            timesheetDetailsOpen: false,
            selectedDetails: [],
            showRejectTimesheetModal: false,
            isRejectingTimesheet: false,
            selectedRowRejectionNotes: '',
            loadingReport: false,
            showExportModal: false,
            isExporting: false,
            current,
            isApproving: false,
        };

        this.onRowClicked = this.onRowClicked.bind(this);
        this.onCellClicked = this.onCellClicked.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);
    }

    componentDidMount() {
        return this.populateState(); 
    }

    componentWillUnmount() {
        return this.setState = (state, callback) => {

        };
    }

    onCellClicked = (data) => {
    // test
        alert(data);
    };

    onChange = onFieldChange;

    onRowClicked = (event) => {
        const selection = event.api.getSelectedRows();
        const row = selection.length ? selection[0] : null;

        if (row) {
            this.setState({ selectedRow: row });
            // this.openTimesheetDetails();
        }
    };

    // https://stackoverflow.com/questions/44263350/count-number-of-selected-rows-in-ag-grid
    // Possibly use lodash dequeue?
    onRowSelected(e) {
        const rs = e.api.getSelectedRows();

        this.setState({
            rowsSelected: rs,
        });
    }

    handleSaveError = (err) => handleFormSaveError(this, err);

    populateState = async () => {
        const [chargeTypes, customers, gridPermissions] = await Promise.all([
            util.fetch.js(ApiRoutes.typeAheads.chargeTypesBillable()),
            util.fetch.js(ApiRoutes.typeAheads.customers()),
            util.fetch.get(ApiRoutes.billingRate.getGridPermissions()),
        ]);

        const gridOptions = createGridOptions(this);

        // https://www.ag-grid.com/documentation/javascript/row-selection/#checkbox-selection
        // One column must have "checkboxSelection" set to true.
        // Note: headerCheckboxSelection not available with infinite scroll.
        gridOptions.rowSelection = 'multiple';

        gridOptions.postProcessData = this.transformRowData;
        gridOptions.onRowSelected = this.onRowSelected;

        gridOptions.rowClassRules = {
            'ag-row-warning': (params) => (params.data ?? {}).hasOverride === 'Yes',
            'ag-row-danger': (params) => (params.data ?? {}).isEmergency === true,
        };

        gridOptions.components = {
            selectFilter: DataGridSelectFilter,
            selectFloatingFilter: DataGridSelectFloatingFilter,
            nameRenderer: LinkCellRenderer,
            iconRenderer: IconCellRenderer,
            variableLinkRenderer: VariableLinkCellRenderer,
        };
        gridOptions.onRowClicked = this.onRowClicked;

        const chargeTypesFilterParams = {
            suppressFilterButton: true,
            options: chargeTypes,
            optionsLabel: 'label',
            optionsValue: 'value',
        };
        const customersCentersFilterParams = {
            suppressFilterButton: true,
            options: customers,
            optionsLabel: 'label',
            optionsValue: 'value',
        };
        const currentFilterParams = {
            suppressFilterButton: true,
            options: this.state.current,
            optionsLabel: 'label',
            optionsValue: 'value',
        };

        gridOptions.columnDefs = [
            {
                headerName: '',
                valueGetter: 'node.id',
                sortable: false,
                hide: true,
                flex: 1,
                maxWidth: 35,
                cellRenderer: indexCellRenderer,
            },
            {
                colId: 'ChargeTypeId',
                headerName: 'Charge Type',
                field: 'chargeTypeName',
                sortable: true,
                flex: 2,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: chargeTypesFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: chargeTypesFilterParams,
                cellRenderer: gridPermissions.canEditChargeType
                    ? 'nameRenderer'
                    : null,
                cellRendererParams: {
                    to: (id) => `${AppNavPaths.ChargeType}/${id}`,
                    nameField: 'chargeTypeName',
                    idField: 'chargeTypeId',
                    title: 'View this Charge Type',
                },
            },
            {
                colId: 'CompanyId',
                headerName: 'Customer',
                field: 'companyName',
                sortable: true,
                flex: 2,
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: customersCentersFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: customersCentersFilterParams,
                cellRenderer: gridPermissions.canEditCompany
                    ? 'nameRenderer'
                    : null,
                cellRendererParams: {
                    to: (id) => `${AppNavPaths.Organization}/${id}`,
                    nameField: 'companyName',
                    idField: 'companyId',
                    title: 'View this Company',
                },
            },
            {
                colId: 'Rate',
                headerName: 'Rate',
                field: 'rate',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'OTRate',
                headerName: 'OT Rate',
                field: 'otRate',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'AltRate',
                headerName: 'Alt Rate',
                field: 'altRate',
                sortable: true,
                flex: 1.5,
                filter: 'agTextColumnFilter',
                filterParams: TextFilterDefaults,
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'MinBill',
                headerName: 'Min Bill',
                field: 'minBill',
                sortable: true,
                flex: 1.5,
            },
            {
                colId: 'MaxBill',
                headerName: 'Max Bill',
                field: 'maxBill',
                sortable: true,
                flex: 1.5,
            },
            {
                colId: 'EffectiveDate',
                headerName: 'Effective Date',
                field: 'effectiveDate',
                sortable: true,
                flex: 1.5,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
                // floatingFilterComponentParams: {
                //    suppressFilterButton: true,
                // }
            },
            {
                colId: 'ExpirationDate',
                headerName: 'Expiration Date',
                field: 'expirationDate',
                sortable: true,
                flex: 1.5,
                sort: { direction: 'asc', priority: 0 },
                filter: 'agDateColumnFilter',
                filterParams: DateFilterDefaults,
                // floatingFilterComponentParams: {
                //    suppressFilterButton: true,
                // }
            },
            {
                colId: 'Current',
                headerName: 'Status',
                field: 'current',
                sortable: true,
                flex: 1.5,
                tooltipField: 'status',
                filter: 'selectFilter',
                floatingFilter: true,
                filterParams: currentFilterParams,
                floatingFilterComponent: 'selectFloatingFilter',
                floatingFilterComponentParams: currentFilterParams,
            },
        ];

        const dataSource = createDataSource(
            ApiRoutes.billingRate.search(),
            gridOptions,
        );

        this.setState({
            loading: false,
            gridOptions,
            dataSource,
            isRejectingTimesheet: false,
        });
    };

    render() {
        const { rowData, gridOptions } = this.state;

        // TODO: Eliminate inline styles.

        return (
            <CommonContext.Consumer>
                {(value) => {
                    const { tenantSettings } = (value ?? {}).tenant ?? {};

                    if (this.state.loading || !tenantSettings) {
                        return (
                            <Progress />
                        );
                    }

                    if (
                        !tenantSettings.billingEnabled
                        || !tenantSettings.billingRatesEnabled
                    ) return <NotAuthorizedPage />;

                    return (
                        <PageWrap>
                            <PageHeading>
                                <FontAwesomeIcon
                                    icon={faTasks}
                                    className="mr-2 text-muted"
                                />
                                <span>Billing Rates</span>
                            </PageHeading>

                            <DataGridToolbar
                                entity="Billing"
                                gridApi={this.state.gridApi}
                                dataSource={this.state.dataSource}
                                hideAdd
                                hideExcelButton
                                gridOptions={this.state.gridOptions}
                                serverExport={{
                                    apiPath:
                                        ApiRoutes.billingRate.excelExport(),
                                    filePrefix: 'BillingRateSearch',
                                }}
                                serverExportDisabled={
                                    !!this.state.saving
                                    || !!this.state.loading
                                    || !!this.state.loadingData
                                }
                            />
                            {!!this.state.loadingReport && (
                                <CustomCircularProgress />
                            )}

                            {!this.state.loadingReport && (
                                <DataGrid
                                    domLayout="normal"
                                    rowData={rowData}
                                    gridOptions={gridOptions}
                                    gridStatus={this.state.gridStatus}
                                />
                            )}
                        </PageWrap>
                    );
                }}
            </CommonContext.Consumer>
        );
    }
}

export default withRouter(BillingRateIndex);

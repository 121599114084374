import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cls from 'classnames';
import NumericInput from '../common/forms/NumericInput';
import './BillingRateCountyGroup.scss';

export const BillingRateCountyGroup = (props) => {
    const {
        groupType, description, usStateName, current,
        rate = 0, otRate = 0, altRate = 0,
        entityName = '', onChange = () => {}, index
    } = props;

    return (
        <tr>
            <td className="badge-column">
                <span className="ml-2 badge badge-info">
                    {groupType === 'Zone' && 'Z'}
                    {groupType === 'Local' && 'L'}
                </span>
            </td>

            <td><span>{description} ({usStateName})</span></td>

            <td className="not-applicable">
                <div>
                    <label>
                        <input
                            name={`${entityName}usCountyGroupBillingRates.${index}.billingRate.current`}
                            checked={current}
                            value={current}
                            type="checkbox"
                            onChange={onChange}
                            hidden
                        />

                        <FontAwesomeIcon
                            icon={faBan}
                            className={cls(
                                'cursor-pointer icon-check',
                                {
                                    checked: !current,
                                },
                            )}
                        />
                    </label>
                </div>
            </td>

            <td className="numeric-input">
                <NumericInput
                    className="form-control form-control-sm"
                    name={`${entityName}usCountyGroupBillingRates.${index}.billingRate.rate`}
                    disabled={!current}
                    onChange={onChange}
                    value={rate || ''}
                    min={0}
                    max={500}
                    placeholder="Rate"
                    type="number"
                    required={current}
                    step={1}
                />
            </td>
            <td className="numeric-input">
                <NumericInput
                    className="form-control form-control-sm"
                    name={`${entityName}usCountyGroupBillingRates.${index}.billingRate.otRate`}
                    disabled={!current}
                    onChange={onChange}
                    value={otRate || ''}
                    min={0}
                    max={500}
                    placeholder="Rate"
                    type="number"
                    required={current}
                    step={1}
                />
            </td>
            <td className="numeric-input">
                <NumericInput
                    className="form-control form-control-sm"
                    name={`${entityName}usCountyGroupBillingRates.${index}.billingRate.altRate`}
                    disabled={!current}
                    onChange={onChange}
                    value={altRate || ''}
                    min={0}
                    max={500}
                    placeholder="Rate"
                    type="number"
                    required={current}
                    step={1}
                />
            </td>
        </tr>
    );
};
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import { Progress } from 'reactstrap';
import moment from 'moment';
import DataGridToolbar from '../common/dataGrid/DataGridToolbar';
import { PageHeading, PageWrap } from '../common/forms/FormElements';
import CommonContext, { ApiRoutes, agGridConstants } from '../Common';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    DateFilterDefaults,
    refreshDataGrid,
    TextFilterDefaults,
} from '../common/dataGrid/DataGrid';

class ActiveEmployeeListIndex extends React.Component {
    // bugbug: the index/edit components were borrowed from an outdated component,
    // so don't go patterning other pieces off of this
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            startDate: null,
            endDate: null,
        };
        this.refreshGrid = this.refreshGrid.bind(this);
    }

    componentDidMount() {
        return this.populateState(); 
    }

    getDateAddendum = () => {
        const { gridApi } = this.state;

        if (!gridApi) {
            return null;
        }

        const dateRangeFilter = gridApi.getFilterInstance('dateRange');

        if (!dateRangeFilter || !dateRangeFilter.appliedModel) {
            return null;
        }

        const { appliedModel } = dateRangeFilter;

        const { dateFrom } = appliedModel;
        const { dateTo } = appliedModel;

        const dateFromString = moment(dateFrom).format('YYYYMMDD');
        const dateToString = moment(dateTo).format('YYYYMMDD');

        return ` - ${dateFromString}-${dateToString}`;
    };

    async populateState() {
    // Get grid defaults and handlers
        const gridOptions = createGridOptions(this);

        const context = this;

        // Provide column definitions
        gridOptions.columnDefs = [
            {
                colId: 'lastName',
                sortable: false,
                headerName: 'Last Name',
                field: 'lastName',
                filter: agGridConstants.columnFilterTypes.text,
                floatingFilter: true,
                filterParams: TextFilterDefaults,
                sort: { direction: 'asc', priority: 0 },
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'firstName',
                sortable: false,
                headerName: 'First Name',
                field: 'firstName',
                filter: agGridConstants.columnFilterTypes.text,
                floatingFilter: true,
                filterParams: TextFilterDefaults,
                sort: { direction: 'asc', priority: 0 },
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'userName',
                sortable: false,
                headerName: 'User Name',
                field: 'userName',
                filter: agGridConstants.columnFilterTypes.text,
                floatingFilter: true,
                filterParams: TextFilterDefaults,
                sort: { direction: 'asc', priority: 0 },
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'dispatchCompanyName',
                sortable: false,
                headerName: 'Dispatch Company Name',
                field: 'dispatchCompanyName',
                filter: agGridConstants.columnFilterTypes.text,
                floatingFilter: true,
                filterParams: TextFilterDefaults,
                sort: { direction: 'asc', priority: 0 },
                floatingFilterComponentParams: {
                    suppressFilterButton: true,
                },
            },
            {
                colId: 'dateRange',
                sortable: false,
                headerName: 'Date Range',
                filterParams: DateFilterDefaults,
                filter: 'agDateColumnFilter',
                doNotExport: true,
            },
        ];

        gridOptions.onGridReady = (params) => {
            const firstOfMonth = moment().startOf('month').format('YYYY-MM-DD');
            const lastOfMonth = moment().endOf('month').format('YYYY-MM-DD');

            const dateRangeFilterComponent = params.api.getFilterInstance('dateRange');
            dateRangeFilterComponent.setModel({
                filterType: 'date',
                type: 'inRange',
                dateFrom: firstOfMonth,
                dateTo: lastOfMonth,
            });

            params.api.onFilterChanged();
            gridOptions.api = params.api;
            params.api.setDatasource(context.state.dataSource, gridOptions);

            this.setState({ gridApi: params.api });
        };

        // Create datasource.  Is reused and rebound on refresh from the state.
        const dataSource = createDataSource(
            ApiRoutes.activeEmployees.query(),
            gridOptions,
        );

        this.setState({
            loading: false,
            gridOptions,
            dataSource,
        });
    }

    refreshGrid = () => refreshDataGrid;

    render() {
        const {
            loading,
            rowData,
            gridOptions,
            gridStatus,
            gridApi,
            dataSource,
        } = this.state;

        if (loading) {
            return <Progress />;
        }

        const dateAddendum = this.getDateAddendum();

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon
                        icon={faUserCheck}
                        className="mr-2 text-muted"
                    />
                    <span>Active Employees</span>
                </PageHeading>
                <DataGridToolbar
                    entity="active_employee_list"
                    gridApi={gridApi}
                    gridOptions={gridOptions}
                    dataSource={dataSource}
                    hideAdd
                    hideExcelButton
                    appendToExportFileName={dateAddendum}
                    serverExport={{
                        apiPath: ApiRoutes.activeEmployees.export(),
                        filePrefix: `ActiveEmployees - ${dateAddendum}`,
                    }}
                />
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                    gridStatus={gridStatus}
                />
            </PageWrap>
        );
    }
}

export default withRouter(ActiveEmployeeListIndex);

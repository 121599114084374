import * as moment from 'moment';
import React from 'react';
import { Input } from 'reactstrap';

export const HourlyIncrements = {
    5: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
    10: ['00', '10', '20', '30', '40', '50'],
    15: ['00', '15', '30', '45'],
    30: ['00', '30'],
};

export const HoursValues12Hour = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

/**
 * Time picker, dropdown style.  Originally implemented to constrain input on timesheet entry.
 *
 * {value}: Saves value in 24 hour format, but constrains user input display to 12 hour.
 *
 * Added: RLC 5/3/21
 *
 * */
export default class TimeEntry extends React.Component {
    // RLC: Lookup an array of printed time values based on what increment is provided.
    getIncrementsOptions = (incrementValue) => {
        let increments = null;
        const options = [];
        switch (incrementValue) {
        case 0.083:
            increments = HourlyIncrements['5'];
            break;
        case 0.167:
            increments = HourlyIncrements['10'];
            break;
        case 0.25:
            increments = HourlyIncrements['15'];
            break;
        case 0.5:
            increments = HourlyIncrements['30'];
            break;
        default:
            increments = null;
            break;
        }

        if (increments !== null) {
            // RLC: using the increments arrays, get options values based on the antemeridian
            HoursValues12Hour.map((h) => increments.map((x) => options.push(`${h}:${x} AM`)));
            HoursValues12Hour.map((h) => increments.map((x) => options.push(`${h}:${x} PM`)));
            options.sort(
                (a, b) => moment(a, ['h:mm A']) - moment(b, ['h:mm A']),
            );
            return options;
        }
        return [];
    };

    render() {
        const {
            required, disabled, name, value, increment,
        } = this.props;

        const disableEmptyOption = !!(required || disabled);

        /// /TODO: Perhaps display the old html5 input control if increments are not provided or not constrained.
        return (
            <Input
                required={required}
                disabled={disabled}
                name={name}
                type="select"
                className="time-input-entry form-control-sm"
                value={
                    value
                        ? moment(value, ['HH:mm']).format('h:mm A')
                        : ''
                }
                onChange={this.props.onChange}
            >
                <option value="" disabled={disableEmptyOption}>--</option>
                {this.getIncrementsOptions(increment).map((o) => (
                    <option key={o} value={o}>{`${o}`}</option>
                ))}
            </Input>
        );
    }
}
import React from 'react';
import './DispatchBoard.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cls from 'classnames';
import { faToolbox, faTruck } from '@fortawesome/free-solid-svg-icons';
import { Droppable } from '@hello-pangea/dnd';
import EquipmentAssignmentCard from './EquipmentAssignmentCard';
import { DraggableResourceTypes } from './Dispatch';

export default class EquipmentJobAssignment extends React.Component {
    render() {
        const {
            job,
            jobIndex,
            day,
            requiredEquipmentType,
            dayAssignments,
            assignmentIndex,
            isDropZoneValid,
            dragging,
        } = { ...this.props };

        const dropId = `equipment_${jobIndex}_${day.getDay()}_${assignmentIndex}`;

        // Check if the drop zone is valid
        const isValid = isDropZoneValid && isDropZoneValid(DraggableResourceTypes.Equipment, requiredEquipmentType.description);

        // Allow interaction if there is already an assignment in the drop zone
        const hasAssignment = !!requiredEquipmentType.assignment;
          
        return (
            <div
                key={requiredEquipmentType.assignmentId}
                className="jobAssignment"
            >
                <Droppable
                    key={dropId}
                    droppableId={dropId}
                    isDropDisabled={hasAssignment || !isValid}
                    style={{ transform: 'none' }}
                >
                    {(provided, snapshot) => (
                        <div
                            className="assignment-placeholder-row"
                            style={{ height: '50px !important' }}
                        >
                            <span
                                style={{
                                    fontSize: '9px',
                                    fontWeight: 600,
                                    textTransform: 'uppercase',
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={requiredEquipmentType.isVehicle ? faTruck : faToolbox}
                                    className="mr-1"
                                />
                            </span>
                            <div
                                className={cls('jobAssignmentDropTarget', {
                                    dragging: snapshot.isDraggingOver, // Retain dragging styles
                                    show: !hasAssignment || snapshot.isDraggingOver, // Show additional visual cues
                                    invalidDropZone: !isValid && dragging,
                                    occupied: hasAssignment,
                                })}
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {
                                    // The assignment
                                    Boolean(hasAssignment) && (
                                        <EquipmentAssignmentCard
                                            removable
                                            compact
                                            innerRef={provided.innerRef}
                                            provided={provided}
                                            job={job}
                                            jobIndex={jobIndex}
                                            day={day}
                                            dayAssignments={dayAssignments}
                                            assignmentIndex={assignmentIndex}
                                            assignmentRequirements={
                                                requiredEquipmentType
                                            }
                                            requiredEquipment={
                                                requiredEquipmentType.assignment
                                                ?? null
                                            }
                                            onEquipmentAssignmentChange={
                                                this.props
                                                    .onEquipmentAssignmentChange
                                            }
                                            onRemoveAssignment={
                                                this.props.onRemoveAssignment
                                            }
                                        />
                                    )
                                }
                                {
                                    // The assignment placeholder
                                    !hasAssignment && (
                                        <div className="dropPlaceholderWrapper">
                                            <label className="dropPlaceholder">
                                                {
                                                    requiredEquipmentType.description
                                                }
                                            </label>
                                        </div>
                                    )
                                }
                                {provided.placeholder}
                            </div>
                        </div>
                    )}
                </Droppable>
            </div>
        );
    }
}

import React from 'react';
import { v4 as uuid } from 'uuid';
import { isEqual, mapValues } from 'lodash';
import {
    Button, FormGroup, Modal, ModalHeader,
} from 'reactstrap';
import CommonContext, { ApiRoutes } from '../Common';
import {
    FlexBetweenRow,
    FlexCenterRow,
    FlexColumnStart,
    FlexStartRow,
    FormDivider,
    FormGroupColumn,
    FormLabel,
    FormValidated,
    GroupedRow,
    onReactSelectChanged,
    SmallButton,
    SmallOutlineButton,
    SubHeading,
    toasty,
} from '../common/forms/FormElements';
import { util } from '../Util';
import { Address } from '../address/Address';
import { OrganizationContact } from '../organization/Organization';
import ValidatedSelect from '../common/forms/ValidatedSelect';
import OrganizationContactForm from '../organization/OrganizationContactForm';
import AddressFormNew from '../address/AddressFormNew';
import { CountyLabel } from '../uscounties/CountyLabel';
import { JobOverride } from './JobOverride';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';

export default class JobOverrideModal extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        this.addressFormRef = React.createRef();
        this.contactFormRef = React.createRef();

        this.state = {
            jobOverride: props.jobOverride,
            formValidated: false,
            loading: true,
            errors: {},
            validationMessage: '',
            show: false,
            showContactsForm: false,
            contactFormIsReadOnly: false,
            allowContactTypeEdit: false,
            selectedContact: null,
            saveDisabled: false,
            addJobDayOverrideFormValidated: false,
            job: props.jobOverride.job,

            weeks: [],
            usCounties: [],
            addressEditType: null, // from object const
            selectedAddressType: '',
            addressFormTitle: '',
            showTimesheetOverview: false,
            companyContactsOptions: [],
            tableRequired: {},
            meetingLocationRequired: {},
            jobLocationRequired: {},

            // Property on the job to update with the newly created/edited contact.
            // jobOverride.requestedById or formenId.
            selectedContactIdProp: null,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelectChanged = this.onSelectChanged.bind(this);
        this.onRemoveOverrideButtonClick = this.onRemoveOverrideButtonClick.bind(this);
    }

    componentDidMount() {
        return this.populateState(); 
    }

    
    // #region CONTACTS
    onAddContact = (prop) => {
        const { job } = { ...this.state };
        const { setFormOpened } = this.props;

        setFormOpened(true);
        this.contactFormRef.current.resetForm();
        this.setState({
            selectedContact: {
                ...new OrganizationContact(),
                id: null /* new record */,
                companyId:
                    job.contract
                        .companyId /* this will be used to attach the new contact to the location. */,
            },
            selectedContactIdProp: prop,
            showContactsForm: true,
            allowContactTypeEdit: true,
        });
    };

    onAddLocation = (type) => {
        const { jobOverride } = this.state;
        const selectedAddress = new Address();
        const addressFormTitle = `New ${
            type === 'meetingLocation' ? 'Meeting' : 'Job'
        } Location`;
        this.setState({
            selectedAddressType: type,
            addressFormTitle,
        });

        // dont show duplicate location if ones filled out already
        let show = true;
        if (jobOverride.meetingLocation || jobOverride.jobLocation) show = false;

        this.addressFormRef.current.open(
            selectedAddress,
            addressFormTitle,
            show,
            `Same as ${
                type === 'meetingLocation' ? 'Job Location' : 'Meeting Location'
            }`,
        );
    };

    onChange = async function (e) {
        if (!e || !e.target || !e.target.name) {
            return;
        }

        const changedPropertyName = e.target.name.split('.').pop();

        const { jobOverride } = this.state;

        jobOverride[changedPropertyName] = e.target.value;
        this.setState({ jobOverride });
    };

    isButtonDisabled = () => {
        const { jobOverride } = this.state;
        const overrideDate = moment(jobOverride.overrideDate).toDate();
        const baseOverride = { ...new JobOverride(overrideDate) };
        const currentOverride = mapValues(jobOverride, (value) => typeof value === 'undefined' ? null : value);
        delete currentOverride.job;
        currentOverride.id = null;
        currentOverride.jobId = 0;
        currentOverride.overrideDate = moment(currentOverride.overrideDate).toDate();
 
        currentOverride.weekOfDate = '';
        currentOverride.workOrderNumber = currentOverride.workOrderNumber === null ? '' : currentOverride.workOrderNumber;
        currentOverride.notes = currentOverride.notes === null ? '' : currentOverride.notes;
        currentOverride.startTime = currentOverride.startTime === '' ? null : currentOverride.startTime;

        return isEqual(currentOverride, baseOverride);
    };

    onClose = () => {
        const { onClose, setFormOpened } = this.props;

        this.resetForm();
        setFormOpened(false);

        onClose();
    };

    onEditContact = async (prop) => {
        const { job } = { ...this.state };
        const selectedContact = await util.fetch.js(
            ApiRoutes.locationContact.byId(job[prop]),
        );
        this.contactFormRef.current.resetForm();

        await this.setState({
            selectedContact: { ...selectedContact },
            showContactsForm: true,
            selectedContactIdProp: prop,
            allowContactTypeEdit: false,
        });
    };

    onEditLocation = (type) => {
        const { jobOverride } = { ...this.state };
        const selectedAddress = { ...jobOverride[type] };
        const addressFormTitle = `Edit ${
            type === 'meetingLocation' ? 'Meeting' : 'Job'
        } Location`;

        this.setState({
            selectedAddress,
            selectedAddressType: type,
            addressFormTitle,
        });
        this.addressFormRef.current.open(selectedAddress, addressFormTitle);
    };

    onOrganizationContactSaved = async () => {
        await this.setState({ contactFormIsReadOnly: true });

        const { selectedContact, selectedContactIdProp, job } = {
            ...this.state,
        };
        const isNew = parseInt(selectedContact.id ?? 0) <= 0;
        const result = await util.fetch.andGetResponse(
            isNew ? util.fetch.types.post : util.fetch.types.put,
            isNew
                ? ApiRoutes.locationContact.create()
                : ApiRoutes.locationContact.update(selectedContact.id),
            { ...selectedContact },
            'Error Saving Contact',
        );

        if (result) {
            toasty.success('Contact saved.');

            // Make the contact the selected one - add returns just the Id, update returns object with id property.
            job[selectedContactIdProp] = result.id ?? result;

            // Refresh the contacts.
            const newOptions = await this.getCompanyContactsOptions(
                job.contract.companyId,
            );

            // Update state.
            this.setState({
                companyContactsOptions: [...newOptions],
                job: { ...job },
                showContactsForm: false,
                saving: false,
            });
        } else {
            this.setState({ saving: false });
        }

        await this.setState({ contactFormIsReadOnly: false });
    };
    // #endregion

    onRemoveOverrideButtonClick = () => {
        const { onClose, setFormOpened, jobOverride } = this.props;

        setFormOpened(false);

        if (jobOverride.id) {
            onClose({ ...jobOverride, isDeleted: true });
        } else {
            onClose();
        }
    };

    onSaveAddress = (address) => {
        const {
            jobOverride,
            selectedAddressType,
        } = this.state;

        jobOverride[selectedAddressType] = { ...address };

        if (address.duplicate) {
            // if the other is blank fill it in.
            if (selectedAddressType === 'jobLocation' && !jobOverride.meetingLocation) {
                const mAddress = util.object.clone(address);
                mAddress.id = uuid();

                jobOverride.meetingLocation = mAddress;
            }

            if (selectedAddressType === 'meetingLocation' && !jobOverride.jobLocation) {
                const jAddress = util.object.clone(address);
                jAddress.id = uuid();

                jobOverride.jobLocation = jAddress;
            }
        }

        this.setState({
            jobOverride,
        });
    };

    onSelectChanged = onReactSelectChanged;

    getCompanyContactsOptions = async (
        companyId,
        constrainContactTypes,
        requestedById,
        foremanId,
    ) => {
        const companyContacts = await util.fetch.js(
            ApiRoutes.company.contacts(companyId),
        );

        // TODO: Define and filter server-side.
        const descs = OrganizationContactForm.filteredContactTypes;

        const contactsFiltered = companyContacts.filter((c) => {
            if (!c.isActive) return false;

            // Automatically include if we're not constraining contact types.
            // Include if the contact was already set as the "requested by" or foreman person.
            const include = !constrainContactTypes
                || c.id === requestedById
                || c.id === foremanId
                || (c.locationContactTypes
                    && c.locationContactTypes.some((t) => descs.some((d) => t.description === d)));

            return include;
        });

        const contactsMapped = contactsFiltered.map((x) => ({
            label: x.contactName,
            value: x.id,
        }));

        // const contactsMapped = [...companyContacts.filter((c) => c.isActive).map(x => { return { label: x.contactName, value: x.id } })];
        return contactsMapped;
    };

    getCountyName = (location) => {
        if (!location || !location.usCountyId) {
            return '';
        }

        const { usCounties } = this.state;

        const county = usCounties.find((c) => c.id == location.usCountyId);

        return county ? `(${county.name})` : '';
    };

    getDayHasAssignment = () => {
        const { job, jobOverride } = this.state;
        const indexName = `${jobOverride.overrideDay.toLowerCase()}HasAssignment`;

        return !!job.resources?.find((resource) => resource[indexName]);
    };

    handleSubmit = () => {
        const { jobOverride } = this.state;
        const { onClose, setFormOpened, setOverride } = this.props;

        // Revert uuid
        if (jobOverride.meetingLocation) {
            jobOverride.meetingLocation.id = jobOverride.meetingLocation.id.constructor === String
                ? null
                : jobOverride.meetingLocation.id;
        }

        if (jobOverride.jobLocation) {
            jobOverride.jobLocation.id = jobOverride.jobLocation.id.constructor === String
                ? null
                : jobOverride.jobLocation.id;
        }

        this.resetForm();
        setFormOpened(false);

        setOverride(true);
        onClose(jobOverride);
    };

    populateState = async () => {
        const { showTimesheetOverview, job } = { ...this.state };
        const { constrainContactTypes } = this.props;

        const [
            usCounties,
            tenantSettings,
        ] = await Promise.all([
            util.fetch.js(ApiRoutes.USCounties.all()),
            util.fetch.js(ApiRoutes.tenant.settings()),
        ]);

        const companyContactsOptions = await this.getCompanyContactsOptions(
            job.contract.companyId,
            constrainContactTypes,
            job.requestedById,
            job.foremenId,
        );

        this.setState((state) => ({
            loading: false,
            job: { ...job },
            showTimesheetOverview,
            companyContactsOptions: [...companyContactsOptions],
            usCounties,
            tenantSettings,
        }));
    };

    resetForm = () => this.setState({ formValidated: false });

    getCounty = (location) => {
        if (!location || !location.usCountyId) {
            return null;
        }

        const { usCounties } = this.state;

        const county = usCounties.find((c) => c.id == location.usCountyId);

        return county || null;
    };

    render() {
        const {
            jobOverride,
            addJobDayOverrideFormValidated,
            companyContactsOptions,
            showContactsForm,
            contactFormIsReadOnly,
            allowContactTypeEdit,
            selectedContact,
            job,
        } = this.state;

        const { open, isReadOnly } = this.props;

        return (
            <Modal
                className="full-screen-modal"
                backdrop="static"
                keyboard={false}
                isOpen={open}
            >
                {Boolean(open) && (
                    <>
                        <ModalHeader>
                            <FlexBetweenRow className="w-100">
                                <span>
                                    {isReadOnly ? 'View' : 'Edit'}
                                    {' '}
                                    Details for
                                    {' '}

                                    {jobOverride.overrideDay}
                                </span>

                                <Button
                                    color="link"
                                    onClick={this.onClose}
                                >
                                    <FontAwesomeIcon icon={faTimes} size="lg" />
                                </Button>
                            </FlexBetweenRow>
                        </ModalHeader>

                        <FormValidated
                            className="modal-body add-self-dispatch-time-form d-flex flex-column h-100 min-height-0"
                            id="addJobDayOverrideForm"
                            name="addJobDayOverrideForm"
                            ref={this.addTimeFormRef}
                            onSubmit={this.handleSubmit}
                            setIsValidated={(validated) => this.setState({ addJobDayOverrideFormValidated: validated })}
                            isValidated={addJobDayOverrideFormValidated}
                        >
                            <SubHeading>Setup</SubHeading>
                            <GroupedRow>
                                <FormGroupColumn>
                                    <FormGroup>
                                        <div className="d-flex flex-row flex-nowrap align-items-baseline justify-content-start">
                                            <label
                                                htmlFor="requestedBy"
                                                className="control-label"
                                            >
                                                Requested By
                                            </label>
                                            {!job.hasAssignments && !isReadOnly && (
                                                <SmallButton
                                                    color="outline-primary"
                                                    className="site-button-small mr-2 ml-auto"
                                                    onClick={() => this.onAddContact(
                                                        'requestedById',
                                                    )}
                                                >   
                                                    <FontAwesomeIcon icon={faPlusCircle} className="mr-2" size="md" />
                                                    Add Contact
                                                </SmallButton>
                                            )}
                                        </div>
                                        <ValidatedSelect
                                            id="requestedBy"
                                            name="jobOverride.requestedById"
                                            options={companyContactsOptions}
                                            value={
                                                (companyContactsOptions ?? []).find(
                                                    (x) => x.value
                                                            === jobOverride.requestedById,
                                                ) ?? ''
                                            }
                                            onChange={this.onSelectChanged}
                                            isDisabled={isReadOnly}
                                        />
                                        {!!jobOverride?.requestedById && (
                                            <div className="text-right pr-3">
                                                <SmallButton
                                                    color="link"
                                                    className="site-link text-right"
                                                    onClick={() => this.onEditContact(
                                                        'requestedById',
                                                    )}
                                                >
                                                    Edit Requestor Details
                                                </SmallButton>
                                            </div>
                                        )}
                                    </FormGroup>
                                </FormGroupColumn>
                                <FormGroupColumn>
                                    <FormGroup>
                                        <div className="d-flex flex-row flex-nowrap align-items-baseline justify-content-start">
                                            <FormLabel
                                                htmlFor="foremenId"
                                                className="control-label"
                                                text="Foreman"
                                            />
                                            {!job.hasAssignments && !isReadOnly && (
                                                <SmallButton
                                                    color="outline-primary"
                                                    className="site-button-small mr-2 ml-auto"
                                                    onClick={() => this.onAddContact(
                                                        'foremenId',
                                                    )}
                                                >
                                                    <FontAwesomeIcon icon={faPlusCircle} className="mr-2" size="md" />
                                                    Add Contact
                                                </SmallButton>
                                            )}
                                        </div>
                                        <ValidatedSelect
                                            id="foremen"
                                            name="jobOverride.foremenId"
                                            options={companyContactsOptions}
                                            value={
                                                (companyContactsOptions ?? []).find(
                                                    (x) => x.value === jobOverride.foremenId,
                                                ) ?? ''
                                            }
                                            onChange={this.onSelectChanged}
                                            isDisabled={isReadOnly}
                                        />
                                        {!!jobOverride?.foremenId && (
                                            <div className="w-100 text-right pr-3">
                                                <SmallButton
                                                    color="link"
                                                    className="site-link text-right"
                                                    onClick={() => this.onEditContact(
                                                        'foremenId',
                                                    )}
                                                >
                                                    Edit Foreman Details
                                                </SmallButton>
                                            </div>
                                        )}
                                    </FormGroup>
                                </FormGroupColumn>
                            </GroupedRow>
                            <FormDivider />
                            <GroupedRow>
                                <FormGroupColumn>
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="startTime"
                                            text="Start Time"
                                        />
                                        <input
                                            id="startTime"
                                            type="time"
                                            className="form-control"
                                            name="jobOverride.startTime"
                                            defaultValue={jobOverride.startTime ?? ''}
                                            onChange={this.onChange}
                                            readOnly={isReadOnly}
                                        />
                                    </FormGroup>
                                </FormGroupColumn>
                                {job.contract.workOrderTypeId !== 3 && (
                                    <FormGroupColumn>
                                        <FormGroup>
                                            <FormLabel
                                                htmlFor="workOrderNumber"
                                                text="Work Order"
                                            />
                                            <input
                                                id="workOrderNumber"
                                                name="jobOverride.workOrderNumber"
                                                className="form-control"
                                                defaultValue={
                                                    jobOverride.workOrderNumber ?? ''
                                                }
                                                onChange={this.onChange}
                                                readOnly={isReadOnly}
                                            />
                                        </FormGroup>
                                    </FormGroupColumn>
                                )}
                            </GroupedRow>

                            <GroupedRow>
                                <FormGroupColumn>
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="meetingLocation"
                                            text="Meeting Location"
                                        />
                                        {jobOverride.meetingLocation ? (
                                            <FlexStartRow>
                                                <SmallOutlineButton
                                                    onClick={() => {
                                                        this.onEditLocation(
                                                            'meetingLocation',
                                                        );
                                                    }}
                                                    text="Edit"
                                                    disabled={isReadOnly}
                                                />
                                                
                                                <span className="form-control-plaintext">
                                                    {jobOverride.meetingLocation.address1}
                                                    {' '}

                                                    {jobOverride.meetingLocation.city}
                                                    {', '}
                                            
                                                    {jobOverride.meetingLocation.state}
                                                    {', '}
                                            
                                                    {jobOverride.meetingLocation.zip}
                                                    {' '}

                                                    (<CountyLabel {...this.getCounty(jobOverride.meetingLocation) || {}} />)
                                                </span>
                                            </FlexStartRow>
                                        ) : (
                                            <Button
                                                type="button"
                                                color="secondary"
                                                size="sm"
                                                disabled={isReadOnly}
                                                onClick={() => this.onAddLocation(
                                                    'meetingLocation',
                                                )}
                                            >
                      Add Meeting Location
                                            </Button>
                                        )}
                                    </FormGroup>
                                </FormGroupColumn>
                                <FormGroupColumn>
                                    <FormGroup>
                                        <FormLabel
                                            htmlFor="jobLocation"
                                            text="Job Location"
                                        />
                                        {jobOverride.jobLocation ? (
                                            <FlexStartRow>
                                                <SmallOutlineButton
                                                    onClick={() => {
                                                        this.onEditLocation(
                                                            'jobLocation',
                                                        );
                                                    }}
                                                    text="Edit"
                                                    disabled={isReadOnly}
                                                />

                                                <span className="form-control-plaintext">
                                                    {jobOverride.jobLocation.address1}
                                                    {' '}

                                                    {jobOverride.jobLocation.city}
                                                    {', '}
                                            
                                                    {jobOverride.jobLocation.state}
                                                    {', '}
                                            
                                                    {jobOverride.jobLocation.zip}
                                                    {' '}

                                                    (<CountyLabel {...this.getCounty(jobOverride.jobLocation) || {}} />)
                                                </span>
                                            </FlexStartRow>
                                        ) : (
                                            <Button
                                                type="button"
                                                color="secondary"
                                                size="sm"
                                                disabled={isReadOnly}
                                                onClick={() => {
                                                    this.onAddLocation(
                                                        'jobLocation',
                                                    );
                                                }}
                                            >
                      Add Job Location
                                            </Button>
                                        )}
                                    </FormGroup>
                                </FormGroupColumn>
                            </GroupedRow>

                            <GroupedRow>
                                <div className="col-md-auto col-sm-12 col-lg-auto col-xl-auto w-100">
                                    <FormGroup className="w-100">
                                        <FormLabel htmlFor="notes" text="Notes" />
                                        <textarea
                                            id="notes"
                                            name="jobOverride.notes"
                                            className="form-control"
                                            defaultValue={jobOverride.notes ?? ''}
                                            onChange={this.onChange}
                                            placeholder="Enter notes to field staff here."
                                            type="text"
                                            maxLength="500"
                                            rows="5"
                                            readOnly={isReadOnly}
                                        />
                                    </FormGroup>
                                </div>
                            </GroupedRow>

                            {this.getDayHasAssignment() && (
                                <GroupedRow>
                                    <div className="col-md-auto col-sm-12 col-lg-auto col-xl-auto w-100">
                                        <FormGroup className="w-100">
                                            <FormLabel htmlFor="notes" text="Reason for this change?" required />
                                            <textarea
                                                id="notes"
                                                name="jobOverride.reason"
                                                className="form-control"
                                                defaultValue={jobOverride.reason ?? ''}
                                                onChange={this.onChange}
                                                placeholder="Enter a reason for making this change. Assigned crew will be notified that changes have been made."
                                                type="text"
                                                maxLength="500"
                                                rows="5"
                                                readOnly={isReadOnly}
                                                required
                                            />
                                            <small className="invalid-feedback text-danger">
                    A reason is required.
                                            </small>
                                        </FormGroup>
                                    </div>
                                </GroupedRow>
                            )}

                            <FlexColumnStart className="w-100">
                                {isReadOnly ? (
                                    <FlexCenterRow className="w-100">
                                        <Button
                                            type="button"
                                            className="w-100 d-flex flex-row flex-nowrap align-items-center justify-content-center"
                                            color="primary"
                                            outline
                                        >
                    Close
                                        </Button>
                                    </FlexCenterRow>
                                ) : (
                                    <>
                                        <FlexCenterRow className="w-100">
                                            <Button
                                                type="submit"
                                                name="addJobDayOverrideForm"
                                                className="w-100 d-flex flex-row flex-nowrap align-items-center justify-content-center"
                                                color="primary" disabled={this.isButtonDisabled()} 
                                            >
                                                Save Override
                                            </Button>
                                        </FlexCenterRow>

                                        {Boolean(jobOverride.jobId) && (
                                            <FlexCenterRow className="w-100">
                                                <Button
                                                    title="Click to remove the override for this day"
                                                    color="outline-danger"
                                                    className="mt-3 cursor-pointer w-100"
                                                    onClick={this.onRemoveOverrideButtonClick}
                                                >
                                                    <FontAwesomeIcon icon={faTrash} className="mr-1" />
                                                    Delete Override
                                                </Button>
                                            </FlexCenterRow>
                                        )}
                                    </>
                                )}
                            </FlexColumnStart>
                        </FormValidated>

                        <AddressFormNew
                            id="jobAddressForm"
                            ref={this.addressFormRef}
                            contractUsesPrevailingWage={job.contract.prevailingWage}
                            onSaveCallback={(address) => this.onSaveAddress(address)}
                        />
                        <OrganizationContactForm
                            ref={this.contactFormRef}
                            show={showContactsForm}
                            readOnly={contactFormIsReadOnly}
                            allowContactTypeEdit={allowContactTypeEdit}
                            contact={selectedContact}
                            onClose={() => {
                                this.setState({ showContactsForm: false });
                            }}
                            onChange={(e) => {
                                const { selectedContact } = { ...this.state };
                                selectedContact[e.target.name] = e.target.value;
                                this.setState({
                                    newContact: { ...selectedContact },
                                });
                            }}
                            onCheckedChanged={(e) => {
                                const { selectedContact } = { ...this.state };
                                selectedContact[e.target.name] = e.target.checked;
                                this.setState({
                                    newContact: { ...selectedContact },
                                });
                            }}
                            onContactTypeChanged={(items) => {
                                const { selectedContact } = this.state;
                                selectedContact.contactTypes = items.map(
                                    (x) => x.value,
                                );
                                this.setState({
                                    newContact: { ...selectedContact },
                                });
                            }}
                            onSaveCallback={this.onOrganizationContactSaved}
                        />
                    </>
                )}
            </Modal>
        );
    }
}

import React from 'react';
import {
    faFileInvoiceDollar,
    faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Row, Button } from 'reactstrap';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { throttle } from 'lodash-es';
import {
    booleanCellRenderer,
    createClientSideGridOptions,
} from '../common/dataGrid/DataGrid';
import CommonContext, { ApiRoutes } from '../Common';
import { util } from '../Util';
import SlideForm from '../common/forms/SlideForm';
import { SmallButton, toasty } from '../common/forms/FormElements';

export default class ChargeTypeSelect extends React.Component {
    static contextType = CommonContext;

    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            customerId: '',
            chargeTypes: [],
            loading: true,
            omissions: [],
            refreshing: false,
            rowData: [],
            selectedRows: [],
            show: false,
        };
        this.yesNoCellRenderer = this.yesNoCellRenderer.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.setupGrid = this.setupGrid.bind(this);
        this.refreshChargeTypes = throttle(this.refreshChargeTypes, 2000);
    }

    componentDidMount() {
        this.populateState();
    }

    onAdd = () => {
        const { selectedRows } = { ...this.state };
        this.setState({ show: false }, () => {
            this.props.onAddCallback(selectedRows);
        });
    };

    onClose = () => {
        this.setState({ show: false, formValidated: false });
    };

    onSave = () => {};

    onSelectionChanged = (event) => {
        const selections = event.api.getSelectedRows() ?? [];
        this.setState({ selectedRows: selections });
    };

    onSubmit = () => {};

    getChargeTypes = async (id, omissions) => {
        const { billableOnly } = { ...this.props };

        let chargeTypes = [];
        if (billableOnly) {
            chargeTypes = await util.fetch.js(
                ApiRoutes.chargeType.byCustomerBillable(id),
            );
        } else {
            chargeTypes = await util.fetch.js(
                ApiRoutes.chargeType.byCustomer(id),
            );
        }

        chargeTypes = chargeTypes.filter((ct) => !omissions.includes(ct.id)) ?? [];

        this.state.gridApi.setRowData(chargeTypes);
        this.state.gridApi.refreshCells();
        this.setState({ selectedRows: [], refreshing: false, loading: false });
    };

    setupGrid = async () => {
        const gridOptions = createClientSideGridOptions(this);

        gridOptions.defaultColDef = { sortable: true, flex: 1 };
        gridOptions.headerHeight = 18;
        gridOptions.rowMultiSelectWithClick = true;
        gridOptions.overlayNoRowsTemplate = 'No charge types to display. You\'ve either selected all the available charge types, or no charge types were found.';

        gridOptions.columnDefs = [
            {
                colId: 'Description',
                sortable: true,
                headerName: 'Description',
                field: 'description',
                sort: { direction: 'asc', priority: 0 },
                cellRenderer: (params) => {
                    const badge = params.data.isException
                        ? '<span className="exception-badge badge badge-warning ml-2">Exception</span>'
                        : '';
                    return (
                        <div className="d-flex flex-row flex-nowrap align-items-center">
                            {params.data.description}
                            {badge}
                        </div>
                    );
                },
            },
            {
                width: 100,
                flex: 0,
                colId: 'Units',
                sortable: true,
                headerName: 'Units',
                field: 'unitsName',
            },
            {
                sortable: false,
                headerName: 'Group',
                field: 'appliesToGroup',
            },
            {
                sortable: false,
                headerName: 'Employees',
                field: 'appliesToUsers',
            },
            {
                sortable: false,
                headerName: 'Equipment',
                field: 'appliesToEquipment',
            },
            {
                width: 100,
                flex: 0,
                sortable: false,
                headerName: 'Billable?',
                field: 'isBillable',
                cellRenderer: this.yesNoCellRenderer,
            },
        ];

        return { gridOptions };
    };

    open = async (id, omissions) => {
        const customerId = id;
        await this.getChargeTypes(customerId, omissions);
        this.setState({
            show: true,
            customerId,
            omissions: [...omissions],
        });
    };

    async populateState() {
        const { gridOptions } = await this.setupGrid();

        gridOptions.rowData = [];

        this.setState({
            loading: false,
            gridOptions,
        });
    }

    refreshChargeTypes = async () => {
        const { customerId, omissions } = { ...this.state };
        await this.getChargeTypes(customerId, omissions);
        toasty.success(
            'Data refreshed',
            'Charge type data reloaded successfully.',
        );
    };

    yesNoCellRenderer = booleanCellRenderer;

    render() {
        const { gridOptions, formValidated, selectedRows } = this.state;
        const selectionCount = (selectedRows ?? []).length
            ? ` (${selectedRows.length})`
            : '';

        return (
            <SlideForm
                size="col-xl-6 col-md-12 col-sm-12"
                loading={this.state.loading}
                show={this.state.show}
                id={this.props.id}
                formIcon={faFileInvoiceDollar}
                formTitle="Add Charge Types to Contract"
                ref={this.formRef}
                setIsValidated={(value) => {
                    this.setState({ formValidated: value });
                }}
                isValidated={formValidated}
                onClose={this.onClose}
            >
                <div className="w-100 d-flex flex-row align-items-center pt-1 pb-1">
                    <div className="flex-fill d-flex flex-row align-items-center">
                        <FontAwesomeIcon
                            icon={faQuestionCircle}
                            className="mr-1"
                        />
                        <small>
              You may multiselect by clicking on individual rows.
                        </small>
                    </div>
                    <div className="flex-fill d-flex flex-row align-items-center justify-content-end">
                        <SmallButton
                            disabled={!!this.state.refreshing}
                            onClick={this.refreshChargeTypes}
                        >
                            <i className="fa fa-sync-alt fa-md mr-2" />
              Refresh Charge Type Data
                        </SmallButton>
                    </div>
                </div>
                <div className="pt-0 flex-fill ag-theme-alpine datatable">
                    <AgGridReact gridOptions={gridOptions} />
                </div>
                <Row className="d-flex flex-row justify-content-end pt-2 pl-0 pr-0 ml-0 mr-0 text-right w-100">
                    <Button
                        disabled={!(this.state.selectedRows ?? []).length}
                        size="sm"
                        type="button"
                        color="primary"
                        onClick={this.onAdd}
                    >
                        {`Add Selected${selectionCount}`}
                    </Button>
                </Row>
            </SlideForm>
        );
    }
}

import React from 'react';
import { Button, ButtonGroup, Badge, FormGroup } from 'reactstrap';
import moment from 'moment';
import { FormLabel } from '../common/forms/FormElements';

export default class DispatchEquipmentFilter extends React.Component {
    getAssignedEquipmentCount = () => {
        const { selectedWeekRangeDays, allJobs } = this.props;

        if (!allJobs || !allJobs.length) {
            return 0;
        }

        const selectedDate = selectedWeekRangeDays[0];

        let assignmentCount = 0;

        allJobs.forEach((j) => Object.values(j.assignments).forEach((a) => {
            if (
                a.isCancelled
                    || !moment(selectedDate).isSame(moment(a.date), 'day')
                    || !a.equipment
            ) {
                return;
            }

            Object.values(a.equipment).forEach((equipmentOpening) => {
                if (equipmentOpening.assignment) {
                    assignmentCount++;
                }
            });
        }));

        return assignmentCount;
    };

    render() {
        const {
            selectedWeekRangeDays,
            loading,
            equipmentLoading,
            resourcesLoading,
            equipment,
            onUnassignedEquipmentClick,
            onlyShowUnassignedEquipment,
            equipmentTabActive,
            tenantSettings,
        } = this.props;

        if (
            !equipmentTabActive
            || loading
            || equipmentLoading
            || resourcesLoading
            || !tenantSettings
            || !tenantSettings.dispatchStatusFilterEnabled
        ) {
            return null;
        }

        if (
            !selectedWeekRangeDays
            || selectedWeekRangeDays.length != 1
            || !equipment
            || !equipment.rows
        ) {
            return <div className="flex-spacing" />;
        }

        const assignedCount = this.getAssignedEquipmentCount();

        const unassignedCount = equipment.rows.length - assignedCount;

        const unassignedBadgeColor = unassignedCount <= 0 ? 'success' : 'danger';
        const unassignedButtonColor = onlyShowUnassignedEquipment
            ? 'primary'
            : 'secondary';

        return (
            <div className="status-selection ml-3">
                <FormGroup>
                    <FormLabel
                        htmlFor="dispatchEquipmentFilter"
                        text="Status"
                    />
                    <ButtonGroup id="dispatchEquipmentFilter" className="dispatchEquipmentFilter" size="sm">
                        <Button
                            type="button"
                            onClick={onUnassignedEquipmentClick}
                            active={onlyShowUnassignedEquipment}
                            color={unassignedButtonColor}
                            outline
                        >
              Unassigned
                            {' '}
                            <Badge
                                color={unassignedBadgeColor}
                                className="badge-bigger"
                            >
                                {unassignedCount}
                            </Badge>
                        </Button>
                    </ButtonGroup>
                </FormGroup>
            </div>
        );
    }
}

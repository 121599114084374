import React from 'react';
import Select from 'react-select';
import { Button, Spinner } from 'reactstrap';
import { ApiRoutes } from '../Common';
import { util } from '../Util';
import { FormLabel } from '../common/forms/FormElements';

export default class CompanySubSelects extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            companies: null,
            companiesLoading: true,
            selectedCompanies: null,
            currentSubs: null,
            selectedSubs: null,
        };

        this.companyChanged = this.companyChanged.bind(this);
        this.subcontractorChanged = this.subcontractorChanged.bind(this);
        this.getOptionValue = this.getOptionValue.bind(this);
    }

    componentDidMount() {
        return this.populateState(); 
    }

    componentWillUnmount() {
        return this.setState = (state, callback) => {

        };
    }

    getOption(item) {
        const ret = { value: item.id, label: item.companyName.trim() };

        return ret;
    }

    getOptionValue(opt) {
    // Value must always be a string.  Options are objects with label/value properties, values are strings.
        return opt.value;
    }

    getOptions(companies) {
        const opts = companies.map((company) => {
            const c = this.getOption(company);
            c.subcontractors = company.subcontractors.map((sub) => this.getOption(sub));
            return c;
        });

        return opts;
    }

    getUpdatedSubOptions(allValidSubs) {
        if (!allValidSubs) return null;

        // We want the distinct list of subs by id.
        // There is some overlap in subcontractors among companies.
        const idSet = new Set();

        const finalSubs = allValidSubs.filter((sub) => {
            const id = sub.value;
            const inSet = idSet.has(id);
            idSet.add(id);
            return !inSet;
        });

        return finalSubs;
    }

    // Can use have an additional parameter (like "e") and use e.action to get the name of the event.
    companyChanged(opts) {
    // Opts will be null when you remove the last option.
        this.setState({ selectedCompanies: opts });

        const subs = opts
            ? opts.flatMap((x) => x.subcontractors).sort(this.sortOpts)
            : null;

        const currentSubs = this.getUpdatedSubOptions(subs);
        this.setState({ currentSubs });
        if (this.props.onCompaniesChange) this.props.onCompaniesChange(opts);

        this.pruneSelectedSubs(currentSubs);
    }

    populateState = async () => {
        this.props.onLoading(true);

        const companiesRaw = await util.fetch.js(
            ApiRoutes.chargeView.hoursReportCompanies(),
        );

        const companyOpts = this.getOptions(companiesRaw).sort(this.sortOpts);

        this.setState({ companies: companyOpts, companiesLoading: false });

        this.props.onLoading(false);
    };

    pruneSelectedSubs(currentSubs) {
        const { selectedSubs } = this.state;

        let selSubs = null;

        // If there are no currentSubs or no selectedSubs, selectedSubs must be null.
        // If we have no currentSubs and we do have selectedSubs, we'd need to clear them all, in other words.
        // And if we didn't have selectedSubs in the first place, there's really no change.
        if (selectedSubs && currentSubs) {
            const currentIds = new Set(currentSubs.map((c) => c.value));

            // Remove any selectedSubs that are not in currentSubs.
            selSubs = selectedSubs.filter((sub) => currentIds.has(sub.value));
        }

        this.setState({ selectedSubs: selSubs });
        if (this.props.onSubcontractorsChange) this.props.onSubcontractorsChange(selSubs);
    }

    // Assumes label is non-null string.
    sortOpts(x, y) {
        const ret = x.label.localeCompare(y.label);
        return ret;
    }

    subcontractorChanged(opts) {
        let selSubs = null;
        if (opts) selSubs = opts.sort(this.sortOpts);

        this.setState({ selectedSubs: selSubs });
        if (this.props.onSubcontractorsChange) this.props.onSubcontractorsChange(selSubs);
    }

    render() {
        const {
            companies,
            selectedCompanies,
            currentSubs,
            selectedSubs,
            companiesLoading,
        } = this.state;

        const showSubcontractors = Boolean(
            !companiesLoading
                && selectedCompanies
                && selectedCompanies.length > 0,
        );

        return (
            <>
                <div className="col">
                    <FormLabel
                        htmlFor="companySubCompanies"
                        text="Company"
                    />
                    {!companiesLoading && (
                        <>
                            <Select
                                id="companySubCompanies"
                                name="companySubCompanies"
                                isMulti
                                options={companies}
                                value={selectedCompanies}
                                onChange={this.companyChanged}
                                getOptionValue={this.getOptionValue}
                            />
                            <Button
                                size="sm"
                                color="link"
                                className={`w-auto float-right site-link${
                                    (companies ?? []).length === 0
                                        ? 'disabled'
                                        : ''
                                } `}
                                onClick={() => this.companyChanged(this.state.companies)}
                            >
                                Select All
                            </Button>
                        </>
                    )}

                    {companiesLoading && (
                        <Spinner type="border" tag="div" size="sm" aria-hidden="true" />
                    )}
                </div>

                <div className="col">
                    <FormLabel
                        htmlFor="companySubSubcontractors"
                        text="Subcontractor"
                    />
                    {showSubcontractors && (
                        <>
                            <Select
                                id="companySubSubcontractors"
                                name="companySubSubcontractors"
                                isMulti
                                options={currentSubs}
                                value={selectedSubs}
                                onChange={this.subcontractorChanged}
                                getOptionValue={this.getOptionValue}
                            />
                            <Button
                                size="sm"
                                color="link"
                                className={`w-auto float-right site-link ${
                                    (currentSubs ?? []).length === 0
                                        ? 'disabled'
                                        : ''
                                }`}
                                onClick={() => this.subcontractorChanged(
                                    this.state.currentSubs,
                                )}
                            >
                                Select All
                            </Button>
                        </>
                    )}

                    {!showSubcontractors && (
                        <div>Please select one or more companies.</div>
                    )}

                    {companiesLoading && (
                        <Spinner
                            type="border"
                            tag="div"
                            size="sm"
                            aria-hidden="true"
                        />
                    )}
                </div>
            </>
        );
    }
}

import React from 'react';
import { Button, ButtonGroup, Badge, FormGroup } from 'reactstrap';
import moment from 'moment';
import _ from 'lodash-es';
import { FormLabel } from '../common/forms/FormElements';
import { isCancelAssignmentTreatedAsCancelled } from './Dispatch';

export default class DispatchEmployeeFilter extends React.Component {
    getAssignedEmployeeIds = () => {
        const { selectedWeekRangeDays, allJobs } = this.props;

        if (!allJobs || !allJobs.length) {
            return [];
        }

        const selectedDate = selectedWeekRangeDays[0];

        const assignedEmployeeIds = [];

        allJobs.forEach((j) => Object.values(j.assignments).forEach((a) => {
            if (
                a.isCancelled
                    || !moment(selectedDate).isSame(moment(a.date), 'day')
                    || !a.staff
            ) {
                return;
            }

            Object.values(a.staff).forEach((staffOpening) => {
                if (staffOpening.assignment) {
                    assignedEmployeeIds.push(staffOpening.assignment.id);
                }
            });
        }));

        return _.uniq(assignedEmployeeIds);
    };

    getCancelledCount = () => {
        const {
            selectedWeekRangeDays,
            cancellationJobs,
            jobAssignmentCancellationTypes,
        } = this.props;

        const selectedDate = selectedWeekRangeDays[0];

        let cancelled = 0;
        cancellationJobs.forEach((j) => Object.values(j.assignments).forEach((a) => {
            Object.values(a.cancelledAssignments).forEach((ca) => {
                if (
                    isCancelAssignmentTreatedAsCancelled(
                        ca,
                        jobAssignmentCancellationTypes,
                    )
                        && ca.assignedEmployeeId > 0
                        && moment(ca.assignmentDate).isSame(selectedDate, 'day')
                ) {
                    // Client cancel (not dispatch)
                    cancelled++;
                }
            });
        }));

        return cancelled;
    };

    getCancelledEmployeeIds = () => {
        const {
            selectedWeekRangeDays,
            cancellationJobs,
            jobAssignmentCancellationTypes,
        } = this.props;

        const selectedDate = selectedWeekRangeDays[0];
        const cancelledEmployeeIds = [];

        cancellationJobs.forEach((j) => Object.values(j.assignments).forEach((a) => {
            Object.values(a.cancelledAssignments).forEach((ca) => {
                if (
                    isCancelAssignmentTreatedAsCancelled(
                        ca,
                        jobAssignmentCancellationTypes,
                    )
                        && ca.assignedEmployeeId > 0
                        && moment(ca.assignmentDate).isSame(selectedDate, 'day')
                ) {
                    // Client cancel (not dispatch)
                    cancelledEmployeeIds.push(ca.assignedEmployeeId);
                }
            });
        }));

        return _.uniq(cancelledEmployeeIds);
    };

    getUnassignedCount = () => {
        const {
            staff,
            employeeDailyAttendanceEvents,
            employeeLongTermStatuses,
        } = this.props;

        const employeeIds = staff.rows.map((e) => e.id);

        const longTermIds = (employeeLongTermStatuses || []).map(
            (elts) => elts.employeeId,
        );

        const dailyAttendanceIds = (employeeDailyAttendanceEvents || []).map(
            (edae) => edae.employeeId,
        );

        const assignedEmployeeIds = this.getAssignedEmployeeIds();
        const cancelledEmployeeIds = this.getCancelledEmployeeIds();

        return employeeIds.filter(
            (eid) => !longTermIds.includes(eid)
                && !dailyAttendanceIds.includes(eid)
                && !assignedEmployeeIds.includes(eid)
                && !cancelledEmployeeIds.includes(eid),
        ).length;
    };

    render() {
        const {
            dailyAttendanceEvents,
            employeeDailyAttendanceEvents,
            selectedWeekRangeDays,
            areAttendanceEventsEnabled,
            loading,
            staffLoading,
            resourcesLoading,
            staff,
            onUnassignedClick,
            onAttendanceClick,
            onCancelledClick,
            onlyShowUnassigned,
            onlyShowAttendance,
            onlyShowCancelled,
            staffTabActive,
            tenantSettings,
        } = this.props;

        if (
            !staffTabActive
            || loading
            || staffLoading
            || resourcesLoading
            || !tenantSettings
            || !tenantSettings.dispatchStatusFilterEnabled
        ) {
            return null;
        }

        if (
            !selectedWeekRangeDays
            || selectedWeekRangeDays.length != 1
            || !staff
            || !staff.rows
        ) {
            return <div className="flex-spacing" />;
        }

        const unassignedCount = this.getUnassignedCount();
        const cancelledCount = this.getCancelledCount();

        const unassignedBadgeColor = unassignedCount <= 0 ? 'success' : 'danger';

        const cancelledBadgeColor = cancelledCount <= 0 ? 'success' : 'danger';

        const unassignedButtonColor = onlyShowUnassigned
            ? 'primary'
            : 'secondary';
        const attendanceButtonColor = onlyShowAttendance
            ? 'primary'
            : 'secondary';
        const cancelledButtonColor = onlyShowCancelled
            ? 'primary'
            : 'secondary';

        return (
            <div className="status-selection ml-3">
                <FormGroup>
                    <FormLabel
                        htmlFor="dispatchEmployeeFilter"
                        text="Status"
                    />
                    <ButtonGroup id="dispatchEmployeeFilter" className="dispatchEmployeeFilter">
                        <Button
                            type="button"
                            onClick={onUnassignedClick}
                            active={onlyShowUnassigned}
                            color={unassignedButtonColor}
                            outline
                        >
              Unassigned
                            {' '}
                            <Badge
                                color={unassignedBadgeColor}
                                className="badge-bigger"
                            >
                                {unassignedCount}
                            </Badge>
                        </Button>

                        {areAttendanceEventsEnabled
                            && dailyAttendanceEvents
                            && dailyAttendanceEvents.length > 0 && (
                            <Button
                                type="button"
                                onClick={onAttendanceClick}
                                active={onlyShowAttendance}
                                color={attendanceButtonColor}
                                outline
                            >
                              Attendance
                                {' '}
                                <Badge className="badge-bigger">
                                    {employeeDailyAttendanceEvents.length}
                                </Badge>
                            </Button>
                        )}

                        <Button
                            type="button"
                            onClick={onCancelledClick}
                            active={onlyShowCancelled}
                            color={cancelledButtonColor}
                            outline
                        >
              Cancelled
                            {' '}
                            <Badge
                                color={cancelledBadgeColor}
                                className="badge-bigger"
                            >
                                {cancelledCount}
                            </Badge>
                        </Button>
                    </ButtonGroup>
                </FormGroup>
            </div>
        );
    }
}

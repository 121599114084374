import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Progress } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import {
    createDataSource,
    createGridOptions,
    DataGrid,
    DataGridRefreshButton,
    refreshDataGrid,
    LinkCellRenderer,
    TextFilterDefaults,
} from '../common/dataGrid/DataGrid';
import { Can } from '../Can';
import CommonContext, { ApiRoutes, agGridConstants } from '../Common';
import {
    FlexRow,
    FlexStartRow,
    FlexEndRow,
    PageHeading,
    SmallButton,
    onReactSelectChanged,
    onFieldChange,
    PageWrap,
    toasty,
} from '../common/forms/FormElements';
import LongTermStatusForm from './LongTermStatusForm';
import { util } from '../Util';
import { handleFormSaveError } from '../common/forms/ValidationError';

class LongTermStatusIndex extends React.Component {
    // bugbug: the index/edit components were borrowed from an outdated component,
    // so don't go patterning other pieces off of this
    static contextType = CommonContext;

    constructor(props) {
        super(props);

        const params = new URLSearchParams(props.location.search);
        const openNew = params.get('new') === 'true';

        this.state = {
            loading: true,
            types: [],
            selectedLongTermStatus: openNew ? {} : null,
            showLongTermStatusForm: openNew,
        };
        this.onChange = this.onChange.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.handleSaveError = this.handleSaveError.bind(this);
    }

    componentDidMount() {
        return this.populateState(); 
    }

    onAddLongTermStatus = () => {
        this.setState({
            selectedLongTermStatus: {},
            showLongTermStatusForm: true,
        });
    };

    onChange = onFieldChange;

    onEditLongTermStatus = async (LongTermStatusId) => {
        const selectedLongTermStatus = await util.fetch.js(
            ApiRoutes.longTermStatus.byId(LongTermStatusId),
        );

        this.setState({
            selectedLongTermStatus,
            showLongTermStatusForm: true,
        });
    };

    onLongTermStatusDeleteCallback = () => {
        this.onLongTermStatusFormClosed();

        this.state.gridApi.setDatasource(this.state.dataSource);
    };

    onLongTermStatusFormClosed = () => {
        this.setState({
            selectedLongTermStatus: null,
            showLongTermStatusForm: false,
        });
    };

    onLongTermStatusSaveCallback = async () => {
        await this.setState({ saving: true });
        const { selectedLongTermStatus } = this.state;

        const saveUrl = selectedLongTermStatus.id && selectedLongTermStatus.id > 0
            ? ApiRoutes.longTermStatus.update()
            : ApiRoutes.longTermStatus.create();

        const response = await util.fetch
            .andGetResponse(
                util.fetch.types.post,
                saveUrl,
                selectedLongTermStatus,
                'Error Saving Long Term Status',
                () => this.setState({ saving: false }),
            )
            .catch(this.handleSaveError);

        if (response && response > 0) {
            this.notifySuccess();

            this.setState({
                selectedLongTermStatus: null,
                showLongTermStatusForm: false,
                saving: false,
            });

            this.state.gridApi.setDatasource(this.state.dataSource);
        }
    };

    onSelectChange = onReactSelectChanged;

    booleanTranslator = (paramValue) => {
        const value = Boolean(paramValue && paramValue.value);

        return value ? 'Yes' : 'No';
    };

    clearErrors = () => this.setState((state) => ({ errors: {} }));

    handleSaveError = (err) => {
        console.debug(err);
        handleFormSaveError(this, err);
    };

    notifyError = (message) => toasty.error(
        'Save Unsuccessful',
        `There was a server error saving this Long Term Status - (${message}).  Please try your request again or contact support for assistance.`,
    );

    notifySuccess = () => toasty.success(
        'Long Term Status Saved',
        'Long Term Status saved successfully.',
    );

    async populateState() {
    // Get grid defaults and handlers
        const gridOptions = createGridOptions(this);

        gridOptions.components = {
            nameRenderer: LinkCellRenderer,
        };

        // Provide column definitions
        gridOptions.columnDefs = [
            {
                colId: 'Name',
                sortable: true,
                headerName: 'Name',
                field: 'name',
                filter: agGridConstants.columnFilterTypes.text,
                floatingFilter: true,
                filterParams: TextFilterDefaults,
                sort: { direction: 'asc', priority: 0 },
                cellRenderer: 'nameRenderer',
                cellRendererParams: {
                    clicked: this.onEditLongTermStatus,
                    nameField: 'name',
                    idField: 'id',
                    title: 'View this Long Term Status',
                },
            },
            {
                colId: 'hideFromDispatchBoard',
                sortable: false,
                headerName: 'Hide From Dispatch Board When Assigned',
                field: 'hideFromDispatchBoard',
                cellRenderer: this.booleanTranslator,
            },
            {
                colId: 'allowDispatch',
                sortable: false,
                headerName: 'Allow Dispatch',
                field: 'allowDispatch',
                cellRenderer: this.booleanTranslator,
            },
            {
                colId: 'requiresEndDate',
                sortable: false,
                headerName: 'Requires End Date',
                field: 'requiresEndDate',
                cellRenderer: this.booleanTranslator,
            },
        ];

        // Create datasource.  Is reused and rebound on refresh from the state.
        const dataSource = createDataSource(
            ApiRoutes.longTermStatus.search(),
            gridOptions,
        );

        this.setState((state) => ({
            loading: false,
            gridOptions,
            dataSource,
        }));
    }

    refreshGrid = () => refreshDataGrid;

    render() {
        if (this.state.loading) {
            return <Progress />;
        }

        const {
            rowData,
            gridOptions,
            showLongTermStatusForm,
            selectedLongTermStatus,
        } = this.state;

        return (
            <PageWrap>
                <PageHeading>
                    <FontAwesomeIcon
                        icon={faCalendarTimes}
                        className="mr-2 text-muted"
                    />
                    <span>Long Term Statuses</span>
                </PageHeading>
                <Row>
                    <Col>
                        <FlexRow>
                            <FlexStartRow>
                                <DataGridRefreshButton
                                    gridApi={this.state.gridApi}
                                    dataSource={this.state.dataSource}
                                />
                            </FlexStartRow>
                            <FlexEndRow>
                                <Can I="create" a="longtermstatus">
                                    <CommonContext.Consumer>
                                        {(value) => (
                                            <SmallButton
                                                type="button"
                                                disabled={
                                                    showLongTermStatusForm
                                                }
                                                onClick={
                                                    this.onAddLongTermStatus
                                                }
                                            >
                                                <i className="fa fa-plus-circle fa-md mr-2" />
                        Add Long Term Status
                                            </SmallButton>
                                        )}
                                    </CommonContext.Consumer>
                                </Can>
                            </FlexEndRow>
                        </FlexRow>
                    </Col>
                </Row>
                <DataGrid
                    domLayout="normal"
                    rowData={rowData}
                    gridOptions={gridOptions}
                />
                <LongTermStatusForm
                    selectedLongTermStatus={selectedLongTermStatus}
                    onChange={this.onChange}
                    onSelectChange={this.onSelectChange}
                    onClose={this.onLongTermStatusFormClosed}
                    show={showLongTermStatusForm}
                    onSaveCallback={this.onLongTermStatusSaveCallback}
                    onDeleteCallback={this.onLongTermStatusDeleteCallback}
                />
            </PageWrap>
        );
    }
}

export default withRouter(LongTermStatusIndex);

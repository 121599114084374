import React from 'react';
import { useIsMobile } from '../../hooks/useIsMobile';
import TimeInput from './TimeInput';
import TimeEntry from './TimeEntry';

const TimePicker = (props) => {
    const isMobile = useIsMobile();
    if (isMobile) {
        return <TimeInput {...props} />;
    } 
    return <TimeEntry {...props} />;
};

export default TimePicker;
	